.newsWrap {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    @include media-breakpoint-down(md) {
        height: auto !important;
    }
    ul {
        li {
            margin-bottom: 0.6rem;
            font-family: 'Gotham-Book';
            // font-size: 0.85rem;
            font-size: 1rem;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    &.sixteen-nine {
        .img {
            padding-top: 56.25%;
        }
    }
    &.eight-five {
        .img {
            padding-top: 62.5%;
        }
    }
    &.four-three {
        .img {
            padding-top: 75%;
        }
    }
    &.three-two {
        .img {
            padding-top: 66.66%;
        }
    }
    .newsBlock h4 {
        margin-bottom: 0.80rem;
    }
    &.testimonialList {
        .newsBlock {
            h4 {
                margin-bottom: 1.80rem;
            }
        }
    }
}

.static {
    .newsBlock {
        margin-top: 4rem;
        position: relative;
        &:nth-child(-n+1) {
            margin-top: 0;
        }
        @include media-breakpoint-up(md) {
            width: 46%;
            margin-right: 6%;
            &:nth-of-type(2n) {
                margin-right: 0;
            }
            &:nth-of-type(-n+2) {
                margin-top: 0;
            }
            //padding-bottom: 36px;
            // .btn.btn-dark{
            //     position: absolute;
            //     bottom: 0;
            // }
        }
        @include media-breakpoint-up(lg) {
            width: 30%;
            margin-right: 5%;
            &:nth-of-type(2n) {
                margin-right: 5%;
            }
            &:nth-of-type(3n) {
                margin-right: 0;
            }
            &:nth-of-type(-n+3) {
                margin-top: 0;
            }
        }
    }
}

.newsBlock {
    color: $darker-brown;
    margin-bottom: 2rem!important;
    figure {
        margin-bottom: 1.9rem;
        @include media-breakpoint-up(md) {
            margin-bottom: 2.1rem;
        }
        .img {
            display: block;
            width: 100%;
            height: 100%;
            background-size: 100%;
            background-position: center;
            min-height: 147px;
            background-repeat: no-repeat!important;
            background-position: left top!important;
            @include transition(0.15s ease-in-out);
            &:hover{
                background-size: 105%;
                background-repeat: no-repeat!important;
                background-position: left top!important;
            }
        }
    }
    .btn {
        margin-top: 1.4rem;
    }
    &:first-child {
        .innerWrap {
            margin-top: 0;
            padding-top: 0;
            border-top: 0;
        }
    }
    h4 {
        margin-bottom: 1.6rem;
    }
    .author {
        font-size: 0.875rem;
        font-family: 'Gotham-Bold';
        margin-bottom: 1.6rem;
        color: $green;
        img {
            margin-right: 5px;
        }
        .ico-calandar {
            display: inline-block;
            height: 50px;
            width: 50px;
            border-radius: 50%;
            text-align: center;
            text-indent: 0;
            background: $green;
            text-align: center;
            margin: 0 1rem 0 0;
            &:after {
                content: "";
                height: 25px;
                width: 25px;
                background-image: url(../images/sprite.png);
                background-repeat: no-repeat;
                background-position: -195px -49px;
                display: inline-block;
                position: relative;
                top: 11px;
            }
        }
        time {
            color: $silver;
            text-transform: uppercase;
        }
        b {
            margin-right: 1rem;
        }
    }
    .innerWrap {
        min-height: 100%;
        position: relative;
        padding-bottom: 3.6rem;
        @include media-breakpoint-down(sm) { 
           padding-bottom: 0;
        }
        .btn-dark {
          position: absolute;
          left:0;
          bottom:0;
          margin-top:0;
          @include media-breakpoint-down(sm) {
            position: static;
            left: auto;
            bottom: auto;
            margin-top:1rem;
          }
        }
    }

}
@include media-breakpoint-down(sm) {
.static .newsBlock {
    margin-top: 2rem;
    width:100%;
    display: block;
}
.accordian .content {
    padding: 0.8rem 1.50rem;
}
body  {
.icon.lang-default-img {
    margin-right: 21px;
 img {
    width: 1.3rem;
    height: 1rem;
 }
}
.icon.ico-lense, .icon.ico-user {
    @include transform(scale(0.9));
    margin-right: 0.6rem;
}
.logo {
    max-width: 106px;
}
}
}
.newsBlock {
    .img-fluid {
        width: 100%;
        height: auto;
    }
}