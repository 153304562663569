.form-control,.form-elements input {
    color: $grey-300;
}

::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: $grey-300;
    opacity: 0.70;
    font-family: 'ScalaSans-Light';
}

::-moz-placeholder {
    /* Firefox 19+ */
    color: $grey-300;
    opacity: 0.70;
    font-family: 'ScalaSans-Light';
}

:-ms-input-placeholder {
    /* IE 10+ */
    color: $grey-300;
    opacity: 0.70;
    font-family: 'ScalaSans-Light';
}

:-moz-placeholder {
    /* Firefox 18- */
    color: $grey-300;
    opacity: 0.70;
    font-family: 'ScalaSans-Light';
}

/* Custom check box */

.form-check-input {
    position: absolute; // take it out of document flow
    opacity: 0; // hide it
    &+label {
        position: relative;
        cursor: pointer;
        padding: 0;
    } // Box.
    &+label:before {
        content: '';
        margin-right: 0.25rem;
        display: inline-block;
        vertical-align: text-top;
        width: 1.0625rem;
        height: 1.125rem;
        background: white;
        border: 0.0625rem solid $silver-250;
        border-radius: 0.125rem;
        top: -0.125rem;
        position: relative;
        @include transition(0.25s ease-in-out);
    } // Box hover
    &:hover+label:before {
        //background: #f35429;
    } // Box focus
    &:focus+label:before {
        -moz-box-shadow: inset 0.0625rem 0.0625rem 0.875rem rgba(0, 0, 0, 0.15);
        -webkit-box-shadow: inset 0.0625rem 0.0625rem 0.875rem rgba(0, 0, 0, 0.15);
        box-shadow: inset 0.0625rem 0.0625rem 0.875rem rgba(0, 0, 0, 0.15);
    } // Box checked
    &:checked+label:before {
        //background: #f35429;
    } // Disabled state label.
    &:disabled+label {
        color: #b8b8b8;
        cursor: auto;
    } // Disabled box.
    &:disabled+label:before {
        box-shadow: none;
        background: #ddd;
    } // Checkmark. Could be replaced with an image
    &:checked+label:after {
        content: '';
        position: absolute;
        left: 0.25rem;
        top: 0.0625rem;
        background: $white;
        width: 0.5rem;
        height: 0.125rem;
        display: block;
        width: 0.4375rem;
        height: 0.75rem;
        border: solid $blue;
        border-width: 0 0.125rem 0.125rem 0;
        @include transform(rotate(46deg));
    }
} //Global search box...
.form-control {
    // outline: 0 !important;
    box-shadow: none !important;
}

.stylish-select-left {
    &:focus {
        outline: 0.0625rem dotted $white;
    }
}

.searchBox {
    position: relative;
    z-index: 0;
    &.active {
        z-index: 4000;
    }
    .outerWrap {
        position: relative;
        z-index: 2000
    }
    .pointer {
        width: 0;
        height: 0;
        border-bottom: 0.8125rem solid $white;
        border-left: 0.8125rem solid transparent;
        border-right: 0.8125rem solid transparent;
        position: absolute;
        top: -0.75rem;
        right: -0.4375rem;
        @include transition(0.3s linear);
    }
    form {
        position: absolute;
        width: 100%;
        left: 0;
        background: #fff;
        border-radius: 0.125rem;
        top: 2.5rem;
        border: 0px solid rgba(0, 0, 0, 0.15);
        border-radius: 0.25rem;
        height: 0;
        visibility: hidden;
        opacity: 0;
        @include media-breakpoint-up(sm) {
            width: calc(100% - 2.8125rem);
            left: 0.9375rem;
        }
        @include transition(visibility 0s linear 0.33s, opacity 0.33s linear, height 0.22s linear);
        .innerWrap {
            overflow: hidden;
            padding: 0.40rem 0;
            @include media-breakpoint-up(sm) {
                padding: 0.90rem;
            }
        }
        .form-control {
            border: 0;
            background: transparent;
            border-radius: 0;
            @include media-breakpoint-down(xs) {
                padding: 0.375rem 0.50rem;
                font-size: 0.90rem;
                line-height: 1;
            }
        }
        .btn {
            text-transform: uppercase;
            visibility: hidden;
            opacity: 0;
            @include media-breakpoint-down(xs) {
                margin-right: 0.375rem;
                font-size: 0.730rem;
                padding: 0.45rem 0.80rem;
            }
        }
    }
    &.active {
        .icon {
            background-position: -391px -4px;
        }
        form {
            height: 2.625rem;
            @include media-breakpoint-up(sm) {
                height: 4.1rem;
            }
            visibility: visible;
            opacity: 1;
            transition-delay: 0s;
            border-width: 0.0625rem;
        }
        .btn {
            text-transform: uppercase;
            visibility: visible;
            opacity: 1;
            @include transition(all 0.55s ease-in-out);
        }
    }
}

.form-group {
    .main {
        min-height: 2.375rem;
        & + .alertMassage {
            margin-top: 1rem;
            display: block;
        }
    }
    .stylish-select-left {
        font-family: 'ScalaSans-Light';
    }
    .stylish-select {
        height: 2.375rem;
    }
    .stylish-select-left .stylish-select-right {
        border: 0.0625rem solid $blue-100;
        padding: 0.47rem 0.45rem;
        font-size: 0.875rem;
        line-height: 1.5;
        border-radius: 0.1875rem;
        color: $grey-300;
        background-color: $white;
    }
    .stylish-select-left:after {
        border-color: $brown-200;
        border-width: 0.06rem;
        height: 0.40rem;
        width: 0.40rem;
    }
    ul.listing {
        top: 100%;
        li {
            font-size: 0.875rem;
            padding: 0.5rem;
        }
    }
}

.filtersGroup {
    margin-bottom: 3.8rem;
    @include media-breakpoint-down(md) {
        margin-bottom: 1.8rem;
    }
    .form-group {
        margin: 0;
        .main {
            min-height: auto;
        }
        .stylish-select {
            height: 3.3125rem;
            @include media-breakpoint-down(md) {
                height: 2.6rem;
            }
        }
        .stylish-select-left:after {
            right: 1.0625rem;
        }
        .stylish-select-left .stylish-select-right {
            border-color: transparent;
            background-color: $white-100;
            padding: 0.90rem;
            font-size: 1rem;
            @include media-breakpoint-down(md) {
                padding: 0.50rem;
            }
        }
    }
    .form-control {
        border: 0;
        background-color: $white-100;
        padding: 0.90rem;
        font-size: 1rem;
        @include media-breakpoint-down(md) {
            padding: 0.50rem;
        }
    }
    .inputSearch {
        position: relative;
        .btn {
            position: absolute;
            top: calc(50% - 0.6875rem);
            right: 1.875rem;
            bottom: auto;
            padding: 0;
            margin-top: 0;
            width: 22px;
            height: 22px;
            border: 0;
            text-indent: -9999px;
            background: url('../images/sprite.png');
            background-position: -391px -65px;
            background-repeat: no-repeat;
        }
    }
}

.subscribeBtn,.type-2{
    ::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: $darker-brown-1;
        opacity: 0.50;
        font-family:'Gotham-light';
    }
    ::-moz-placeholder {
        /* Firefox 19+ */
        color: $darker-brown-1;
        opacity: 0.50;
        font-family:'Gotham-light';
    }
    :-ms-input-placeholder {
        /* IE 10+ */
        color: $darker-brown-1;
        opacity: 0.50;
        font-family:'Gotham-light';
    }
    :-moz-placeholder {
        /* Firefox 18- */
        color: $darker-brown-1;
        opacity: 0.50;
        font-family:'Gotham-light';
    }
}

.btn-clear{
    background-color: transparent;
    border: 1px solid #fff;
    color: #fff;
    &:hover,&:focus{
        background-color: $blue;
        border-color:$blue;
        color:$white;
    }
}