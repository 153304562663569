%global_shadow_box {
    @include box-shadow(0px 0px 20px rgba(0,0,0,0.2));
    background: $white;
    padding:1.1rem 1rem;
    @include border-radius(5px);
}
%global_light_txt {
    font-size: 0.95rem;
    font-family: 'Gotham-light';
}

.block-investor{
.col-lg-6 {
   
        position: relative;
      &.content {
          z-index: 10;;
      }
}
h2.lg{
    span{
    display: block;
    }
}
}
.profile_section {
  padding: 3.5rem 0 0;
  &>div {
  margin-bottom: 1.7rem;
  }
.profile_desc {
    @extend %global_shadow_box;
    display: table;
    width: 100%;
    div {
        display: table-cell;
        vertical-align: middle;
        &:first-child {
            width: 10rem;
            padding-right: 2rem;
        }
        h5 {
            font-family: 'Gotham-Book';
            margin-bottom: 0.2rem;
        }
        p {
            line-height: 1.3;
        }
    }
   figure {
     width: 8rem;
     height: 6rem;
     margin-bottom: 0;
     img {width: 100%; height: 100%; 
        @include border-radius(10px)
    }
    }
}
.price_info {
    @extend %global_shadow_box; 
    div {
        display: inline-block;
        width: 22%;
        vertical-align: top;
        &:first-child {width: 50%;padding-right: 2%;}
        h5 {
            font-family: 'Gotham-Book';
            font-size: 1.15rem;
            span {
               @extend %global_light_txt;
            }
        }
        p{
            @extend %global_light_txt;
            padding-top: 0.9rem;
        }
    }
    & strong {
        font-size: 2rem;
        font-family: 'Gotham-Book';
        font-weight: bold;
        background: url(../images/investor/up-arrow.png) no-repeat;
        background-position: right center;
        padding-right: 1.7rem;
    }
    & span {
        @extend %global_light_txt;

    }
}
.quickLinks{
    @extend %global_shadow_box; 
    margin-top:0!important;
    .navbar-nav {
        display: block;
    .nav-item {
        display: inline-block;
        vertical-align: top;
        width: 47%;
        &:first-child{
            margin-right: 2%;
        }
        a {
        span {
            font-size: 1rem;
            font-family: 'Gotham-Book';
            margin-left: 0;
        }
        &:hover {
            span{color: $blue;}
        }
      }
    }
}
}
h4 {
    font-size: 1rem;
    font-family: 'Gotham-light';
    margin-bottom: 1.4rem;
}

.login_options {
    @extend %global_shadow_box; 
    p {
        margin-bottom: 0.2rem;
        a {
            font-size: 1.2rem;
            font-family: 'Gotham-light';
        }
    }
    .stylish-select-left{
        &:after {
            border-color: $dark-brown-100;
            height: 0.7rem;
           width: 0.7rem;
        }
    .styledSelect{
           font-size: 1.3rem;   
           border:none;
           font-family: 'Gotham-light';
           border-bottom: 1px solid $light-brown;
           border-radius: 0px;
           padding-left:0;
    }
}
}
}
.block-investor {
    h3.light {
        padding-top: 2rem;
    }
    svg {
        stroke-width: 1;
    }
}
#LoginModal {
    .form-check-input {
        left: 22px;
        z-index: 1000;
        cursor: pointer;
    }
    button.close {
        position: absolute;
        right: 1rem;
        top:1rem;
        z-index: 100000;
        cursor: pointer;
    }
    .modal-body {
     padding-left: 2rem;
     padding-right: 2rem;
    }
}
#LoginModal,body.modal-open,body {
  padding-right: 0!important;
}
.modal-open  {
.navbar.fixed-top {
    padding-right: 1rem!important;
}

}
.navbar.fixed-top {
    padding-right: 1rem!important;
}
@include media-breakpoint-down(lg) { 
    %global_inline_block {
        display: inline-block;
        vertical-align: middle;
        
    }
    .profile_section{
        .quickLinks{
            .navbar-nav{
                .nav-item {display: block; width: 100%;}
            }
        }
    .price_info div {
        display: block;
        margin: 0 0 0.7rem;
        width:100%;
        &:first-child {
            width:100%;
        }
        p {
            padding-top: 0px;
            @extend %global_inline_block;

        }
        h5 {
            margin-right: 0.5rem;
            @extend %global_inline_block;
        }
    }

    }

}
@include media-breakpoint-down(md) {  
    .profile_section {
        padding: 1.5rem 0 0;
    }
  
}
@include media-breakpoint-down(sm) { 
   #LoginModal {
       padding-right: 0!important;
    .userloginBox {
        display: block!important;
         }
   }

}
@include media-breakpoint-down(xs) { 
    .profile_section {
     display: block;
    .profile_desc  {
        div {
            display: block;
            width: auto;
            &:first-child {
                padding: 0;
                margin:0 auto 0.7rem;
            }
        }
    }
    .price_info{
         div {
             h5 {
                 display: block;
             }
         }
    }
    & > div {
        margin-bottom: 1rem;
    }
    .login_options {
        .stylish-select-left{
            .styledSelect {
                font-size: 1rem;
            }
        }
    }
    .form-group {
        ul.listing{
            background: $white;
        }
    }
    }
   
    .block-investor {
        h3.light {
            font-size: 1rem;
        }
        h2.lg {
            margin-bottom: 0.7rem;
        }
    }
}