body{
    font-size: 1rem;
    line-height: 1.34;
    font-family:'ScalaSans-Light';
    color:$brown-200;
    font-style: normal;
    padding:0!important;
}
p {
    line-height: 1.8;
    margin-bottom: 1.35rem;
    font-size: 1.125rem;
    &:last-child{
        margin-bottom:0;
    }

}


a {
    color:$blue;
    &:hover,&:active,&:focus{
        color:$blue;
    }
 }


h1,h2,h3,h4,h5,div.h4{font-family:'Gotham-Black';}

h2,div.lg,h1 {
    font-family:'Gotham-medium';
    &.disp-2{
        font-family: 'Gotham-Book';line-height: 1.4;margin-bottom: 2.8rem;
        @include media-breakpoint-down(md) {
            font-size: 1.5rem;
            margin-bottom: 1.2rem;
        }   
    }
    &.lg{
        font-size: 2.375rem;
        line-height: 0.80;
        margin-bottom:1.8rem;
        // word-wrap: break-word;
        word-wrap: anywhere;
        
        @include media-breakpoint-up(sm) {
            font-size: 4rem;
        }
        @include media-breakpoint-up(xl) {
            font-size: 6.375rem;
            margin-bottom: 3.2rem;
        }
    }
    &.block{
        display: block;
    }
}


h3,div.light,h1 {
    font-size: 1.5rem;
    @include media-breakpoint-up(md) {
        font-size:1.7rem;
    }    
    margin-bottom: 1.2rem;
    &.light{
        font-family:'Gotham-light';
        line-height: 1.34;
    }
}

h4,div.h4 {
    font-size: 1.2rem;
    font-family:'Gotham-medium';
    line-height: 1.4;
}

h5,.heading5{

    font-size:1rem;
    font-family:'Gotham-Bold';   line-height: 1.4;
}
.heading5{
    text-transform: uppercase;
}
h6,time,.dropdown-menu .title{
    font-size: 0.80rem;
    font-family: 'Gotham-medium';
    &.lead{
        font-family:'Gotham-medium';font-size: 1rem;
    }
}

.btn{
    font-family: 'Gotham-Book';
    font-size: 0.80rem;
    line-height: 1;
    padding: 0.750rem 1.5rem 0.60rem;outline:0;box-shadow: none!important;letter-spacing: 0.85px;
}
textarea{resize: none;}
.breadCrub{margin-bottom: 1rem;}

.blogInfo{
    font-family: 'Gotham-Book';font-size: 0.85rem;color:$silver;margin-bottom: 2.6rem;
    @include media-breakpoint-down(md) {
        margin-bottom: 1rem;
    }  
    a{
        font-family: 'Gotham-Bold';
        color:$green;
        margin-right: 1.1rem;
        text-decoration:none;
        &:hover,&:focus{
            opacity:0.8;
        }
        margin-bottom: 0.3rem;
        display: inline-block;
    }
}
.greenText{
    font-family: 'Gotham-Bold';
    color:$green;
    margin-right: 1.1rem;
    text-decoration:none;
    &:hover,&:focus{
        opacity:0.8;
    }
    margin-bottom: 0.3rem;
    display: inline-block;
}
footer{
    font-family:'ScalaSans-Light';
    font-size: 0.875rem;
    .navbar-brand{font-size: 0.875rem;}
    .title{
        font-family: 'Gotham-medium';
    }
}


.tableView{
    .heading{
        font-family: 'Gotham-Book';
        font-size:1rem;
        text-transform: uppercase;
        margin-bottom:0.90rem;
    }
}

.subscribeBtn{
    input{
        font-family:'Gotham-light';
        font-size:1.1rem;
    }
}

.showParent{
    display:block;
}

.hideParent {
    display: none;
} 
body {
.offerBlock .content-wrap ul li {
    padding-top: 2px;
}
.calculator-main-content .info_box p,.offerBlock .content-wrap ul li,.step_declaration p {
    font-size: 1.125rem;
}
}
.footer3  p {
    font-size: 0.875rem;
}
@include media-breakpoint-down(sm) {
p ,body .calculator-main-content .info_box p, body .offerBlock .content-wrap ul li, body .step_declaration p{
    font-size: 1rem;
}

}




.img-responsive {
    width: auto!important;
    max-width: 100%!important;
    height: auto!important;
}