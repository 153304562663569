//Color Pallet...
$brown          : #373735;
$brown-100      : #626262;
$brown-120      : #5e5e5e;
$brown-150      : #525252;
$brown-200      : #383b40;
$grey-300       : #363735;
$gray-320       : #8b8b8b;
$dark-brown-100 : #333;
$dark-brown     : #303030;
$darker-brown   : #16110a;
$darker-brown-1 : #1f1f1f;
$light-brown    : #d8d8d8;
$gray-light      :#c5c5c5;
$gray-light-0    :#a1a1a1;
$white          : #fff;
$white-50       : #fefefe;
$white-light-10 : #fafafa;
$green          : #84bd00;
$green-100      : #9dca33;
$green-400      : #81c341;
$offwhite       : #c6cdbf;
$white-100      : #f7f7f7;
$white-150      : #f9f9f9;
$white-light    : #f4f4f4;
$white-300      : #f5f5f5;
$lightoffwhite  : rgba(255,255,255,0.50);
$grey-50        : #acacac;
$gray-60        : #656565;
$grey-100       : #ececec;
$grey-200       : #ebebec;

$blue           : #00a9e0;
$blue-100       : #0085b1;
$blue-200       : #1cb2e3;
$blue-300        : #00b6f0;
$silver         : #a2a8b2;
$silver-50      : #b2b2b2;
$silver-100     : #d2d2d2;
$sliver-150     : #d9d9d9;
$silver-200     : #c4c4c4;
$silver-250     : #c9c9c9;
$silver-300     : #838383;
$silver-350     : #868685;
$silver-400     : #898988;
$gray-84         :#848484;
$gray-f0          :#f0f0f0;
$gray-e6       :#e6e6e6;
$gray-e8       :#e8eaeb;

$gold           :#ff9c00;
$red            :#FF0000;
$black          : #000000;
// Social Icons

$li-color       : #0077B5;
$twit-color     : #55acee;
$fb-color       : #3b5999;
$yout-color     : #cd201f;
$blog-color     : #f57d00;
$textColor      :#2d2d2d;
$purple         : #9a0058;

//Custom classes...
.blue{
    color:$blue;
}
.blueBg{
    background-color: $blue!important;
}
.greyBg{
    background-color: $white-100!important;
}
.brownBg{
    background-color: $brown;
}
.darkBrownBg{
    background: $dark-brown;
}
.greenBg{
    background-color: $green;
}
.blue-txt {
    font-weight: normal;
    color: $blue;
}
.blueBg{
    color:$white;
    h1,h2,h4,h5,h6,p,a{color:$white}
    .btn.btn-primary{
        background: $white;color:$brown-200;border-color:$white;
        &:hover,&:active,&:focus{background: transparent;color:$white;border-color:$white;}
    }
}
a.btn:focus{
    color:$white;
}

.dark{background-color:$white-100;}
.shadow-down{
    background: -moz-linear-gradient(top, rgba(246,246,246,0.66) 0%, rgba(255,255,255,0.6) 100%);
background: -webkit-linear-gradient(top, rgba(246,246,246,0.66) 0%,rgba(255,255,255,0.6) 100%);
background: linear-gradient(to bottom, rgba(246,246,246,0.66) 0%,rgba(255,255,255,0.6) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a8f6f6f6', endColorstr='#99ffffff',GradientType=0 );
}
.shadow-up{
    background: -moz-linear-gradient(top, rgba(255,255,255,0.6) 0%, rgba(246,246,246,0.66) 100%);
background: -webkit-linear-gradient(top, rgba(255,255,255,0.6) 0%,rgba(246,246,246,0.66) 100%);
background: linear-gradient(to bottom, rgba(255,255,255,0.6) 0%,rgba(246,246,246,0.66) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#99ffffff', endColorstr='#a8f6f6f6',GradientType=0 );
}

//Display-Table...

.table{display: table;width:100%;height: 100%;}
.table-row{
    display: table-row;
    width:100%;height: 100%;
}
.table-cell{
    display: table-cell;vertical-align: middle;
    &.bottomAlign{
        vertical-align: bottom;
    }
}

//Buttons 
.btn-primary{
    background-color:$blue; border-color:$blue;
    &:hover,&:active,&:focus{
        background-color: $blue-100;border-color:$blue-100;
        box-shadow:none;
    }
}

.btn-dark{background-color:$brown-200;
    &:hover,&:focus{
        background-color: #23272b;
        border-color: #1d2124;
    }
}


.disabled{cursor: not-allowed;}

//Padding
.padding-right-0{padding-right:0!important;}


//margin
.mb-6{
    margin-bottom:9rem;
}

a{@include transition(0.25s ease-in-out);}

//Custom Forms
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

@media (min-width:1200px) {
    .container{
        max-width:1170px;
    }
 }
// @media (min-width:1600px) {
//    html{
//         font-size: 18px;
//    }
//     .container{
//        max-width:1570px;
//    }
// }
// @media (min-width:1920px) {
//     html{
//         font-size: 26px;
//    }
//     .container{
//         max-width:1800px;
//     }
//  }
//  @media (min-width:2560px) {
//     html {
//         font-size: 36px;
//     }
//     .container{
//         max-width:2500px
//     }
// }


 //Overlay
 .globalOverlay{
    @include transition(opacity 0.50s ease-in-out);
    visibility: hidden;
    width: 0;
    height: 0;
    opacity: 0;
    top: 0;
    left:0;
    z-index: 999;
    background: rgba(0,0,0,0.5);
    position: fixed;
    &.show{
        visibility: visible;
        width:100%;
        height:100%;
        opacity: 1;
        display: block!important;
        &+.outerWrap {
            .dropdown-menu {
                display: block!important;
            }
        }
     }
 }
 
 .owl-drag{
     .owl-item{
        cursor: -webkit-grab;
     }
     &.owl-grab{
        .owl-item{
            cursor: -webkit-grabbing;
         }
     }
 }