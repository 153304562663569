.p-none {padding: 0!important;}
.promotionBanner2 .homeBanner .innerWrap {padding-top: 8rem;}
.block-getQuote {
    position: relative;
    .contact_form.userloginBox {display: flex!important;}
    &:before {
        position: absolute;
        content: "";
        width: 100%;
        top: 4.4rem;
        height: 10px;
        z-index: 1000;
       @include box-shadow(0px 3px 5px  rgba(0, 0, 0, 0.1));
    }
    .quick-contact-link {
      padding-bottom: 0;
      margin-bottom: 0;
    }
     .form-group ul.listing {top:140%!important;}
}
.dark-text.navbar-dark .navbar-toggler span.navbar-toggler {background:$gray-light-0 ;}
.stuck.dark-text.navbar-dark .navbar-toggler span.navbar-toggler {background: $white;}
.insuration-info-list {
    padding: 0;
    li {
        display: inline-block;
        vertical-align: top;
        font-size: 1rem;
        font-family: 'Gotham-medium';
        margin-right: 1.8rem;
       
        i {
            background-image: url(../images/sprite.png);
            background-repeat: no-repeat;
            display: block;
            float: left;
            width: 29px;
            height: 25px;
            margin-right: 0.8rem;
        &.estimate-time {
            background-position: left -254px;

        }
        &.required-medical {
            background-position: left -292px;
            width: 40px;
        }
    }
        strong{
            color: $blue;
        }
    }
}
.internalPage {
    .block.block-getQuate:last-child {
        padding:10rem 0 0;
    }
}
.block-getQuate {
    padding:10rem 0 0;
    h2 {
        font-size: 5rem;
       
    small {
        display: block;
        padding-top: 1.7rem;
        font-size: 2.7rem;
    }
    }
}

#msform {
    padding:3.93rem 0 0;
    position: relative;
    margin:0 ;
    
    h5 {
        font-size: 1.25rem;
        font-family: 'Gotham-light';
        span {
            display: block;
        }
    }
    #progressbar {
        margin:0 2rem 0rem;
        counter-reset: item;
        list-style-type: none;
        position: relative;
        z-index: 10;
        padding:0;
        &:before {
            width: 100%;
            height: 3.11rem;
            background: #ebebeb;
            border-radius: 75px;
            position: absolute;
            content: "";
            left:0;
            top:0.0625rem
        }
        li {
            counter-increment: li;
            display: inline-block;
            vertical-align: top;
            font-family: 'Gotham-light';
            font-size: 1.12rem;
            margin-right: 1.6rem;
            width: auto;
            min-width: 20%;
            position: relative;
            &:before {
                content: counter(li);
                content: counter(item, decimal-leading-zero) " "; 
                counter-increment: item;
                display: inline-block;
                width: 3.28rem;
                height: 3.28rem;
                border-radius: 50%;
                background: $white;
                @include box-shadow(0px 0px 3px rgba(0,0,0,0.3) inset);
                border: 3px solid $white;
                text-align: center;
                line-height:3rem;
                font-family: 'Gotham-bold';
                font-size: 1rem;
                margin-right: 1.3rem;
                
            }

            &.active {
                color: $blue;
                &:before {
                  background: $blue;
                  color: $white;
                  @include box-shadow(0px 0px 0px 3px $white);
                  border: 4px solid #ebebeb;
                }
            }
            &.done-active {
                color:$brown;
                &:before {
                background: $green;
                // font-size: 0px;
                color:$green;
                border: 2px solid #ebebeb;
                }
                &:after {
                    width: 0.8rem;
                    height: 1.4rem;
                    left: 1.3rem;
                    top: 0.8rem;
                    border-right: 3px solid $white;
                    border-bottom: 3px solid $white;
                    content: "";
                    position: absolute;
                    @include transform(rotate(41deg));
                }
                
            }
        }
    }
    .step-content {
        position: relative;
        top: -1.8rem;
        margin-bottom: 4rem;
        .step-subtitle {
            padding-bottom: 2.5rem;
        }
     fieldset {
        width: 100%;
        .fieldset-form-wrap {
            padding: 5.9rem 4.5rem 6rem;
            border:1px solid #f5f5f5;
            @include box-shadow(0px 0px 10px rgba(0,0,0,0.07));
            margin-bottom: 3rem;
        }
       
        &>.row {
            margin:0;
            &.step-subtitle {
                margin:0;
            }
        }
        &:not(:first-of-type) {
         display: none; 
        }
        &.step-last {
            h5.title-5 {
                font-size: 1rem;
                font-family: 'Gotham-medium';
                margin-bottom: 4rem;

                span {
                    display: inline;
                    color: $blue;
                }
            }
            h4 {
                font-size: 1.5rem;
                font-family: 'Gotham-light';
                margin-bottom: 2.5rem;
            }
          }
    }
}
.renewal-col {
    strong,span  {
        font-size: 1.12rem;
        font-family: 'Gotham-medium';
        margin: 0 -15px;
        display: block;
        font-weight: normal;
    }
}
.m-t-1 {
    margin-top: 1.5rem;
}



}

.internalPage{
    .contact_form {
        label {
            display: block!important;
            font-size: 1rem;
            opacity: 1;
            font-family: 'Gotham-light';
            font-size: 1.1rem;
            span {
                color: $red;
            }
            &.require-label:after {
            position: absolute;
            color: $red;
            content: "*";
            width: 10px;
            height: 10px;
            padding-left:5px;
             
            }
            &.none-require-label:after {
                display: none;
            }
            &.hidden {
                display: block!important;
            }
            &.hidden.d-none {
                display: none!important;
            }
            &.dropdownLabel  {
                position: static;
                top:auto;
            }
        }
        .checkbox-wrapper {
            padding-left: 0.3rem;
            label {
                position: static;
                top:auto;
            }
            .form-check {
                position: relative;
                margin-bottom: 0.4rem;
                display: inline-block;
                 vertical-align: top;
                margin-right: 0.8rem;
            input[type="checkbox"]{
                left:0;
                top:0;
                margin:0;
                width: 1.0625rem;
                height: 1.125rem;
            }
            .form-check-label {
                &:before {
                    margin:0.2rem 0.8rem 0 0;
                }
                &:after {
                    top:0.3rem;
                }
            }
            
        }
        }
        .customSelect-require .styledSelect {
            position: relative;
            &:after {
                position: absolute;
                color: $red;
                content: "*";
                width: 10px;
                height: 10px;
                padding-left:5px;
        
                }
        }
        }


    .block-contact.block-getQuate {
        aside {
            padding-left: 0;
        
.getQuate_aside{
    width: 88%;
       float: right;
    h3 {
    font-family: 'Gotham-book';
    font-size: 1.56rem;
    text-transform: none;
    }
    ul {
        padding: 0;
        list-style: none;
        li {
            font-size: 1.125rem;
            color: #fff;
            font-family: 'Gotham-book';
            background:url(../images/pointer-white.png) left 0.3rem no-repeat;
            padding:0 0 1.75rem 1.75rem;
            

        }
    }
}
}

.form-group {
    margin-bottom: 2.5rem;
    padding: 0 2rem;
}
}
}
.block {
.action-button {
    text-transform: uppercase;
font-size: 1rem;
min-width: 13rem;
line-height: 1.2;
margin-top: 0;
font-weight: bold;


&.gray-btn {
    background: $grey-100;
    color: $darker-brown-1;
    border-color: $grey-100;
    margin:0 0 0 2rem!important;
    &:hover,&:focus {
        background:$blue-100;
        color: $white;
        border-color: $blue-100;
    }
}
}
}
.tab-section {
    padding: 0 15px;
    @include box-shadow(0px 5px 5px rgba(0,0,0,0.04));
    border:1px solid #f1f1f1;
    border-radius: 5px;
    margin-bottom: 4rem;
    .tab-content {padding:1.3rem 15px 2rem 5rem;}
    .col-lg-4,.col-lg-3 {
        padding: 0;
        background:$white-light-10;
    }
    .nav {
        display: block;
        .nav-link {
            border:none;
            font-size: 1.25rem;
            color: $darker-brown-1;
            font-family: 'Gotham-light';
            border-left: 10px solid transparent;
            border-radius: 0px;
            padding: 1.7rem 1.75rem;
            &.active {
                border:none;
                color: $green;
                border-left: 10px solid $green;
            }
        }
    }
    .nav-tabs {
        border:none;
    }
    .offer-txt {
        display: block;
        color: $gold;
        text-transform: uppercase;
        font-family: 'Gotham-bold';
        font-size: 0.9rem;
        padding: 2.3rem 0 1.5rem;
    }
    .pricePerMonth {
        font-size: 3.12rem;
        font-family: 'Gotham-medium';
        font-weight: 400;

        small {
            font-family: 'Gotham-light';
            font-size: 1.12rem;
            min-width: 100px;

           display: inline-block;
        }
    }
    .tab-pane {
        .clearfix {
            margin-bottom: 3rem;
            &>div {
                float: left;
                width: 33%;
                &:last-child {
                    width: 67%;
                    padding-left:10%;
                }
            }
            p {
                font-size: 0.9rem;
            }
        }
        h6 {
            font-family: 'Gotham-book';
            font-size: 1.25rem;
        }
        .form-group .stylish-select-left .stylish-select-right {
            font-size: 1.87rem;
            font-family: 'Gotham-book';
            background: none;
        }
        
    }
    &.motor-tab-section {
        width: 31.5rem;
        padding:2rem;
        text-align: center;
        @include box-shadow(0px 7px 15px rgba(0,0,0,0.1));
        border-radius: 7px;
        border:none;

        h6 {
            font-family: 'Gotham-book';
            font-size: 1.25rem;
        }
        p {
            line-height: 1.3;
            font-size: 0.78;
            font-family: 'Gotham-light';
        }
        .pricePerMonth {
            font-family: 'Gotham-medium';
            font-size: 3.12rem;
            padding-bottom: 0.7rem;
            display: block;
        }
    }
}
.purchase-col {
    text-align: center;
    p,.actiaction-button {
        display: inline-block;
        vertical-align: middle;
        margin:0;

    }
    p {
        margin-left: 1.4rem;
        font-size: 1.2rem;

        a {
            color: $darker-brown-1;
            font-family: 'Gotham-book';
            padding-left: 6px;
            &:hover {
                color: $blue;
                text-decoration: none;
            }

        }

    }
    .actiaction-button {
        font-size: 1rem;
        font-family: 'Gotham-medium';
        text-transform: uppercase;
        padding: 0.7rem 2.1rem;
        
    }
    &.motor-purchase-col {
        text-align: left;
        margin-bottom: 3rem;
        p {
            margin-left: 2rem;
        }
    }
}
.quick-contact-link {
    padding: 3rem 0 2rem;
    list-style: none;
    li {
        display: inline-block;
        vertical-align: middle;
        margin-right: 1.2rem;
        &:last-child {margin-right: 0;}
        min-width: 255px;
        overflow: hidden;
        a {
            display: table;
            font-size: 1.12rem;
            color: $darker-brown-1;
            width: 100%;
            &:hover {text-decoration: none;color: $green;}
            svg,strong {
            display: table-cell;
            vertical-align: middle;
         }
         svg {height: 1.6rem;margin:0;}
        }
        strong {
            width: auto;
            font-weight: normal;
            text-align: left;
            padding-left:0.5rem;
        }
    }

}
.step_declaration {
    padding: 1.5rem 0 2rem;
    h3 {
        font-size: 1.12rem;
        font-family: 'Gotham-book';
    }
    p {
        font-size: 0.9rem;
        font-family: 'Gotham-light';
        line-height: 1.5;
    }
}
.form_tooltip {
    display: block;
    position: absolute;
    right: 0!important;
    bottom: auto!important;
    top: 17px!important;
    background: $white;
    width:1.87rem;
    height:1.87rem;
    text-align: center;
    line-height: 1.87rem;
    border-radius: 50%;
    background: $white;
    @include box-shadow(0px 0px 3px rgba(0,0,0,0.2));
    &:hover {
        text-decoration: none;
    }
#svg_info,.svg_info {
    max-width: 1rem;
    position: relative;
    top:3px;
}
&:hover {
    background: $blue;
    #svg_info path ,.svg_info path{
        fill: $white;
        margin-top: 0.4rem;
    }
}
}
.tooltip,.bs-tooltip-bottom {
    top:-1rem!important;
    bottom:auto!important;
&.show {
    opacity: 1!important;
    filter:alpha(opacity=100)!important;
    left: 14.3%!important;
    &> .tooltip-inner {
        opacity: 1!important;
        filter: alpha(opacity=100)!important;
    }
}
&> .tooltip-inner {
    background: $green;
    min-width:32rem;
    opacity: 1!important;
    filter: alpha(opacity=100)!important;
    border-radius: 0px;
    padding:1.7rem;
    text-align: left;
    font-size: 1rem;
    line-height: 1.4;
    font-family: 'Gotham-light';
   
}
&>.tooltip-arrow {
    border-bottom-color:$green!important;
    left:10%!important;
    transform: transform(translate(0,0))!important;
}
}
.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {border-width: 0px;}
.bs-tooltip-top .arrow{
 left: 10%!important;
&:before {
    border-bottom-color:$green !important;
    border-top-color:$green !important;
    border-top-width:17px !important;
    border-right-width: 17px;
    border-left-width: 17px;
    }
}
.bs-tooltip-bottom .arrow {
    left: 10%!important;
    &:before {
        border-bottom-color:$green !important;
        border-top-color:$green !important;
        border-bottom-width:17px !important;
        border-right-width: 17px;
        border-left-width: 17px;
        }
}
.bs-tooltip-bottom  {
    top:0.3rem!important;
}
.checkbox_1 {
 width: 1.87rem;
 height: 1.87rem;
 position: absolute;
 left: 0.4rem;
 top: 0.7rem;
 display: block;
 z-index: 10;
 opacity: 0;
 cursor: pointer;
 &+span {
     //border:1px solid $light-brown;
      @include box-shadow(0px 0px 5px rgba(0,0,0,0.1));
     background: $white;
     display: block;
     width: 1.87rem;
     height: 1.87rem;
     float: left;
     border-radius: 2px;
     margin-right: 1.2rem;
     &:after {
         position: absolute;
         width: 0.6rem;
        height: 1rem;
         border-right:2px solid $white;
         border-bottom:2px solid $white;
         content:"";
         left:1rem;
         top:0.9rem;

        @include transform(rotate(41deg));
     }
     & + label {
         padding-top:0.1rem;
     }
 }
 &:checked {
    &+span {
        background: $blue;
        border-color: $blue;
    } 
 }

}
.internalPage .block-contact.block-getQuote {
    .form-control, .form-group .stylish-select-left .stylish-select-right {
        height: 3.3rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .form-group .stylish-select-left {
        &:after {
            margin-top:2px;
        
        }
    .stylish-select-right {
        height: 2.8rem;
        margin-top:0.5rem;
       
    }
}
    .tab-section {
        .form-control, .form-group .stylish-select-left .stylish-select-right {
            height: 3.93rem;
        }
    }
}
.purchase-col {
    p {
        span {
            padding-right: 15px;
        }
    }
}
.progressbar-mobile {
    display: none;
}
.internalPage .block-contact .none-border .form-control {border-bottom: none;}
.block-getQuote .stylish-select-right.styledSelect {padding-right: 21px!important;}
.dark-text .megaMenu.navbar-nav li .nav-link {
    color: $black;
}
.dark-text .selLanguage .stylish-select-left::after {
    border-color: $black;
}
#msform {
.animatePl {
    position: relative;
    span.alertMassage {
        // bottom: -2.2rem;
        // position: absolute;
        &.mark{
        position: absolute;
        content: "!";
        color: $red;
        width: 5px;
        height: 20px;
        right:25px;
        top:20px;
        bottom: auto;
        display: none;
        }
       
    }
    &.active {
        &:before {
            display: none;
        }
    }
}
}
.internalPage .block-contact.block-getQuote .tab-section .form-group.designation  .stylish-select-left .stylish-select-right{
    height: auto;
}
.internalPage .block-contact.block-getQuote .tab-section .form-group.designation  .select {height: 3rem;}
.userloginBox .form-group.active label.drpdwnlabel,.userloginBox .form-group label.drpdwnlabel {
    top: -1.5rem !important;
    font-size: 1.1rem !important;
}

@include media-breakpoint-down(lg) {

        .contact_form.userloginBox {display: flex!important;}

    
.insuration-info-list {
    li {
        font-size: 1rem;
    }
}
#msform {
    
    h5 {
        span {
            display: inline;
        }
    }
    
    #progressbar{
        
    li  {
      font-size: 1rem;
    &:before{
        margin-right: 1rem;
        font-size: 0.8rem;
        width: 2.7rem;
        height: 2.7rem;
        line-height: 2.5rem;
  }
  &.done-active:after {
    width: 11px;
    height: 16px;
    left: 17px;
  }
}
 }

 }
 #msform #progressbar li {font-size: 0.9rem;}
 .step_declaration { padding: 1.5rem 0;}
 #msform #progressbar li {margin-right: 0.8rem;}
 .tab-section {
    .pricePerMonth {
        font-size: 2.6rem;
        small {
            font-size: 1rem;
        }
    }

}
.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
left: 5.25rem !important;
}
#msform #progressbar:before {
    height: 2.7rem;
    top: 0;
}
#msform {
    margin: 0;
}

}

@include media-breakpoint-down(md) {
    .block-getQuote {
        .contact_form.userloginBox {display: flex!important;}
    }
    
    .insuration-info-list{
        li{
            display: block;
            width: 100%;
            overflow: hidden;
            margin: 0 0 0.6rem;
        }
    }

    #msform {
         padding: 2rem 0rem 0;
        .step-content fieldset .fieldset-form-wrap {
            @include box-shadow(0 0 3px rgba(0,0,0,0.1));
        }
        h5 {
            font-size: 1rem;
        }
        #progressbar {
            padding: 0 0 0.5rem;
            margin:0;
            border-radius: 0;
            text-align: center;
            position: relative;
            
            li {
                text-align: center;
                display: inline-block;
                width:20%;
                min-width:3rem;
                vertical-align: top;
                bottom:-2rem;
                span {
                    display: none;
                }
                &:before{
                float: none;
                display: block;
                margin: 0 auto 0.3rem;
                }
        
            }
        }
        .step-nav-wrap{
            @include box-shadow(0px -2px 3px rgba(0,0,0,0.07));
        .progressbar-mobile {
            display: block;
            list-style: none;
            padding: 1.5rem 1.5rem 0;
            background:$white-300;
            margin:0;
            li {
                display: none;
                &.active {
                    display: block;
                    font-family: 'Gotham-light';
                    color: $blue;
                    font-size: 1.2rem;
                    text-align: center;
                    span {
                        display: block;
                        color: $darker-brown-1;
                        font-size: 1rem;
                        padding-top: 0.8rem;
                    }
                }

            }
        }
        }
        .contact_form.row {margin: 0;}
        .action-button {margin-left:0!important;margin-bottom: 1rem;}
        .step-content {
            top:0;

            fieldset {
                &>.row {
                    margin:0 -15px;
                }
            }
            fieldset.step-last{
                h5.title-5 {
                    margin-bottom: 2rem;
                }
            }
            
        }
    }
    .internalPage .block-contact.block-getQuate {
        aside {
        padding:1rem 0 0;
        .getQuate_aside {
            width: 100%; float: none;margin:0 0 1.5rem;
        h3 {
            font-size: 1.1rem;
        }
        ul {
        li {
            padding: 0 0 1rem 1.7rem;
        }
    }
    }
    }
}
.internalPage .block.block-getQuate:last-child {padding: 9.12rem 0 0;}
    #msform #progressbar li.done-active:after{left: 50%; margin-left:-5.5px;}
    .tab-section {
        padding: 0rem 0 2rem;
        margin-bottom: 2rem;
        .nav-tabs {
            .nav-item {
                display: inline-block;
                vertical-align: middle;
                width: 30%;
            }
        }
     .nav {
        .nav-link {
            border-left:none;
            border-bottom:5px solid transparent;
            padding: 1rem;
            font-size: 1rem;
            text-align: center;
            &.active {
            border-left:none;
            border-bottom: 5px solid $green;
            }
        }
     }
     .offer-txt {
         padding: 0 0 0.6rem;
     }
     .tab-content {
         padding: 1.2rem 1rem;
     }
     .tab-pane {
         h6 {
             font-size: 1.1rem;
         }
     }
     .pricePerMonth {font-size: 1.6rem;}
    }
    .tab-section .tab-pane .form-group .stylish-select-left .stylish-select-right {
        font-size: 1.4rem;
    }
    .quick-contact-link {padding:2rem 0 0;}
    .tab-section .col-lg-4 ,.tab-section .col-lg-3{margin:0 15px;}
    .action-button {vertical-align: top;margin-right: 0.5rem;}
    .quick-contact-link li {margin:0 0 1rem;}
    .quick-contact-link li a svg, .quick-contact-link li a strong {display: inline-block;}
    .tooltip > .tooltip-inner, .bs-tooltip-bottom > .tooltip-inner, .bs-tooltip-auto[x-placement^="bottom"] > .tooltip-inner {
        min-width: 18.75rem;
    }
    .tooltip.show, .bs-tooltip-bottom.show, .show.bs-tooltip-auto[x-placement^="bottom"] {left:0!important;}
    .internalPage .block-contact.block-getQuate .form-group.renewal-col {
      padding: 0 15px;
    }
    .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow,
    .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow  {
     left: 8.4rem!important;
    }
    .block-getQuate {
        h2 {
            font-size: 3rem;
           
        small {
            font-size: 1.7rem;
        }
        }
    }
    #msform #progressbar{
        background: $white-300;
        &:before {
            display: none;
        }
    
    }
    #msform .step-content fieldset .fieldset-form-wrap {
        padding: 4rem 2.5rem 2rem;
    }
    #msform #progressbar li::before {
        width: 2.3rem;
        height: 2.3rem;
        line-height: 2rem;
    
    }
    #msform #progressbar li.done-active::after {
        top:0.5rem;
    }
    #msform .action-button {
        margin:0 1rem 0 0rem;
    }

}
@include media-breakpoint-down(sm) {
    #msform{
        #progressbar {
            li {margin: 0; padding: 0 0.4rem;font-size: 0.9rem;}
        }
        .form-col {
            &>div {
            padding: 0;
            .pl-m {
                padding-left: 15px!important;
            }
            }
        }
        .action-button {
            margin: 1rem auto !important;
            display: block;
            width: auto;}

            .step_declaration {
                padding: 0;
            }
            .step-content {
                fieldset {
                    top:1rem;
                    .row {
                        margin:0;
                        padding-bottom: 1rem;
                    }
                }
               
            }
    .step-subtitle {
        .pl-0 {
        padding-right: 0!important;
        }
    }
    }
    #msform .step-content fieldset .fieldset-form-wrap {padding: 5rem 2rem 2rem;}
    .internalPage .block-contact.block-getQuate .form-group {margin-bottom: 1rem;padding-left:0;}
    .tab-section {
        padding: 0rem 0 1rem;
        .tab-pane {
            .clearfix {
                margin-bottom: 1.5rem;
                &>div {float: none; width: 100%;
                &:last-child{padding:1rem 0 0 0px;width: 100%;}
                }
            }
            .purchase-col {
                .actiaction-button {
                    margin: 1rem 0 !important;
                    display: block;
                }
                p {
                    text-align: left;
                    margin-left:0;
                    display: block;
                }
            }
        }
     
      .nav {
        .nav-item {
           .nav-link {padding: 0.7rem 0.3rem; font-size: 1rem;}
        }
      }
    }
    .internalPage .block-contact.block-getQuate .form-group {padding:0;}
    .internalPage .block-contact.block-getQuate aside {padding: 0;}
    .internalPage .block-contact.block-getQuate aside .getQuate_aside ul li {font-size: 0.9rem;padding: 0 0 0.5rem 1.6rem;}
    .internalPage .block-contact.block-getQuate aside .getQuate_aside {margin-bottom: 0;}
    .tab-section.motor-tab-section h6{font-size: 0.8rem;}
    .tab-section.motor-tab-section .pricePerMonth {font-size: 2.5rem;}
    .tab-section.motor-tab-section p {font-size: 0.9rem;}
    .tab-section.motor-tab-section {width: 88%; margin: 0 auto 2rem;}
    #msform .renewal-col label {margin:0;}
    .purchase-col.motor-purchase-col p {margin-left: 0;display: block;padding-top:0.3rem;}
    .purchase-col.motor-purchase-col {text-align: center;}
    #msform #progressbar {margin:0;}
    .block .action-button {max-width: 100%;text-overflow: ellipsis;overflow: hidden; white-space: nowrap;}
    .block.block-getQuote .lg .heading5 {position: static;top: auto; right: auto;left: auto; 
     @include transform(translate(0,0));
     float: none !important;
     padding: 2rem 0 0;
    }
    .block-getQuate h2 small {padding-top: 1rem;}
    .benefits-list  {
        li {
        text-align: center;
        &>div{ 
        padding-right: 0;
         &:last-child{
         padding-left: 0;
        }
    }
        }
    }
    .internalPage {
        .block.promotionBlock {
            h2,p,h3 {text-align: center;}
        }
    }
    #msform .renewal-col strong,   #msform .renewal-col span {
        margin:0;
    }
    .tooltip > .tooltip-inner, .bs-tooltip-bottom > .tooltip-inner, .bs-tooltip-auto[x-placement^="bottom"] > .tooltip-inner {
        min-width: 15rem;
        padding:1rem!important;
    }
    .form_tooltip {z-index: 1000 !important;
        @include transform(scale(0.7));
        right: 0% !important;}
        #msform{
            padding: 1rem 0rem 0;
            .customSelect-tooltip {
            .stylish-select-left {
                .stylish-select-right {
                padding-right: 2.7rem;
                }
                &:after {
                right: 2rem;
                }
            }
        }
        .blank-col {
         margin:0;
        }
        }
        .internalPage .block-contact.block-getQuate .form-group.renewal-col {
            padding: 0;
             }
             .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
                border-bottom-width: 13px !important;
                border-right-width: 13px;
                border-left-width: 13px;
            
            }
            .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
                border-top-width: 13px !important;
                border-right-width: 13px;
                border-left-width: 13px;
            
            }
            .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow,
            .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
             left:7rem !important;
            }
            .block.block-getQuote .lg .heading5 {display: none;}

            .benefits-list li > div:last-child {padding-left:0;}
            .internalPage .offerBlock .content-wrap {
                padding:1rem 2rem!important;
            }
            .block-getQuote .form-group ul.listing {width: 86%;}
            #msform .m-t-1 {
                margin-top: 0rem;
                margin-bottom: 1rem;
            
            }
            .internalPage .block-contact.block-getQuate .form-group.renewal-col {padding-top:1rem;}
            .internalPage .block-contact.block-getQuate .form-group.renewal-col  .row {padding-bottom: 0!important;}
            .insuration-info-list li strong{
                display: inline-block;
                min-width: 100px;
            }
            .block-getQuote .stylish-select-right.styledSelect {padding-right: 43px!important;}
            .insuration-info-list li i.estimate-time {width: 40px;}
            .tab-section.motor-tab-section {width: 100%;}
        }

@include media-breakpoint-down(xs) {
    .tab-section {
        margin-bottom: 1rem;
    .nav {
        .nav-item {
            
           .nav-link {padding: 0.7rem 0.3rem; font-size: 0.8rem;}
        }
      }
     .tab-content {
        padding: 1.2rem 0;
      }
    }
    #msform .step-content fieldset.step-last h4{font-size: 1rem;}
    section {padding: 0;}
    .quick-contact-link {padding: 1rem 0 0;}
    #msform{
        h5 {
        font-size: 0.8rem;
    }
}
.quick-contact-link li {
    display: block;
    margin:0 0 0.5rem;
    min-width: 14.68rem;
}
.block .action-button {font-size: 0.9rem;}
#msform #progressbar {margin:0;}
#msform .step-content fieldset .fieldset-form-wrap {
    padding: 4rem 1rem 1rem;
}
.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow,
.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    left: 11.6rem !important;
   }
   .block-getQuate {
    h2 {
        font-size: 2rem;
       
    small {
        font-size: 1rem;
    }
    }
}
}

.internalPage .contact_form label.form-checkbox {
    position: relative;
    line-height: 1.125rem;
    padding-left:1.7rem;
    margin-bottom: 0.5rem;
    padding-right: 0.8rem;
    vertical-align: top;
    float:left;
    width: 30%;
    @include media-breakpoint-down(md) {
        width: 100%;
       float: none;
    }
    input[type="checkbox"] {
        position: absolute;
        left:0;
        top:0;
        width: 1.0625rem;
       height: 1.125rem;
       opacity: 0;
       cursor: pointer;
    }
    &:before {
        border: 0.0625rem solid #c9c9c9;
        border-radius: 0.125rem;
        position: absolute;
        content: "";
        width: 1.0625rem;
       height: 1.125rem;
       left:0;
       top:0rem;

    }
    &:after {
        content: '';
position: absolute;
left: 0.3rem;
top: 0.15rem;
background: #fff;
width: 0.5rem;
height: 0.125rem;
display: block;
width: 0.4375rem;
height: 0.75rem;
border: solid #00a9e0;
    border-top-width: medium;
    border-right-width: medium;
    border-bottom-width: medium;
    border-left-width: medium;
border-width: 0 0.125rem 0.125rem 0;
 @include transform(rotate(46deg));
 display: none;
 cursor: pointer;
    }
    &.form-checkbox-active {
        &:after {
            display: block;
        }
    }
}