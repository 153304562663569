.userloginBox {
    z-index: 0;
    .outerWrap{position: relative;z-index: 2000}
    &.active{
        z-index: 4000;
    }
    // @include media-breakpoint-down(sm) {
    //     display: none;
    // }
    .header {
        //margin-top: 1.90rem;
        margin-bottom: 0;
    }
    .display-1,
    h4 {
        font-family: 'Gotham-Book';
    }
    .display-1 {
        font-size: 1.875rem;
        color: $brown-200;
        margin-bottom: 0.2rem;
    }
    h4 {
        color: $silver-400;
        letter-spacing: 0.5px;
    }
    &.show {
        .dropdown-toggle {
            background-position: -419px -3px;
        }
    }
    form {
        margin-bottom: 1.5rem;
        .dropdown-item {
            padding-left: 0;
            padding-right: 0;
            opacity: 0.7;
            text-align: left;
            margin-bottom: 0.95rem;
            margin-top: 34px;
            font-size: 0.85rem;
            a {
                color: $grey-300;
                text-decoration: underline;
            }
        }
        .btn {
            margin-bottom: 1.2rem;
            padding: 0.52rem 2rem 0.45rem;
            font-size: 1rem;
        }
    }
    .form-check {
        padding-left: 0;
    }
    .form-check-label {
        font-family: 'ScalaSans-Light';
        font-size: 0.875rem;
        color: $silver-350;
    }
    .dropdown-item {
        color: $grey-300;
        opacity: 0.7;
        text-align: center;
        background: $white;
        outline: 0 !important;
        box-shadow: none !important;
        font-size: 0.85rem;
        letter-spacing: 0.4px;
        a {
            color: $grey-300;
        }
    }
    .form-group {
        margin-bottom: 1.2rem;
        position: relative;
        label {
            position: absolute;
            color: $grey-300;
            opacity: 0.70;
            font-family: 'ScalaSans-Light';
            font-size: 1rem;
            top: 9px;
            margin-bottom: 0;
            @include transition(0.3s ease-in-out);
        }
        &.active {
            label {
                top: -1.1rem;
                font-size: 0.8rem;
            }
        }
    }
    .form-control {
        padding-left: 0;
        padding-right: 0;
        border: 0;
        border-bottom: 1px solid $blue;
        border-radius: 0;
        // outline: 0 !important;
        box-shadow: none !important;
        position: relative;
        z-index: 2;
        background: transparent;
        padding-right: 25px;
    }
    .errorUs{
        .form-control{
            position: initial;
        }
    }
    .form-group label{
        z-index: 1;
    }
    .dropdown-menu {
        left: auto;
        right: -1.15rem;
        .dropdown-wrap .login-form-wrap {
             max-height: 24rem;
             overflow: auto;
             @include media-breakpoint-down(lg) {
                max-height: 16rem;
             }
             @include media-breakpoint-down(sm) {
                max-height: 12rem;
             }
            form {
                padding-top: 1.2rem;
            }

        }
        @include media-breakpoint-down(lg){right: 0;}

        width: 19.4375rem;
        top: 2.5625rem;
        padding: 2.15rem 2.15rem 1.6rem;
        &:before {
            content: '';
            width: 0;
            height: 0;
            border-bottom: 0.8125rem solid #fff;
            border-left: 0.8125rem solid transparent;
            border-right: 0.8125rem solid transparent;
            position: absolute;
            top: -0.79rem;
            right: 2rem;
        }
    }
    .checkmark{
        right: 1.25rem;
        top: 1.0625rem;
        left: auto;
        position: absolute;
        display: none;
    }
    .hasSuccess{
        .checkmark{
            display: block;
        }
    }
    &.active{
        .dropdown-menu{
            display: block;
           
        }
    }


}//user login box ends


.lang-default-img + .dropdown-menu {
    right: -1.1rem;
    &:before {
      right: 1rem;
    }
    @include media-breakpoint-down(lg){
        right: 0.4rem;
    }
}
.text-success {
    color: $green!important;
    display: block;
    padding: 0.2rem;
}