.selLanguage {
    width: 2.625rem;
    height: 1.75rem;
    @include media-breakpoint-down(lg){margin-right: 1rem;}
    @include media-breakpoint-down(sm){display: none;}

    .main {
        min-height: 1.625rem;
        z-index: 0!important;
        @include transform(translatey(-50%));
        top: 50%;
        ul.listing {
            left: auto;
            right: 0;
            min-width: 5rem;
            .flag {
                margin-right: 1.4rem;
            }
            li {
                padding-left: 0.5rem;
            }
        }
    }
    .stylish-select {
        height: 28px;
    }
    .stylish-select-left {
        color: $white;
        font-size: 1rem;
        font-family: 'ScalaSans-Light';
        .stylish-select-right {
            border: 0;
            padding: 1px 0px;
            line-height: 1.4;
            position: relative;
            top: 1px;
            padding-right: 1rem;
            strong{
                font-size: 1rem;
                display: inline;
                padding:0rem 0 0 1rem;
                position: relative;
                top:0.1rem;
                font-weight: normal;
                color: $grey-300;
                font-family: 'pf_encore_sans_proregular', sans-serif;
            }
        }
    }
    .stylish-select-left:after {
        border-color: $white;
        right: 3px;
        top: 57%;
        height: 8px;
        width: 8px;
    }
    .flag{
        width:24px;height:21px;background-repeat:no-repeat;background-size:24px 19px;    background-position: center;display: inline-block;vertical-align: middle;
    
  
    
    }

 
    & + .selLanguage  {
        margin-left: 1rem;
    }
}

// header {
//     .navbar  {
//         .container {
//             @include media-breakpoint-up(lg) {
//               max-width: 1200px;
//             }
//             .megaMenu.navbar-nav > .nav-item .nav-link {
//                 padding: 1.20rem 0.60rem;
//             }
//             .megaMenu {
//                 font-size: 0.76rem;
//             }
            
//         }
//     }
// }
.selLanguage .main ul.listing {
li {
    padding-left: 3rem;
    position: relative;
}
.flag {
    position: absolute;
    left:0.5rem;
    top: 0.6rem;
}

}