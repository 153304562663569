.block-calculator{
    &.blockCar-calculator:last-child {
        padding-bottom: 2.4rem!important;
    }
    h2.lg {
        font-size: 3.8rem;
        font-family: 'Gotham-medium';
        line-height: 1;
        &>span {
            span {
                display: block;
             
            }
            
                &.clearfix {
                    span{
                    max-width: 70%;
                    }
                }
        }
    }
    .result-show {
        display: none;
    }
    .navbar-expand .navbar-nav {
        width: 100%;
    }
    .quickLinks.display1 {
        margin-bottom: 0;
        padding-bottom: 0;
    }
  h3 {
      font-size: 1.125rem;
      color: $blue;
      font-family: 'Gotham-medium';
      border-bottom: 1px solid  $blue;
      padding-bottom: 0.5rem;
    //   margin-bottom: 1.4rem;
      margin-bottom: 1rem;
      &~h3 {
        padding-top: 2rem;
      }
  }
}
.calculator-heading {
    
    p {
        font-size:1.125rem;
        color: $brown-200;
        margin-top: 3rem;
        @include media-breakpoint-down(md) {
            margin-top: 0rem;
        }
    }
}
.block .lg-heading-wrap.calculator-heading  {
    margin-bottom: 2.1rem;
    @include media-breakpoint-down(md) {
       margin-top: 2.5rem;
       .lg {
           margin:0!important;
       }
       .heading5 {
           padding-top:0!important;
       }
       p {
           margin-top: 1.5rem;
       }
    }
    @include media-breakpoint-down(sm) {
        margin-bottom: 1.5rem;
        p {
            margin-bottom: 0rem;
        }
    }
}
.slider-range-min {
    margin: 3rem 1.5rem 0;
    width:auto;
    }
.calculator-main-content {
    @include box-shadow(0px 3px 30px rgba(0, 0, 0, 0.1));
    background: #f8fcff;
    position: relative;
    // padding: 2.5rem 2.75rem 0rem;
    padding: 2.5rem 2rem 0rem;
    position: relative;
    z-index: 100;
    
    & [class*="col-"] {
        position: static;
    }
    &:before {
        background: $white;
        position: absolute;
        content: "";
        left:0;
        top:0;
        width:58.3333%;
        height:100%;
       
        }
        .calculator-form{
            // padding-right: 3.6rem;
            padding-right: 2rem;
        }
        .info_box {
            // padding-left:3.625rem;
            padding-left: 2.6rem;
            margin-bottom: 5rem;
            h2 {
                font-size: 1.8rem;
                font-family: 'Gotham-Book';
                padding-bottom: 0.7rem;
            }
            p {
                font-size: 1rem;
                margin-bottom: 1rem;
            }
        }
        .input-label {
            opacity: 0;
        }
       
        .min{
            position: absolute;
            left: -1rem;
            top: -0.5rem; top: -0.5rem;
        }
        .max{
            position: absolute;
            right:-1.5rem;
            top: -0.5rem;
        }
        .min,.max, .middle1,.middle2,.middle3 {
            font-size: 0.875rem;
            font-family: 'Gotham-Book';
        }
        .middle1, .middle2,.middle3  {
            position: absolute;
            top:1.5625rem;
        }
        .middle1 { 
            left: 33.33%;
        }
        .middle2 {
            left: 66.66%;
        }
        #middle12 {
            left: 2%;
         &:after{
            left: 56%;
         }
        }
        #middle22 {
            left: 35%;
            &:after{
                left: 67%!important;
            }
           }
           #middle32 {
            left: 69%;
            &:after {
                left: 52%;
            }
           }
        .middle1:after, .middle2:after ,.middle3:after{
         width:0.1rem;
         height: 1.875rem;
         background: #e3e3e3;
         position: absolute;
         left: 50%;
         margin-left: 0.5px;
         top:-2.1rem;
         content: "";
        }
        #middle12:after,#middle22:after, #middle23:after {
            left: 72%;
        }
        .ui-widget-content {
          background: #f3f3f3;
          border:none;
          border-radius: 30px;
          height: 0.3125rem;
        }
        .ui-widget-header {
        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffd900+0,febc00+100 */
        background: #ffd900; /* Old browsers */
        background: -moz-linear-gradient(left, #ffd900 0%, #febc00 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(left, #ffd900 0%,#febc00 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right, #ffd900 0%,#febc00 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffd900', endColorstr='#febc00',GradientType=1 ); /* IE6-9 */
        border:none;
        border-radius: 30px;
        height: 0.3125rem;
    
    }
    .ui-slider-horizontal .ui-slider-handle {
        width: 1.25rem;
        height: 1.25rem;
        border:none;
        background: $blue-200 url(../images/range-icon.png) no-repeat center center;
        top: -0.5rem;
        opacity: 1;
        margin-left: -1.25rem;
    }
    .navbar-expand {
        padding:0;
    }
    .pageOptions {
        background: none;
        padding-top: 3.4375rem;
        text-align: left;
        li a {
            font-size: 0.7rem;
        }
        .list .nav-link::before, .list a::before{
            width: 36px;
            height: 33px;
            margin-right: 0;
        }
       .list .nav-link[data-rel="print"]::before, .list a[data-rel="print"]::before {
           background-position:0 -324px;
       }
      .list .nav-link[data-rel="share"]::before, .list a[data-rel="share"]::before,
      .list .nav-link[data-rel="print"]::before, .list a[data-rel="print"]::before {
        position: relative;
        top: 0.2rem;
      }
    } 
    .needs-result-col {
        .pageOptions {
            padding-top: 2.6rem;
        }
    }
    &.ars-main-content {
        .pageOptions {
            padding:2rem 0 2.5rem;
        }
        .result_box ul.result-list li {
            padding:1.2rem 0;
        }
    }
    .button-wrap {
        padding-top: 2.5rem;
        &.car-button-wrap {
            padding-top: 0.3rem;
        }
    }   
}

.internalPage .block-contact.block-calculator {

.form-control{
    font-size: 0.875rem;
    padding-left: 0;
    &[disabled="disabled"] {
        background:$white-150;
        margin-top:1rem;
        cursor: not-allowed;
        height: 2.3rem;
    }
}
}
.internalPage .block-contact.block-calculator .ars-calculatorblock .form-group label {
    &.disabled-label {
        color: $brown-100 ;
    }
}
.internalPage  .block-contact.block-calculator  .form-group {
    margin-bottom: 1.5rem!important;
    padding-top:2rem;
   
    &.form-date {
        padding-top:1rem;
        z-index: 1;
    }
    &.date-picker-wrap {
        padding:1.6rem 0 0;
        margin-bottom: 0.3rem;
        label {
            position: static;
            padding-bottom: 1.5rem;
        }
    }
    &.rangeLabel{
        padding:0!important;
        label {
            position: static;
            padding-bottom: 0rem;
            padding-right: 11rem;
        }
    }
    label{
        font-size: 1.125rem;
        font-family: 'Gotham-medium';
        margin-bottom: 0;
        display: block;
        color: #383b40;
        opacity: 1;
        padding-left:0;
        padding-right: 0;
        span {
            color: $blue!important;
            font-size: 1.125rem;
            font-family: 'Gotham-Book';
            color: #383b40;
        }
        sup {
            color: #ff0000;
            font-size: 1.5rem;
            top: 0rem;
            font-family: 'Gotham-light';
            left: 0rem;
            &.start_icon {
                top:0.3rem;
            }
        }
        strong {
            font-family: 'ScalaSans-Light';
            font-size: 0.875rem;
            display: block;
            color: #666666;
            padding-top: 0.2rem;
        }
     
    }
    &.animatePl.active {
        label {
    @include transform(scale(0.9) translateX(-5.5%));
    }
    }
}
.internalPage  .block-contact.block-calculator  .form-calculator-default .form-group {
    padding-top:0.8rem;
} 

.tooltip-range {
    position: absolute;
    z-index: 1020;
    display: block;
    padding: 5px;
    font-size: 11px;
    visibility: visible;
    margin-top: -2px;
    bottom:120%;
    margin-left: -0.5rem;
    display: none;
}
.ui-state-focus.ui-state-active  .tooltip-range {
display: block;
}

.tooltip-range .tooltip-arrow {
    bottom: 0;
    left: 50%;
    margin-left: -5px;
    border-top: 5px solid #000000;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    position: absolute;
    width: 0;
    height: 0;
}

.tooltip-inner-range {
    max-width: 200px;
    padding: 3px 8px;
    color: #ffffff;
    text-align: center;
    text-decoration: none;
    background-color: #000000;
    -webkit-border-radius: 4px;
       -moz-border-radius: 4px;
            border-radius: 4px;
}

.button-wrap {
    padding-top:2.1rem;
    .btn {
        min-width: 13.5625rem;
        font-size: 1rem;
        padding: 0.8rem 2rem!important;
        &.step-next-button,&.step-prev-button {
            min-width: 1px;
            margin-left: 0.6rem;
        }
        &.step-prev-button {
            background: $grey-100;
            color: $darker-brown-1;
            border-color:  $grey-100;
            &:hover {
                background: $blue;
                border-color: $blue;
                color: $white;
            }
            &+.calculator-btn {
                margin-left: 0.6rem; 
            }
        }
    }
    &.step-button-wrap {
      text-align: right;
      padding-bottom: 1.25rem;
    }
}

.result_box {
    // position: absolute;
    // width: 50%;
    height: auto;
    // top:0%;
    // right:0;
    background: #f8fcff;
    // padding:0 2rem 0 3.375rem;
    padding:0 0.8rem 0 1.5rem;
    display: none;
    &.unit_result_box {
        padding: 0 2rem 1.4rem 2.5rem;
    }
    // @include transform(translateY(-50%));
    ul.result-list {
        padding:0;
        list-style: none;
        border-top:none;
        li {
            list-style: none;
            border-bottom: 1px solid $gray-e8;
            padding: 1rem 0;
            &>span {
                font-size: 0.875rem;
                font-family: 'Gotham-Book';
                width: 50%;
                &>span {
                    color: $blue;
                }
            }
            &>span, &>strong {
                display: inline-block;
                vertical-align: middle;
              
            }
            &>strong {
                width:47%;
                padding-left: 1rem;
                font-family: 'Gotham-Book';
                font-size: 1rem;
                font-weight: normal;
                color: #000;
                text-align: right;
                &.result_total {
                    font-family: 'Gotham-Bold';
                    font-size: 1.2rem;
                    
                }
            }
        }
    }
    h2 {
        font-size: 1.875rem;
        color: $blue;
        text-transform: uppercase;
        font-family: 'Gotham-Bold';
    }
    h6 {
        font-size: 1.125rem;
        border-bottom: 1px solid #e8eaeb;
        padding-bottom: 1.8rem;
        margin-bottom: 0;
    }
}
.quickLinks-3 {
    padding:1.5rem 2.75rem;
    
    p {
        font-size: 1.125rem;
        margin-bottom: 2.3rem;
    }
    ul a{
        font-size:1.375rem;
        font-family: 'Gotham-Light';
        span span,span strong {
            font-family: 'Gotham-Bold';
            font-weight: normal;
        }
    }
}
.internalPage .block-calculator.block-contact.block-getQuote .form-group .stylish-select-left .stylish-select-right {
font-size: 1rem;
color: #666;
background: transparent;
height: auto;
margin-top: 0;
// padding:0.2rem 2rem 1rem 0;
// min-height: 2.7625rem;
padding:0.7rem 2rem 1rem 0;
}
.internalPage .block-calculator.block-contact.block-getQuote .TextSelect-2 .stylish-select-left .stylish-select-right {
    // font-size: 1.125rem;
    // font-family: 'Gotham-medium';
    margin-bottom: 0;
    display: block;
    font-size: 0.875rem;
    opacity: 1;
    padding-left: 0;
    padding-right: 0;
    // &:after{
    //     color: #ff0000;
    //     font-size: 1.5rem;
    //     top: 0rem;
    //     font-family: 'Gotham-light';
    //     right: auto;
    //     position: absolute;
    //     content: "*";
    // }
}

.block-calculator.block-getQuote .form-group ul.listing {
    top:105%!important;
    max-height: 7rem;
    .mCSB_container {
        margin-right:0px;
       
    }
}
.internalPage .block-calculator.block-contact.block-getQuote .TextSelect-2 {
    padding-top: 1rem;
}
.internalPage .block-calculator.block-contact.block-getQuote .form-group .stylish-select-left{
    &:after {
        border-color: $darker-brown-1;
        height: 0.6rem;
        width: 0.6rem;
        top:0.6rem;
    }
}

.date-range-wrap {
    &> div {
        display: inline-block;
        vertical-align: middle;
        position: relative;
    
       input[type="text"]{
         position: absolute;
         width: 100%;
         height: 100%;
         opacity: 0;
         z-index: 10;  
         cursor: pointer;
         &.hasDatepicker:focus{
             outline: none;
             text-indent: -99999px;
         }
         
       }
       .dateRangeText {
           min-width: 8.75rem;
           min-height: 6.6875rem;
           @include box-shadow(0 0 7px rgba(0,0,0,0.15));
           cursor: pointer;
           padding:1.1rem 0.5rem 1rem;

           span {
               display: block;
               text-align: center;
               font-family: 'Gotham-medium';
               font-size: 0.9375rem;
               text-transform: uppercase;
               color: #4c4d4b;
               line-height: 1;
            

           }
           span.dayText {
            color: #92c41c;
            font-family: 'Gotham-Bold';
            font-size: 3.125rem;
            }
         
       }
     
    }
    .dateRange-dash {
        min-width: 5rem;
        text-align: center;
        i {
            display: block;
            margin:0 auto;
            width: 1rem;
            background: #d2d2d2;
            height:0.07rem;
        }
    }
}
.ui-datepicker-title {
    color: $green;
}
.unit_result_box.result_box {
    ul.result-list{
        &>li {
            span {
                color: #010101;
                font-size: 1rem;
            small {
                display: block;
                font-size: 0.9rem;
                color: $brown-200;
            }
          }
          strong {
            font-size: 1.1rem;
            font-family: 'Gotham-Bold';
            text-align: right;
            line-height: 1;
          }
        }
    }
}
.calculator-main-content-2 .pageOptions {
    padding-top: 1.8rem;
}
.result-col {
    background: #f8fcff;
}
.form-calculator {
    padding-top: 0.4rem;
    &.form-calculator-2 {
        .form-group-range {
        margin-bottom: 2.3rem!important;
        }
    }
    &.form-calculator-car {
        .form-group-range {
            padding:3.3rem 0 1.3rem!important;
       
        }
        .alertMassage {
            position: relative;
            z-index: 10;
        }
        
    }
}
.internalPage  {
    .block-contact.block-calculator{
        .form-calculator-car,.form-calculator-2  {
            .form-group.rangeLabel  {
                padding-bottom: 0.1rem !important;
        }
    }
    }
}
.calculator-main-content .userloginBox form {
    margin-bottom: 2.3rem;
}
.internalPage .block.block-calculator:last-child {
    padding-bottom: 4rem;
}
.ui-slider-handle {
    outline: none;
    z-index: 1000!important;
}
#slider-range-min-2,#retirement-range-min,#Withdrawal-range-min {
    margin: 2.5rem 8rem 4rem 7rem;
    &.ui-slider-pips .ui-slider-pip-first {
        margin-left: -2.7rem;
    }
}
#mortgage-range-min {
    margin: 2.5rem 7rem 2rem 8rem;
    &.ui-slider-pips .ui-slider-pip-first {
        left: -6rem!important;
    }
}
.slider-range-min-1 {
    margin-top:2.5rem;
    margin-bottom: 3rem;
}
#retirement-range-min,#Withdrawal-range-min {
    margin: 1rem 8rem 3.8rem 7rem;
}
.calculator-main-content {
    #max2,#max-retirement ,#max-Withdrawal{
        right: -6rem;
    }
}
.calculator-main-content {
  #min2,.min-retirement ,#Withdrawal-range-min #min{
    left: -5rem; 
  }
}
.calculator-main-content {
    #middle1-retirement ,#middle1-Withdrawal{
        left: 5%;
    }
    #middle2-retirement ,#middle2-Withdrawal {
        left: 27.5%;
    }
    #middle3-retirement,#middle3-Withdrawal{
        left: 51.5%;
    }
    #middle4-retirement,#middle4-Withdrawal{
        left: 76%;
    }
}
.internalPage .block-contact.block-calculator .form-group-2 {
    margin-top:3.5rem;
    .form-control {
    height: 2rem;
    }
   
}
.internalPage .block-contact.block-calculator .range-form-calculator {
    .form-group {
        padding-top:3.5rem;
        &.form-group-2 {
            padding-top:2rem;
            margin-top:0;
            margin-bottom:2rem;
        }
        &.form-group-2.TextSelect-2 {
            padding-top:0rem;
            margin-bottom: 0.7rem;
        }
    }
}
.calculator-main-content {
.input-label-show {
position: absolute;
width: 10rem;
text-align: center;
top: 1rem;
// right:-2rem;
right: 0;
border: 1px solid $white-300;
z-index: 10;
border-radius: 3px;
background:$white-150;
padding:0.2rem 0.2rem 0.2rem 0.5rem;
input{
border:none;
font-size: 0.8rem;
font-family: 'Gotham-Book';
color: $dark-brown-100;
max-width:83%;
width:auto;
background:transparent;
padding-left: 0.2rem;
}
&.input-label-show-year {
    input{
        // max-width: 2rem;
        text-align: right;
        padding-right: 0.3rem;
    }
}
}
}
.require-label.require-label-2.active {
@include transform(scale(0.9) translateX(-6%));
top: -1rem;
// top:2px;
}
.internalPage .block-calculator.block-contact.block-getQuote .TextSelect-2  .customSelect-wrap {
    .stylish-select-left .stylish-select-right {
        color: transparent;
        min-height: 2.4rem;
       
    }
    &.active .stylish-select-left .stylish-select-right {
        color: $brown-200;
    }
}
.internalPage .block-calculator.block-contact.block-getQuote .retirementcalculator .TextSelect-2.select-form-group.TextSelect-2 {
    margin-top: 1.5rem;
    padding-top: 0.8rem;
}
.align-col-top {
    .result-col {
        padding-top: 2.5rem;
    }
    .result_box {
        top:3.7rem;
        @include transform(translateY(0));
    }
}
.disclaimer-section {
 background: #fbfbfb;
 .step_declaration {
    padding: 3rem 2.75rem 5rem;
   
 }
}

.ui-slider-pips {
.ui-slider-pip {
    font-size: 0.875rem;
font-family: 'Gotham-Book';
color: #333333;
width: auto;
font-weight: normal;
&:hover {
    font-weight: normal;
}
.ui-slider-line {
    width: 0.1rem;
height: 1.875rem;
background: #e3e3e3;
position: absolute;
left: 50%;
margin-left: 0.5px;
top: -2.1rem;
}
.ui-slider-label {
    width: auto;
    left: 50%;
    @include transform(translateX(-50%));
    margin-left:0;

}
&.ui-slider-pip-first ,&.ui-slider-pip-last{
    .ui-slider-label {
        @include transform(translateX(0%));
        left:0;
        margin-left: -1rem;
    }
}
}
.ui-slider-pip-first {
    left:0rem!important;
    top: -0.2rem;
    .ui-slider-line {
        display: none;
    }
    .ui-slider-label {
        top:0;
    }
}
.ui-slider-pip-last {
    left: auto!important;
    right: -2.5rem !important;
    top: -0.2rem;
    .ui-slider-line {
        display: none;
    }
    .ui-slider-label {
        top:0;
    }
}
}
#slider-range-min-2{
    &.ui-slider-pips  {
        .ui-slider-pip-first {
            left:-3.5rem!important;
        }
    }
}
.ui-slider-pips:not(.ui-slider-disabled) .ui-slider-pip:hover .ui-slider-label {
    font-weight: normal;
}
#Withdrawal-range-min,#retirement-range-min {
    &.ui-slider-pips {
        .ui-slider-pip-first {
            left: -3rem!important;
        }
    }
}
.internalPage .block-contact.block-calculator  {
  .form-group.form-date {
      
      .require-label {
        // top:2rem;
        top: 1.4rem;
        background: #fff;
        z-index: 10;
        @include transform(scale(1) translateX(0));
        &.active-date {
            @include transform(scale(0.9) translateX(-6%));
            position: absolute;
            top:-0.8rem;
        }
      }
    .form-control {
        
        font-size: 1.125rem;
        color: $grey-300;
        font-family: 'Gotham-medium';
        &.dob-datepicker {
            background: url(../images/date-icon.png) no-repeat;
            background-position: right center;
        }
        &.need-dob-datepicker {
            background: url(../images/date-icon-2.png) no-repeat;
            background-position: right center;
        }
   
    }
    .ui-datepicker-trigger {
        position: absolute;
        bottom: 1rem;
        right: 0;
        // background: url(../images/date-icon.png) 98% center no-repeat;
        z-index: 100000;
        cursor: pointer;
    }
}
}

#myChart {
    width:30rem;
    height:26rem;
    font-size: 0.7rem!important;
    overflow: visible!important;
    @include transform(translate(-2rem, -1.5rem) scale(0.8));
    #myChart-top {
        overflow: visible!important;
    }
    svg{

     stroke:  $black;
     fill:$black;
     stroke-width: 0;
     
    }
    #myChart-license-text {
        display: none!important;
    }
    svg:not(:root) {
        overflow: visible;
    }
  }
  #myChart-tooltip {
      left:-10rem!important;
  }
.chart-heading {
    text-align: center;
    margin-bottom: 2rem;
    h4 {
    color: $blue;
    font-size: 1.125rem;
    text-align: center;
    margin-bottom:0;
    }
    span {
        display: block;
        font-size: 1rem;
        font-family: 'Gotham-Book';
    }
}
.internalPage .block-contact.block-calculator  
.ars-calculatorblock  .form-group {
label  {
 font-size: 0.79rem;
 span {
    font-size: 0.79rem;
 }
 
}

}
.internalPage .block-calculator.block-contact.block-getQuote .ars-calculatorblock .TextSelect-2 .stylish-select-left .stylish-select-right ,
.internalPage .block-contact.block-calculator .form-group.form-date .form-control{
    font-size: 0.79rem;
    min-height: 2.4rem;
}
.internalPage .block-contact.block-calculator .form-group.form-date .form-control{
    font-size: 0.79rem;
    color: $brown-200 ;
    font-weight: normal;
    font-family: 'Gotham-light';
}
.calculator-chart {
padding-top:3rem;
border-top: 1px solid #e8eaeb;
}
.internalPage .block-contact.block-calculator {
.calculator-tabWrap {
    .calculator-tab-list {margin-bottom: 2.25rem;}
 .form-calculator {
    padding-top: 0.7rem;
    .form-group  {
        margin-bottom: 2.25rem;
    }
    .form-group.animatePl {
        padding-top: 0.8rem;
    }
 }
 ul.nav-tabs {
    counter-reset: item;
    list-style-type: none;
    position: relative;
    border-bottom: 0.25rem solid $gray-f0;
 li {
    position: relative;
    counter-increment: li;
    width: 33.3333%;
    
    padding-right: 0.125rem;
    &:before {
        content: counter(li);
        content: counter(item, decimal-leading-zero) "."; 
        counter-increment: item;
        display:block;
        width: 100%;
        height: 1;
        line-height:2.3rem;
        font-family: 'Gotham-book';
        font-size: 1.125rem;
        color: $gray-84;
        
    }
    
    a:first-child {
        color: $brown-200;
        font-size: 0.9375rem;
        font-family: 'Gotham-medium';
        width: 100%;
        display: block;
        padding-bottom: 1.2rem;
        position: relative;
        padding-right: 20%;
        min-height: 3.7rem;
        @include media-breakpoint-down(lg) {
          font-size: 0.875rem;
          height: 5rem;
        }
        
        &:hover {
         text-decoration: none;
        }
        
       
        
    }
    &:first-child {
        a:first-child{
            padding-right: 46%;
            word-wrap: break-word;
        
        }
        .form_tooltip {
            right: 34% !important;
            display:block;
           
            @include media-breakpoint-down(lg) {
                right: 20% !important;
            }
        }
    }
    &:last-child {
        margin-right: 0;
    }
    .form_tooltip {
        width: 1.5rem;
        height: 1.5rem;
        top: auto !important;
        bottom: 1.4rem !important;
        right: 10% !important;
        #svg_info,.svg_info {
            max-width: 0.8rem;
            position: relative;
            top: -0.13rem;
        }
    }
    &:after {
        border-bottom: 0.25rem solid $gray-e6;
        content: "";
        position: absolute;
        left:0;
        bottom: -0.25rem;
        height: 0.25rem;
        width: 100%;
    }
    &.active {
        color:$blue;
        &:after {
            border-bottom: 0.25rem solid $blue;
        }
    }
}

 }
}
}

.calculator-tab-content {
    .form_tooltip {
        width: 1.5rem;
        height: 1.5rem;
        z-index: 100;
        right: -1.2rem!important;
       
        @include media-breakpoint-down(lg) {
            top:2rem!important;
        }
        #svg_info,.svg_info {
            max-width: 0.8rem;
            position: relative;
            top: -0.13rem;
        }
    }
}
.tooltip {
    pointer-events: none!important;
    z-index: 100!important;
}
.internalPage .block-contact.block-getQuote .form-group {
.customSelect-infoBox {
 .stylish-select-left::after {
   right: 2.7rem;
}
}

}
.calculator-tab-content {
    &>div {
        display: none;
        &.active-tabContent {
            display: block;
        }
    }
}
.viewCalculate-data {
    text-align: center;
    border-bottom: 1px solid $gray-e8;
    padding: 0.7rem 0;
    margin-bottom: 0.875rem;
    a {
        font-size: 1rem;
        color: $blue;
        font-family: 'Gotham-Book';
        &:hover {
            text-decoration: none;
            color: $green;
        }
    }
}
.needs-result-col {
    ul.result-list {
        margin: 1.9rem 0 0;
        border-top: 1px solid $gray-e8; 

    }
    .Calculate-GetGuote 
    {
    padding:1.8rem 0 0;
    p {
        font-size: 0.875rem;
        color: $brown-200;
        font-family: 'Gotham-light';
        text-align: center;
        line-height: 1.3;
    }
    .button-wrap {
        padding-top:0;
        text-align: center;
        .btn {
            text-transform: uppercase;
            margin-top: 0.5rem;
        }
    }
}
}
.infoCalculate {
    background:  $green;
    border-radius: 15px;
    text-align: center;
    padding:1rem;
    p {
        color: $white;
        font-size: 0.875rem;
        line-height: 1.3;
        font-family: 'Gotham-medium';
        strong{
            font-family: 'Gotham-bold';
        }
    }
}
#needsModal {
 .close {
    position: absolute;
    right: 1rem;
    top: 1rem;
    z-index: 100000;
    cursor: pointer;
 }
 .modal-body {
    padding: 3rem 2rem 2rem;
 }
 .modal-dialog {
     max-width: 700px;
 }
 h3 {
     color: $blue;
     font-family: 'Gotham-Bold';
     font-size: 1.125rem;
     margin-bottom: 0.8rem;
 }
 ul{
     list-style: none;
     padding-left:0;
     margin-bottom:1.8rem;
 li {
     font-size: 0.875rem;
     font-family: 'Gotham-light';
     overflow: hidden;
     margin-bottom: 0.7rem;
     span {
         display: block;
         float: left;
         width:60%;

     }
     strong {
        text-align: left;
        display: block;
        float: right;
        padding-left: 1rem;
        width:40%;
        &.total-ammount {
            font-family: 'Gotham-medium';
        }
     }

 }
 }
}
.familySelect-box {
    & > div {
        display: none;
    }
}
.pageOptions .list .nav-link.a2a_dd:before, .pageOptions .list a.a2a_dd:before {
    display: none;
}
@media only screen and (min-width: 1920px) {
    .block-calculator{
        h2.lg {
            font-size: 4rem;
            font-family: 'Gotham-medium';
        }
    }

}
@media only screen and (max-width: 1300px) {
    #myChart {
       @include transform(scale(0.87) translate(-10%,-5%));
    }
}
@media only screen and (max-width: 1199px) {
    #myChart {
       @include transform(scale(0.71) translate(-15%,-8%));
    }
    .calculator-chart {
        overflow: auto;
    }
}

@include media-breakpoint-down(lg) {
    .calculator-main-content {
    .result_box ul.result-list li > strong {
        font-size: 0.875rem;
        &.result_total {
            font-size: 1rem;
        }
    }
    .pageOptions {
        padding-top:1rem;
    .navbar-nav {
        display: block;
        width: 100%;
    }
    li {
        width: 50%;
        display: block;
        margin-bottom: 1rem;
    }
   }

}
.calculator-main-content .min, .calculator-main-content .max, .calculator-main-content .middle1, .calculator-main-content .middle2, .calculator-main-content .middle3 {
    font-size: 0.7rem;
}
.date-range-wrap > div .dateRangeText {min-width: 8rem;}
.ui-slider-pips .ui-slider-pip {
    font-size: 0.7rem;
}
.internalPage .block-calculator.block-contact.block-getQuote .TextSelect-2 {
    padding-top: 1.8rem;
}
.userloginBox .require-label.require-label-2 {
    top:1.4rem;
    &.active {
        top:0rem;
    }
}
}
@include media-breakpoint-down(md) {
    .calculator-main-content {
        background: none;
        padding:0;
        [class*="col-"] {
            padding:0 2rem;
        }
       .info_box {
          padding: 0rem;
      }
      .calculator-form {
          padding:0;
      }
      .result-col {
       padding-top: 1.5rem;
       padding-bottom: 1.5rem;
       z-index: 10;
      }
    }
    .d-flex {
        display: block;
        [class*="col-"] {
            display: block;
            position: relative;
        }
    }
    .quickLinks-3 {
        padding: 1.5rem 1.75rem;
    }
.mobForm {
    display: none;
}
.calculator-form form {
    padding: 0 3rem;
    margin-bottom: 0;
}
.result_box {
    position: static;
    left:auto;
    right: auto;
    top:auto;
    width: 100%;
    @include transform(translateY(0));
    padding: 1.5rem 2rem;
    margin-top: 4rem;
    &.unit_result_box {
        padding: 1.5rem 2rem;
    }
}
.mob-info_box {
    display: none;
}
.quickLinks-3 {
    margin-top:1rem!important;

         ul a{
             font-size: 1rem;
         }
}
.block-calculator h2.lg {
    font-size: 2rem;
    margin: 1.5rem 0!important;
}
.calculator-main-content .info_box,.calculator-main-content .result_box {
    margin:0;
}
.calculator-main-content .userloginBox form {
    margin-bottom: 0rem;
}
.calculator-main-content .button-wrap {
    padding-top: 2.1rem;
}
.slider-range-min {
   .middle1,.middle2,.middle3 {
        display: none;
    }
}
.calculator-main-content .input-label-show {
    position: static;
    left:auto;
    right: auto;
    top:auto;
    bottom: auto;
    padding-left: 0;
margin-bottom: 1rem;
}
#slider-range-min-2, #retirement-range-min, #Withdrawal-range-min,.slider-range-min {
    margin:0;
}
.calculator-main-content #max2, .calculator-main-content #max-retirement, .calculator-main-content #max-Withdrawal,.calculator-main-content .max ,.calculator-main-content #min2, .calculator-main-content .min-retirement, .calculator-main-content #Withdrawal-range-min #min,.calculator-main-content .min,.calculator-main-content .max{
    bottom:0;
    top:auto;
    bottom:-1.6rem;
}
.calculator-main-content #min2, .calculator-main-content .min-retirement, .calculator-main-content #Withdrawal-range-min #min,.calculator-main-content .min{
left: auto;
}
.calculator-main-content #max2, .calculator-main-content #max-retirement, .calculator-main-content #max-Withdrawal,.calculator-main-content .max {
right: 0;
}
.internalPage .block-contact.block-calculator .form-group label strong {
    font-size: 0.775rem;
}
.internalPage .block-contact.block-calculator .form-group.rangeLabel label {
    padding-bottom: 1rem;
}
.internalPage .block-contact.block-calculator .form-group.rangeLabel {
    margin-bottom: 3rem;
} 
.internalPage .block-contact.block-calculator .retirementcalculator .form-group {
    padding-top: 2.5rem;
    margin-bottom: 2rem;
}
.internalPage .block-contact.block-calculator .retirementcalculator .form-group.form-group-2 {
    margin-bottom: 0;
}
.internalPage .block-contact.block-calculator .form-group.rangeLabel label {
    padding-right: 0;
}
.disclaimer-section .step_declaration {
    padding: 3rem 1.75rem;
}
.internalPage {
    .block-contact.block-calculator {
        .form-control {
           padding-bottom: 0;
           padding-top: 0.7rem;
           
        }
        .form-group {
            margin-bottom: 0.5rem;
            padding-top: 1.6rem;
        }
        .form-group-range {
            p {
                margin-bottom: 0;
            }
        }
    }
}
.calculator-main-content {
    [class*="col-"] {
        padding:0;
        &.result-col {
         padding:2rem 2rem;
         margin: 0 15px;
        }
        .result_box {
         padding:0;
        }
    }
    .calculator-form {
        min-height: 1px!important;
    }
    
}

.block-calculator {
    padding-top:5.5rem!important;
}
.calculator-main-content .calculator-form {
    padding:1rem 0;
}
.calculator-heading {
padding-top:0rem;
}
.form-group-range {
    .input-label-show  {
        margin-bottom: 2.5rem;
    }
}
.form-calculator {
    padding-top: 1rem;
}
.form-calculator-car .form-group-range .input-label-show {
    margin-bottom: 1.8rem;
}
.ui-slider-pip {
    display: none;
    &.ui-slider-pip-last,&.ui-slider-pip-first {
        display: block;
        top:1rem;
    }
    &.ui-slider-pip-first {
        left: 2rem!important;
    }
    &.ui-slider-pip-last {
        right: 0!important;
    }
}
#slider-range-min-2.ui-slider-pips .ui-slider-pip-first {left: 1rem!important;}
#slider-range-min-2.ui-slider-pips .ui-slider-pip.ui-slider-pip-last {right: 5rem!important;}
#Withdrawal-range-min.ui-slider-pips .ui-slider-pip-first, #retirement-range-min.ui-slider-pips .ui-slider-pip-first {
    left: 1rem !important;
}
#Withdrawal-range-min.ui-slider-pips .ui-slider-pip-last, #retirement-range-min.ui-slider-pips .ui-slider-pip-last {
    right: 3rem !important;
}
#myChart {
    @include transform(scale(1) translate(0,0));
    width:100%;
 }
 #mortgage-range-min {
    margin: 2.5rem 0rem 2rem 0rem; 
    &.ui-slider-pips .ui-slider-pip-first {
        left: 2rem!important;
    }
    &.ui-slider-pips .ui-slider-pip-last {
        right: 3rem!important;
    }
}
.internalPage .block-contact.block-calculator .mortgage-main-content .form-group.rangeLabel {
margin-bottom: 1rem;
}
#slider-range-min-2.ui-slider-pips .ui-slider-pip-first, #retirement-range-min.ui-slider-pips .ui-slider-pip-first, #Withdrawal-range-min.ui-slider-pips .ui-slider-pip-first {
    margin-left: 0;
}
.internalPage {
    .block-contact.block-calculator {
        .calculator-tabWrap {
            ul.nav-tabs {
                text-align: center;
                display: block;
                padding-top:5rem;
                position: relative;
                li {
                   
                    display: inline-block;
                    vertical-align: top;
                    padding-right: 1rem;
                    padding-top:1rem;
                    width: auto;
                    position: static;
                    &:last-child {
                        padding-right: 0;
                    }
                   
                    a {
                        &:first-child {
                            display: none;
                          
                        }
                        &.form_tooltip,&.form_tooltip:first-child {
                            display: none!important;

                        }
                    }
                    .form_tooltip,.form_tooltip:first-child {
                        display: none!important;

                    }
                    &.active {
                        a {
                            &:first-child {
                                display: block;
                                text-align: center;
                                padding: 0;
                                position: absolute;
                                top:1rem;
                                width:100%;
                                left:0;
                                text-align: center;
                                 &:after {
                                    bottom: -2.5rem;
                                 }
                            }
                            &.form_tooltip,&.form_tooltip:first-child {
                                display: block!important;
                                right: auto !important;
                               left: 50%;
                               @include transform( translateX(-50%));
                               top: 4rem !important;
                            }
                        }
                        &:before {
                            color:$blue;
                        }
                      
                    }
                }
            }
        }
    }
}
}
@include media-breakpoint-down(sm) {
    .calculator-main-content [class*="col-"] {
        padding: 0 1rem;
    }
    .calculator-main-content .result-col {
        padding-top: 2.5rem;
        padding-bottom: 1.2rem;
       }
       .calculator-main-content .button-wrap {
           padding-bottom: 1rem;
       }
    .calculator-heading p {
        margin-bottom: 1.5rem;
    }
    .calculator-main-content .calculator-form {
        padding: 0
    }
    .calculator-form form {
        padding: 0.5rem 1.5rem;
    }
    .calculator-main-content .info_box h2 {
        font-size: 1.3rem;
    }
    .quickLinks-3 {
     padding:0;
    ul a {
        font-size: 0.8rem;
        span.nav-link {
            padding-left: 0;
        }
    }
}
.result_box {
    padding:0rem;
    background:none;
    margin:0;
    &.unit_result_box {
        padding:0rem;
    }
}
.result_box ul.result-list li > span, .result_box ul.result-list li > strong {
    display: block;
    width: 100%;
    text-align: left;
}
.result_box ul.result-list li > strong {
    padding-left:0;
}
.calculator-main-content .pageOptions li {
    width:100%;
    margin:0 0 1rem;
}
.result_box h2 {
    font-size: 1.2rem;
}
.button-wrap {
    padding-top: 1.1rem;
}
.date-range-wrap {
&>div {
    // display: block;
    // width: 100%;
    // min-width: 1px;
    // margin-bottom: 1rem;
    width: 47%;
    &:first-child{
        margin-right:2%;
    }
    &:last-child{
        margin-left:2%;
    }
    .dateRangeText {
        min-width: 100%;
        min-height: 5.688rem;
    }
}
.dateRange-dash {
display: none;

}
}
.date-range-wrap {position: relative;}
.date-range-wrap > div .dateRangeText span {
    font-size: 0.8rem;
}
.date-range-wrap > div .dateRangeText span.dayText {
    font-size: 2.6rem;
}
.unit_result_box.result_box ul.result-list > li strong {
    text-align: left;
    padding:0.5rem 0;
}
.calculator-main-content .button-wrap {
    text-align: center;
}
.quickLinks-3 {
    margin-top: 2rem !important;
    padding: 0 1rem;
}
.block-calculator.block-getQuote .form-group ul.listing {
    width: 100%;
}

.calculator-form.userloginBox{
    display: block;
}
.disclaimer-section .step_declaration {
    padding: 2rem 1rem;
}
.block-calculator h2.lg > span {
    width: 100%;
    max-width: 100%;
}
.calculator-main-content {
    [class*="col-"].result-col {
        padding:1.5rem;
    }
}
.calculator-main-content  {
    .button-wrap {padding-top:0;}
}
.result_box ul.result-list li > span {
    padding-bottom: 0.7rem;
}
.internalPage .block-contact.block-calculator {
  .form-group.animatePl {
     padding-top:0;
    label {
    position: static;
    padding-bottom: 0rem;
    }
    .form-control {
        height: 1.5rem;
        padding:0;
   }
    &.active {
        label {
            @include transform(scale(1) translate(0,0));
        }
    }
  }
 
}
#myChart {
    @include transform(scale(1) translate(0, 0));
    width:30rem;
 }
 .calculator-chart {
    overflow: auto;
 }
 .internalPage .block-contact.block-calculator .form-control{
    &[disabled="disabled"] {
        margin-top:0.5rem;
    }
}
.calculator-main-content [class*="col-"].result-col {
    margin:0;
}
.internalPage .block-contact.block-calculator .calculator-tabWrap .form-calculator .form-group {margin-bottom: 0.8rem;}
.internalPage .block-contact.block-calculator .calculator-tabWrap .calculator-tab-list {margin-bottom: 1rem;}
.internalPage .block-contact.block-getQuote .calculator-tabWrap .form-group {
    .customSelect-infoBox {
     .stylish-select-left::after {
       right: 1.3rem;
    }
    .form_tooltip {
        right: -0.7rem!important;
    }
}
}
#needsModal{
 .modal-body {
    padding: 3rem 1rem 1rem;
}
ul {
    li{
     span,strong{
        width: 50%;
     }
    
    }
}
}
}
