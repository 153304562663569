header{
    z-index: 2;
}
.banner {
    background-position: 0 0;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    background-image: url(../images/home-banner.jpg);
    height: 35vh;
    min-height: 260px;
    background-position: right top;
    background-color: $brown;
    h2,h1{
        color:$white;
    }
    @include media-breakpoint-up(md) {
        height: 53vh;
        min-height: 340px;
        h2.lg,h1.lg{
            font-size:5rem;
            line-height: 0.90;
        }
    }
}

.internalPage {
    .block {

        &.block-investor {padding-top:3rem;}
        @include media-breakpoint-up(md) {
            padding: 2.7rem 0 2.7rem;
            
            &:last-child {
                padding: 2rem 0 10rem;
                &.block-investor {padding-top:3rem;}
            }
        }
        @include media-breakpoint-up(xl) {
            padding: 2rem 0 3rem;
            &:last-child {
                padding: 2rem 0 10rem;
                &.block-investor {padding-top:3rem;}
            }
        }
    }
    .oddShade{
        > .clearfix:not(:last-child){
            .block {
                @include media-breakpoint-up(md) {
                    padding: 2.7rem 0 2.7rem;
                }
                @include media-breakpoint-up(xl) {
                    //padding: 5rem 0 5rem;
                    padding: 2rem 0 2rem;
                }
            }
        }
    }
}

.block {
    padding: 2.5rem 0;
    @include media-breakpoint-up(md) {
        padding: 3rem 0 4rem;
        &:last-child {
            padding: 3rem 0 7rem;
        }
    }
    .lg {
        color: $sliver-150;
        position: relative;
        span {
            display: block;
        }
       
    }
    .heading5 {
        color: $blue;
        line-height: 1;
        height: auto;
        display: block;
        
        span {
            display: block;
        }
        @include media-breakpoint-down(md) {
            text-align: left;
            padding-top:1.6rem;
        }
    }
    .lg-heading-wrap {
        margin-bottom: 3.2rem;
        @include media-breakpoint-down(md) {
            margin-bottom: 2rem;
        }
        .lg {
            margin-bottom: 0rem;
           
        
        }
        .col-md-4 {
            text-align: right;
            
        }

        .heading5 {
            text-align: right;
            display: inline-block;
            @include media-breakpoint-down(md) {
                text-align: right;
                padding-top:1.6rem;
            }
        }
        .sm-hide {
            @include media-breakpoint-down(sm) {
            display: none;
            }
        }
    }
    .btn {
        margin-top: 1rem;
        &.lg-btn {
            padding: 0.750rem 2.1rem 0.60rem;
        }
    }
    .input-group {
        .btn {
            margin-top: 0;
        }
    }
    &.halfContent {
        .content {
            @include media-breakpoint-up(lg) {
                border-left: 1px solid $silver-100;
                padding-left: 3rem;
            }
        }
    }
    .lead {
        margin-bottom: 1.98rem;
    }
    .text-muted {
        font-family: 'Gotham-Book';
        font-size: 0.875rem;
        color: $silver-300;
        display: block;
        margin-top: 5px;
    }
}

.nav-link.disabled {
    pointer-events: none;
}

.internalPage {
    min-height: 550px;
    z-index: 1;
    position: relative;
}

.pageOptions {
    text-align: right;
    // margin-top: 2rem;
    margin-top: 1rem;
    padding-top: 0;
    padding-bottom: 0;
    background: $white;
    ul {
        padding: 0;
        margin: 0;
    }
    .navbar {
        li {
            a.nav-link,a {
                padding: 0;

                &:hover{
                    text-decoration: none;
                    color: $green!important;
                }
            }
        }
    }
    li {
        margin-right: 1.5625rem;
        text-transform: uppercase;
        display: flex;
        flex-wrap: wrap;
        flex-wrap: wrap;
        a.nav-link,a {
            color: $darker-brown-1;
            padding: 0;
            font-size: 0.875rem;
            padding: 0;
            align-items: center !important;
            display: flex !important;
        }
        &:last-child {
            margin: 0;
        }
    }
    .list {
        .nav-link,a {
            &:before {
                content: "";
                width: 25px;
                height: 26px;
                display: inline-block;
                background-image: url(../images/sprite.png);
                vertical-align: middle;
                background-position: -460px -8px;
                margin-right: 0.5rem;
                position: relative;
            }
            &[data-rel="share"] {
                &:before {
                    background-position: -460px -8px;
                }
            }
            &[data-rel="download"] {
                &:before {
                    width: 25px;
                    height: 33px;
                    background-position: -460px -43px;
                    top: -3px;
                }
            }
        }
    }
}


.floatingMenu{
    @include transition(0.25s ease-in-out);
    //padding:1rem 0;
    padding:0.5rem 0;
    &.pageOptions{
        li{
            color:$darker-brown-1;
            .nav-link,a{
                font-size: 0.85rem;
                @include media-breakpoint-up(lg) {
                    font-size: 0.85rem;
                }
                &:hover{
                    color:$green;
                    text-decoration: none;
                }
                &.active{
                    color:$green;
                   // font-size: 1.2rem;
                }
            }
        } 
    }
}

.loader-wrap {
    text-transform: uppercase;
    font-family: 'Gotham-Book';
    font-size: 0.85rem;
    color: $blue-200;
    margin-bottom: 1.2rem;
    margin: 1rem 0;
    img {
        max-width: 70px;
        margin-bottom: 1rem;
    }
}

.quickLinks {
    &.display1 {
        color: $darker-brown-1;
        font-size: 0.85rem;
        @include media-breakpoint-up(sm) {
            font-size: 1rem;
        }
        p {
            font-family: 'Gotham-light';
        }
        ul {
            font-family: 'Gotham-Book';
            .nav-link,a {
                color: $darker-brown-1;
            }
        }
    }
    svg{
        max-width: 1.95rem;
        max-height:2rem;
        vertical-align: middle;
    }
    .nav-item {
        margin: 0 0 0.9rem;
    }
    .nav-link {
        padding: 0;
        position: relative;
        padding-left: 0.60rem;
        vertical-align: middle;
        display: inline-block;
        @include transition(0.25s ease);
        &:not(span):hover,
        &:not(span):active,
        &:not(span):focus {
            color:$blue;
        }
        
        &[data-rel='mail'] {
            &:before {
                background-position: -75px -82px;
            }
        }
        &[data-rel='call'] {
            a{
                text-decoration:none;
                color: $darker-brown-1;
                &:hover{
                    color:$blue;
                }
            }
        }
    }
}
.block {
    .quickLinks {
        .nav-item {
            a{
                &:hover {
                    span{
                    color: $blue;
                    }
                }
            }
        }
    }
}
//List style default.
ul.points ,.customCol ul{
    list-style: none;
    padding-left: 50px;
    li {
        position: relative;
        margin-bottom: 1.5rem;
        &:before {
            content: url('../images/pointer.png');
            position: absolute;
            left: -50px;
        }
    }
}
ol.points,.customCol ol {
    list-style: none;
    padding-left: 0;
    list-style: none;
   counter-reset: item;
    li {
        position: relative;
        margin-bottom: 1.5rem;
        counter-increment: item;
        padding-left: 50px;
        position: relative;
        &:before {
            
            content: counter(item);
           border:1px solid $green;
            border-radius: 100%;
            color: white;
            width: 1.7rem;
            height: 1.7rem;
            text-align: center;
            display: inline-block;
            color: $green;
            line-height: 1.5rem;
            font-family: 'ScalaSans-Light';
            font-size: 0.875rem;
            position: absolute;
            left:0;
            top:0;

        }
    }
}

/* Social nav */
.socialWrap{
    span{
        color: #868685;
        font-size: 0.85rem;
        margin-right: 0.70rem;
    }
}
.socialNav {
    vertical-align: middle;
    display: inline-block;
    .nav-item {
        margin: 0 0.40rem 0 0;
        display: inline-block;
        &:last-child {
            margin-bottom: 0;
        }
    }
    .nav-link {
        // width: 28px;
        // height: 28px;
        width: 2rem;
        height: 1.7rem;
        text-indent: -9999px;
        background-color: $brown-150;
        display: inline-block;
        @include transition(0.3s ease-in-out);
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
        position: relative;
        &:before{
            content:"";
            width: 19px;
            height: 19px;
            position: absolute;
            top:50%;
            left:0;
            right:0;
            margin:0 auto;
            display: inline-block;
            @include transform(translatey(-50%));
            background-image: url('../images/sprite.png');
            background-position: -44px -47px;
        }
        &[data-rel='facebook'] {
            &:before{
                background-position: -44px -47px;
            }
            &:hover{
                background-color: #3b5998;
            }
        }
        &[data-rel='twitter'] {
            &:hover{
                background-color: #00aced;
            }
            &:before{
                background-position: -67px -47px;
            }
        }
        &[data-rel='linkedin'] {
            &:hover{
                background-color: #007bb6;
            }
            &:before{
                background-position: -92px -47px;
            }
        }
        &[data-rel='youtube'] {
            &:hover{
                background-color: #bb0000;
            }
            &:before{
                width: 12px;
                height: 14px;
                background-position: -114px -49px;
            }
        }
        &[data-rel='google-p']{
            &:hover{
                background-color: #dd4b39;
            }
            &:before{
                background-position: -128px -47px;
            }
        }
    }
    &.type-2{
        .nav-item{
            margin-right: 0;
        }
        .nav-link{
            &[data-rel='google-p']{
                background-color: #dd4b39;
            }
            &[data-rel='facebook']{
                background-color: #3b5998;
            }
        }
    }
}

/* Social nav */

/* Subscribe btn */

.subscribeBtn {
    background: $white-100;
    padding: 0.50rem;
    @include media-breakpoint-up(md) {
        padding: 0.70rem;
    }
    border-radius: 0.25rem;
    max-width: 555px;
    input {
        border: 0;
        background: transparent;
        font-size: 0.85rem;
        @include media-breakpoint-up(md) {
            font-size: 1.125rem;
        }
        &:hover,
        &:focus {
            background: transparent;
        }
    }
    .input-group-append {
        .btn {
            @include media-breakpoint-down(sm) {
                padding: 0.40rem 0.95rem;
            }
            border-radius: 0.25rem;
            text-transform: uppercase;
        }
    }
}

/* Subscribe btn */
.toggleCollapse {
    display: none;
    min-width: 44px;
    min-height: 12px;
}
.quickLinks {
    .nav-item{
    a {
        .nav-link {
            span {
                color: $blue;
            }
        }
     &:hover {
         text-decoration: none;
        
     }
    }
}
}
// Life Insurance
.quickLinks-new.quickLinks.display1 ul .nav-link, .quickLinks.display1.quickLinks-new ul a {
            padding-left: 0;
}
.internalPage .block.fourCards,.internalPage .block.fourCards:last-child {
    padding-top:7.125rem;
    margin-top:3.125rem;
}
.modal-backdrop{
    &.in  {
    background: transparent;
    }
    &.show {
        background: #000;
    }
}

@include media-breakpoint-down(lg) {
.floatingMenu {
 #collapsibleNavbar  {
     display: none!important;
     position:absolute;
     left:15px; 
     top:2.8rem;
     z-index: 10;
     background: $green;
     padding: 1.2rem;
     ul {
        flex-direction: column;
        li {
            display: block;
            margin: 0 0 0.7rem;
            text-align: left;
            a{
                color:$white;
                display: inline-block!important;
                &:hover {
                    opacity: 0.6;
                    color: $white!important;
                }
            }
        }
     }
     
    }
#collapsibleNavbar.show{display: block!important;width: 98%;}
.navbar-toggler {display: block!important;}
}
.navbar-toggler {
    border:1px solid $green;
}
.toggleCollapse {
    display: block;
    cursor: pointer;
    span {
        text-transform: uppercase;
        width: 25px;
        height: 2px;
        background: $green;
        display: block;
        margin-bottom: 4px;
         @include transform(rotate(0deg));
         @include transition(0.5s ease-in-out);
         
        &:nth-child(2){
         margin-left: 4px;
        }
    }
    &.active {
        span {
            &:first-child{
                @include transform(rotate(45deg));
            }
            &:nth-child(2){
                display: none;
            }
            &:nth-child(3){
                @include transform(rotate(-45deg) translate(4px, -4px));
            }
            
        }
    }
}
.internalPage .block.fourCards,.internalPage .block.fourCards:last-child {
    padding-top:2rem;
    margin-top:0rem;
}
}

@include media-breakpoint-down(xs) { 
    .floatingMenu.pageOptions  {
        li {
            margin-right: 1.5rem;
            &:last-of-type{margin-right: 0;}
            .nav-link{
            &:before{margin-right: 0.4rem;width: 33px;}
            }
        }
        #collapsibleNavbar.show {
            width: 93%;
        }
       
    }
    
    .toggleCollapse {
       span{
        font-size: 1rem;
        margin-right: 1.2rem;
       }
    }
    .quickLinks-new.display1 {
        font-size: 0.7rem;
    }
}

.homecarousel  {
    .owl-nav {
        .disabled {
            opacity: 0.5;
        }
    }
}


.showParent{
    display:block;
}
.hideParent {
    display: none;
} 
// .ui-widget.ui-widget-content {
//     z-index: 10000000!important;
// }
.ui-menu.ui-widget.ui-widget-content.ui-autocomplete.ui-front {
    z-index: 10000!important;
}
.pageOptions-list .list .nav-link:before, .pageOptions-list .list a:before {
    display: none;
}

.navbar-nav.floatingList.d-xl-flex{
    display: block!important;
    overflow: hidden;
    max-width: 52rem;
    .nav-item {
        
       display: block!important;
     
     margin-bottom: 0.5rem;
       float: left;
    }
    .nav-link {display: block!important}
    @include media-breakpoint-down(lg) { 
        .nav-item {         
            float: none;
         }
    }
}
.productPage {

    .floatingMenu {
padding-bottom: 0;
    }
.oddShade {
    & > .clearfix:nth-child(even) {
        .block {
            background-color: #fff!important;
       
        }
    }
    & > .clearfix:nth-child(odd) {
        .block {
            background-color: #f7f7f7!important;
        }
    }
}
}
.globalOverlay.menuOverlay {
    z-index:0;
} 



.block .headerWrap {
    @include media-breakpoint-down(md) { 
    //   padding:0!important;
    }
.lg-heading-wrap.calculator-heading {
margin-bottom: 3.2rem;
@include media-breakpoint-down(md) { 
margin: 0 0 1.8rem;
}
}
}
/*Rewrite the property in case of PDF- if class 'pdf-body' found*/
.pdf-body [data-aos^=fade][data-aos^=fade]{
    transition-property: none;
}
.pdf-body [data-aos=fade-up],
.pdf-body [data-aos=fade-right],
.pdf-body [data-aos=fade-left],
.pdf-body [data-aos=fade-bottom]  {
    transform: none;
}
/*Ends*/
.white-bckg{
    .cls-1{
        stroke: #fff;
    }
}
.form-message-thanks{
    p{
        padding: 1em 1em 0;
    }
}

    .block .breadCrub .heading5  {
        display: inline;
    }
