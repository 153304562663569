// Error CSS Start
.error-landing-page {
    text-align: center;
    &:before {
        position: absolute;
content: "";
width: 100%;
top: 4.4rem;
height: 10px;
z-index: 1000;
left:0;

@include box-shadow(0px 3px 5px rgba(0, 0, 0, 0.1));
    }
    padding-top:10rem!important;
    @include media-breakpoint-down(sm) {
        padding-top:7rem!important;
    }
    figure {
        text-align: center;
        margin-bottom: 2rem;
        img {
            max-width: 5rem;
            max-height: 5rem;
        }
    }
}

.text-success {
    font-size: 1rem!important;
}




// Error CSS End