.globalFooter {
    position: relative;
    .title {
        text-transform: uppercase;
        margin-bottom: 0.87rem;
        text-decoration: none;
        display: block;
        color: $white;
      
        @include media-breakpoint-up(md) {
            display: inline-block;
        }
    }
    strong.title {font-weight: normal}
   
    p{
        color: $offwhite;
    }
    .nav-link{
        display: inline;
        @include transition(0.30s ease);
        svg {
            @include transition(0.30s ease);
        }
    }
    .nav-link:not(.disabled) {
        color: $offwhite;
        &:hover, &:active, &:focus {
            color: $white;
        }
    }
    /* Footer Level-1 */
    .quickLinks {
        padding-top: 1.45rem;
        padding-bottom: 0.45rem;
        text-transform: uppercase;

        @include media-breakpoint-between(sm , md){
            .container{
                max-width: 100%;
            }
            text-align: center;
        }
        .navbar-nav {
            display: block;
            margin-bottom:0;
        }
        .nav-item {
            @include media-breakpoint-up(sm) {
                margin-right: 1.3rem;
                display: inline-block;
                width: auto;
            }
            margin-top:0;
            margin-bottom:1rem;
            &:last-child{
                margin-right: 0;
            }
            
        }
        .nav-link {
            color: $white;
            display: inline-block;
            padding:0;
            &:hover,&:active{
                opacity:1;
                color:$brown;
            }
            &:before {
                position: static;
                @include transform(translateY(0));
                top:-1px;
            }
            &[data-rel='mail'] {
                &:before {
                    background-position: -3px -11px;
                }
            }
            &[data-rel='call'] {
                &:before {
                    background-position: -36px -4px;
                }
            }
            &[data-rel='chat'] {
                &:before {
                    background-position: -74px -8px;
                }
            }
            &[data-rel='ask'] {
                &:before {
                    background-position: -120px -9px;
                }
            }
            &[data-rel='location'] {
                &:before {
                    background-position: -152px -7px;
                }
            }
        }
    }
    /* Footer Level - 1 */
    /* Footer leve - 2 */
    .footer2 {
        background: $brown;
        padding: 2.3rem 0 0rem;
        ul {
            padding: 0;
            margin-bottom: 0;
            @include media-breakpoint-up(md){
                margin-bottom: 1.75rem;
            }
        }
        .nav-item {
            display: block;
            margin-bottom: 0.74rem;
        }
        .nav-link {
            padding: 0;
        }
        @include media-breakpoint-down(md){
           min-height: 1px!important;
           padding-bottom: 0;
        }
    }
    /* Footer level - 3 */
    .footer3 {
        padding: 1.2rem 0;
        min-height: 4rem;
        @include media-breakpoint-down(md){text-align: center;
            padding: 2rem 0;
            ul{
                display: inline-block;
                li{
                    display: inline-block;
                }
            }
        }
        .nav-item{margin-bottom: 1.33rem;}
        .nav-link {
            padding: 0;
            margin-right: 20px;
            color:$lightoffwhite;
        }
        p,.navbar-brand{margin: 0;  color:$lightoffwhite;}
        .navbar-brand{margin-right: 1.2rem; text-transform: capitalize;}
    }
    /* Footer level - 3 */
    .socialNav{
        .nav-item{margin:0 0.40rem 0 0;
            &:last-child{
                margin-bottom: 0;
            }
        }
        .nav-link{
            margin-right:0;
        }
    }
    .navbar-brand{vertical-align: middle;}
    .footerQuickLink{
        background: $dark-brown ;
       .require-field{
      
       position: relative;
       &:before{
        content: "*";
        color: red;
        font-size: 0.7rem;
        width:0.7rem;
        height:0.7rem;
        position: absolute;
        left: 1.3rem;
       top: 0.4rem;
       }
       input{
        padding-left: 0.9rem;
       }
    }

        @include media-breakpoint-up(lg) {
            padding: 0;
        }
        .nod{
            position: static;
            margin-bottom: 0;
            @include media-breakpoint-up(lg) {
                position: absolute;
            }
        }
        .quickConnect {
            .form-group:first-child, .col-12:first-child + .form-group {
             
              z-index: 10000;
           
            }
        }
.card-body {
    .field-validation-error {
        position: absolute;
left: 1rem;
top: auto;
bottom: -0.9rem;
height: auto;
line-height: 1;
padding: 0;
    }
    .form-group {
        margin-bottom: 1rem;
        position: relative;
    }
}

    }
    .footerQuickLink{
        background: $blue;
        @include media-breakpoint-down(md){
            .nod-body{
                padding:0;
            }
        }
        @include media-breakpoint-up(lg) {
            background: transparent;
            // height: 0;
            position: absolute;
            right: 0;
            width:100%;
        }
    }
    .socialWrap{
        padding: 1.5rem 0 0.5rem;
        @include media-breakpoint-up(lg) {
            float:right;padding:0;
        }
    }
    .footerMenu{
        .footer-nav-heading {
            @include media-breakpoint-down(xs) {
             > .title {

                display: inline-block;
                padding-right: 2rem;
             }

            }
        > span{
           display: none;
           
            @include media-breakpoint-down(xs) {
                position: absolute;
                right:1.5rem; 
                top:0.2rem;
                display: block;
                
                padding-right: 25px;
                cursor: pointer;
                &:before{
                    content: "";
                    display: block;
                    width:20px;height: 20px;background-image: url('../images/sprite.png');
                    background-position: -9px -62px;
                    position: absolute;
                    right: 0;
                }   
                &.open{
                    &:before{
                        background-position: -9px -84px;
                    }
                }
            }
        }

        }
        ul{
            display: none;
            @include media-breakpoint-up(sm) {
                display: block!important;
            }
        }
    }
    .card-body{
        @include media-breakpoint-down(md) {
            padding:0;
        }
    }

    .quickLinks {
        .nav-item {
            a,.nav-link {
                svg{
                    max-width: 1.5rem!important;
                }
            }
        }
    }
   .card-body.row {margin: 0;}
}

.socialNav-svg {
    display: inline-block;
        vertical-align: middle;
    .nav-item {
        display: inline-block;
        vertical-align: middle;
        margin: 0 0.5rem 0 0!important;
       a {
           margin: 0!important;
           background: $gray-60;
           display: block;
           padding: 0;
           min-height: 1.8rem;
           min-width: 1.7rem;
           text-align: center;
        svg {
            max-width: 1rem;
            max-height: 1rem;
            fill:$white;
            margin: 0.4rem 0.4rem 0.1rem;
        }
        &:hover {
            opacity: 0.6;
        }
        &.fb-link:hover {
            background: $fb-color;
            opacity: 1;
         }
         &.twiter-link:hover {
          background: $twit-color;
          opacity: 1;
         }
         &.linkdin-link:hover {
          background:$li-color;
          opacity: 1;
         }
         &.youtube-link:hover {
          background: $yout-color;
          opacity: 1;
         }
       }
      
    }
}
@include media-breakpoint-down(md) {
    .globalFooter  .footerQuickLink .card-body{
        padding: 2rem 0 1rem;
    }
    .globalFooter .card.quickConnect {
        margin-bottom:0;
    }
}
@include media-breakpoint-down(sm) {
    .globalFooter {
        .footer3 {
            .nav {
                margin-bottom: 0.8rem;
            .nav-item {
                margin-bottom: 0.5rem;
                .nav-link {
                    margin:0 0.3rem;
                }
            }
        }
        }
    }
 
}
.footer-icon-col {
display: block;
background: $brown ;
padding-bottom: 45px;
a {
   display: block; 
}
@include media-breakpoint-down(md) {

 padding-bottom: 1.5rem;
}
ul {
    text-align: right;
    li {
        display: inline-block;
        padding:0 0 1rem  1rem;
        width: 19%;
        vertical-align: top;
        @include media-breakpoint-down(sm) {
            width: 48%;
        }
        a {
            img {
            max-width: 100%;
            height: auto
            }
        }
    }
}
}
.footerQuickLink {
    .subscribeBtn {
        margin-top:2.2rem;
        @include media-breakpoint-down(md) {
  margin:1rem auto;
        }
        .btn-primary, .btn-primary:focus {
            margin-top:0;
            background-color: #00a9e0;
            border-color: #00a9e0;
            color: #fff;
            border-radius: 0.25rem!important;
        }
        .btn-primary:hover , .btn-primary:active{
            background-color: #0085b1;
          border-color: #0085b1;
        }
        input[type="text"] {
            border:none;
            background: transparent;
        }
    }
    .justify-content-end > div {
        @include media-breakpoint-down(md) {
   width: 100%!important;
   max-width: 100%!important;
        }
    }
}
.footerQuickLink .justify-content-end > div{
        min-height: 0px;
    
}