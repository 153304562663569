.homecarousel{
    .crauselWrap{
        position: relative;
        @include media-breakpoint-up(md) {
            margin-bottom: 2rem;
        }
    }
    .owl-item{
        padding-left: 3px;
        @include media-breakpoint-up(sm) {
            figure{
                margin-bottom: 0;
            }
        }
    }
    .owl-nav{
        display: none;
    }
    .custom-nav{
        width:48px;
        height:48px;
        background-color: $green;
        opacity: 1;
        cursor: pointer;
        top: calc(50% - 24px);
        text-align: center;
        position: absolute;
        z-index: 2;
        span{
            background-image: url(../images/sprite.png);
            width: 11px;
            height: 11px;
            background-size: initial;opacity: 1;
            position: absolute;
            top: calc(50% - 5px);
            text-indent: -9999px;
            display: inline-block;
            left:calc(50% - 5px);
        }
        &.owl-prev{
            span{
                background-position: -6px -40px;
            }
            left:0
        }
        &.owl-next{
            span{
                background-position: -19px -40px;
            }
            right:0
        }
        
    }
}
.custom-nav.disabled{opacity: 0;cursor:context-menu;}
.disabled-custom-nav {
    & + .custom-nav , & + .custom-nav + .custom-nav{
        opacity: 0;
        cursor:context-menu;
    }
}
.homeBanner-carousal   {
    overflow: hidden;
    height: 100%;
    position: relative;
    .owl-item {
        float: left;
        height: 100%;
    }
    .owl-nav.disabled {
        display: none;
    }
    .owl-stage,.owl-stage-outer { height: 100%;}
    .owl-dots {
        position: absolute;
        bottom: 6rem;
        width: 100%;
        text-align: center;
        .owl-dot {
            display: inline-block;
            vertical-align: top;
            margin:0 0.4rem;
            span {
                width:0.875rem;
                height:0.875rem;
                display: block;
                background: #acb3b9;
                border-radius: 50%;
                cursor: pointer;
              
               
            }
            &.active {
                span {
                background: #1de1f7;
                }
            }
        }
    }
}
@include media-breakpoint-down(md) {
    .homeBanner-carousal .owl-dots {
        bottom:5rem;
    }
}
@include media-breakpoint-down(sm) {
.homecarousel .owl-item {
    padding-left: 0px;
}
.homeBanner-carousal .owl-dots {
    bottom:2.6rem;
}
}