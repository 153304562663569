
// Dev Update CSS Start


/*!
 * Bootstrap v4.0.0 (https://getbootstrap.com)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
//  .lang-default-img.icon {
//     margin-right: 0.5rem;
// }

// @media (min-width: 992px) {
//     header .navbar .container {
//         max-width: 1200px;
//     }
// }

// header .navbar .container .megaMenu.navbar-nav > .nav-item .nav-link {
//     padding: 1.20rem 0.60rem;
// }

// header .navbar .container .megaMenu {
//     font-size: 0.76rem;
// }

// .rateTab {
//     width: 22rem;
//     margin-bottom: 2rem;
// }

// @media (max-width: 575.98px) {
//     .rateTab {
//         width: 18rem;
//     }
// }

// .rateTab .rateTab-heading {
//     background: rgba(132, 189, 0, 0.9);
//     padding: 1rem;
// }


// @media (max-width: 575.98px) {
//     .rateTab-content {
//         padding: 0.7rem;
//     }
// }

// .rateTab-content strong {
//     font-size: 0.8rem;
// }

//     .rateTab-content strong.rate-value span {
//         font-size: 1.1rem;
//     }

// .rateTab-content table th:first-child {
//     text-align: right;
//     width: 50%;
//     padding-right: 1.5rem;
// }



.agent-block .agent-filter .form-group .stylish-select-left .stylish-select-right {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding-right: 1.8rem;
}
.hidden {
    display: none;
}


