.resetPassword-page {
    padding: 3rem 2.5rem 3rem;
    max-width: 28rem;
    margin:0 auto;
    width:100%;
            border:1px solid #f5f5f5;
            @include box-shadow(0px 0px 10px rgba(0,0,0,0.07));
            margin-bottom: 3rem;
            @include media-breakpoint-down(sm) {
           margin-top: 5rem;
           padding: 1rem 1.5rem 2rem;
           margin-bottom: 0;
            }
            .heading5 {
                margin-bottom: 2.5rem;
            }
            .backToLogin {
                display: inline-block;
                margin-top: 1.5rem;
            }  

            .text-success {
                font-size: 1.8rem;
            }
}