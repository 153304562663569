.card-table-list {
    overflow: auto;
    table {
        width: 100%;
        img {
            max-width:7.5rem;
            margin:0 auto;
            height:auto!important;
        }
        td,th {
            text-align: center;
            &:first-child {
                text-align: left;
            }
        }
    }
    .fa-check {
        &:before {
            font-size: 1rem;
        color:$green;
        }
    }
}