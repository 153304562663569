.search-form {
    display: flex;
    width: 100%;
    padding-bottom: 4.5rem;
    .searchTag, .btn {
       margin:0;
       height: 3rem;
    }
    .searchTag {
        width: 70%;
        border:1px solid $blue;
        padding: 0 1rem;
        border-radius: 5px 0 0px 5px;
    }
    .btn {
        width: 30%;
        border-radius: 0px 5px 5px 0px;
    }
    input::-ms-clear {
        display: none;
      }
}
.ui-menu {
    padding: 0.5rem 0;
    height: 200px;
    overflow-y:auto;
    overflow-x:hidden;
    .ui-menu-item-wrapper {
        padding: 0.5rem 1rem;
        font-size: 1rem;
    }
}
.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active,
a.ui-button:active,
.ui-button:active,
.ui-button.ui-state-active:hover {
	border: 1px solid $green;
	background:  $green;
	font-weight: normal;
	color:$white;
}
.result-heading {
    padding-bottom: 1.5rem;
    p {
        font-family: $font-family-base;
        span {
         font-weight: bold;
        }
        strong {
            color: $purple;
            font-weight: normal
        }
    }
}

.result-list {
    border-top: 1px solid $gray-light;
 &>div {
     border-bottom: 1px solid $gray-light;
     padding: 1.5rem 0;
     display: flex;
     width: 100%;
     &:last-child {border-bottom: none;}
     figure {
         width: 11rem;
         min-width: 11rem;
         height: 6rem;
         margin-bottom: 0;
         overflow: hidden;
         padding-right: 2rem;
         img {
             width: 100%;
             min-height: 100%;
             height: auto;
             @include transition(all 0.1s ease-in-out);
             &:hover {
                @include transform(scale(1.1));
             }
         }
     }
     &>div {
        
         p {
             margin-bottom: 0;
            
         }
          a {
              display: inline-block;color: $darker-brown;
            &:hover {
             text-decoration: none;
             color: $blue;
            
            }
            }
     }
     .list_date {
         display: block;
         font-family: $font-family-base;
         color: $gray-320;
         font-size: 0.8rem;
         padding-bottom: 0.4rem;
         line-height: 1;

     }
 }
}
.internalPage .block-contact.search-block:last-child{padding-top: 4rem;}
.search-block {
    .loader-wrap {padding-top:1rem;}
}

@include media-breakpoint-down(md) { 
    .search-form {padding-bottom: 2.5rem;}
    .internalPage .block-contact.search-block:last-child {
        padding-top: 2.5rem;
    }
}
@include media-breakpoint-down(sm) { 
    .search-form {
        padding:0 0 1.4rem;
        .searchTag,.btn {
            height: 2.5rem;
        }
        .searchTag {
            width: 80%;
        }
        .btn {
            background:$blue url(../images/search-icon.png) center center no-repeat;
            text-indent: -9999999rem;
            width: 20%;
            &:hover {
                background-color: $blue-100;
            }
        }
    }
    .result-heading {
       padding-bottom: 0.8rem;
    }
    .result-list {
        &>div {
            display: block;
            figure {
                min-width: 1rem;
                width: 100%;
                height: auto;
                padding:0;
                margin-bottom: 1rem;
                img {
                    height:auto;
                    min-width: 1px;
                }
            }
        }
    }
    .internalPage .block-contact.search-block:last-child {
        padding-bottom: 4rem;
    }
    .ui-menu .ui-menu-item-wrapper {
        padding: 0.4rem 1rem;
        font-size: 0.8rem;
    }
}
