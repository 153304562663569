.banner h2.lg,.banner h1.lg{
    span {
        display: block;
    }
}
.homebannerWrap {
    position: relative;
    .fixed-50 {
        .listing {
            max-width: 12.1rem; // @media (min-width: 1600px) {
            //     max-width: 18rem;
            // }
        }
    }
    .fixed-100 {
        .listing {
            max-width: 25.4rem; // @media (min-width: 1600px) {
            //     max-width: 39.38rem;
            // }
        }
    }

    @include media-breakpoint-up(lg) {
        height: 100vh;
    }
    @include media-breakpoint-down(md) {
        min-height: 650px;
    }
    @include media-breakpoint-down(sm) {
        min-height: 480px;
        overflow: hidden;
    }
    .quickLinkWrap {
        position: relative;
        width: 100%;
        top: -2.375rem;
        z-index: 1;
        @include media-breakpoint-up(md) {
            top: auto;
            bottom: 1.375rem;
        }
        @include media-breakpoint-up(sm) {
            bottom: 4.6rem;
        }
        @include media-breakpoint-up(lg) {
            position: absolute;
            bottom: -3.0625rem;
        }
    }
    @include media-breakpoint-down(md) {
        background-color: $white-100;
        overflow: visible;
    }
    .searchBox {
        h3,h1 {
            margin-bottom: 1rem;
            font-family: 'Gotham-light';
            line-height: 1.34;
        }
        input {
            padding: 0.30rem 0.550rem;
            opacity: 1!important;
        }
        .input-group-append {
            .btn {
                padding: 0.20rem 1.2rem;
            }
        }
        @include media-breakpoint-up(md) {
            h3,h1 {
                margin-bottom: 0;
                font-size: 2rem;
            }
            input {
                padding: 0.69rem;
            }
            .input-group-append {
                .btn {
                    padding: 0.50rem 2.8rem;
                }
                a.btn {
                    line-height: 2;
                    display: block;
                }
            }
        }
        @include media-breakpoint-up(xl) {
            h3,h1 {
                font-size: 4rem;
            }
        }
    }
    /* Card */
    .card {
        .form-control,
        .form-group .stylish-select-left .stylish-select-right {
            padding: 0.25em 0.45rem;
        }
        .form-group {
            &.design {
                padding-right: 0.4rem;
            }
            .main {
                min-height: auto;
            }
            ul.listing li {
                font-family: 'ScalaSans-Light';
                padding: 0.2rem;
                padding: 0.6rem 0.45rem;
            }
        }
        .stylish-select {
            height: 1.90rem;
        }
        @include media-breakpoint-up(lg) {
            max-width: 20.5rem;
            &.type-2 {
                max-width: 27rem;
            }
        }
        @include media-breakpoint-down(xl) {
            .card-title {
                margin-bottom: 0.6rem;
                font-size: 0.90rem;
            }
            p {
                font-size: 0.80rem;
                line-height: 1.4;
                margin-bottom: 1rem;
            }
            .form-group {
                margin-bottom: 0.50rem;
                &:last-child {
                    margin: 0;
                }
            }
            &.type-2 {
                .form-group {
                    margin-bottom: 0.80rem;
                }
            }
            .btn {
                margin-top: 1rem;
            }
            .card-body {
                padding: 2rem;
            }
        }
    }
    /* Card */

    .brosherBtn{
        color:$white;
        text-decoration: none;
        position: relative;
        display: block;
        // padding-left: 3.8rem;
        overflow: hidden;
        // &:before{
        //     content: url(../images/download-img.png);
        //     display: inline-block;
        //     position: absolute;
        //     left: 0;
        // }
        figure {
            float:left;
            padding-right: 1rem;
            width: 3.8125rem;
            img{
                width: 2.8125rem;
                height: 3rem;
            }
            svg {
                width: 2.8125rem;
                height: 3rem;
            }
            
        }
        span{
            display: block;
            font-family: 'ScalaSans-Light';
            font-size: 1rem;
            margin-top: 0.40rem;
        }
    }
.quickLinkWrap .gBox span.wrap svg.make-a-claim .cls-1 {
        fill: #fff!important;
        stroke-width: 1px;
       }
}

.homeBanner {
    position: relative;
    background-position: 0 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url('../images/home-banner.jpg');
    height: 100%;
    min-height: 480px;
    background-position: right top;
    font-family: "Gotham-Light";
    color: $white;
    font-size: 1rem;
  
    @include media-breakpoint-between(md,
    md) {
        min-height: 650px;
    }
    .btnWrap {
        margin-top: 1.5rem;
        display: inline-block;
        @include media-breakpoint-down(sm) {
            .btn {
                width: 100%;
            }
            text-align: center;
            span {
                margin-top: 0.50rem;
                margin-bottom: 0.50rem;
                display: block;
            }
        } // @media (min-width: 1600px) {
        //     margin-top: 2.5rem;
        // }
        a {
            color: $white;
            text-decoration: none;
        }
        @include media-breakpoint-down(xs) {
            text-align: center;
            .btn {
                width: 100%;
            }
        }
        .ml-4 {
            @include media-breakpoint-down(xs) {
                display: block;
                margin: 0.5rem 0 !important;
                padding: 0;
            }
        }
    }
    .type-2 {
        &.quickConnect {
            background: transparent;
            .card-body {
                background: $white;
                padding: 1.5rem;
            }
        }
        &.card{
            .card-title{
                font-family: 'Gotham-Light';
                font-size:1.2rem;
                color:$blue;
            }
        }          
        .quickLinkWrap {
            position: static;
            .innerWrap {
                flex-wrap: nowrap;
            }
            .icon{
                margin: 0 0 0.45rem;
            }
            .gBox {
                width:33.344%;
                h4 {
                    font-family: 'ScalaSans-Light';
                    font-size: 0.75rem;
                    margin: 0;
                }
                height: auto;
                .wrap {
                    text-align: center;
                    top: 0;
                    @include transform(translatey(0));
                }
            }
        }
        .btnWrap {
            .btn {
                background-color: $brown-120;
                border-color: $brown-120;
                &:hover,
                &:active,
                .active,
                &:focus {
                    color: $brown-120;
                    background: $white;
                }
            }
            a {
                color: $brown-120;
            }
        }
    }
    .display-4 {
        line-height: 0.9;
        margin-bottom: 0.5rem;
        font-size: 1.5rem;
        @include media-breakpoint-up(sm) {
            margin-bottom: 0.8rem;
        }
        @include media-breakpoint-up(md) {
            font-size: 2rem;
        }
        @include media-breakpoint-up(lg) {
            margin-bottom: 0.4rem;
        } // @media (min-width:1600px) {
        //     margin-bottom: 1rem;
        //     font-size: 3.313rem;
        // }
    }
    .innerWrap {
        padding-top: 8rem;
        @include media-breakpoint-up(md) {
            padding-top: 3rem;
            &.productBanner {
                padding-top: 6rem;
            }
        }
        padding-bottom: 7rem;
        .bannerChart {
            position: absolute;
            top: 50%;
            left: auto;
            right: 15px;
            @include transform(translateY(-37%));
            @include media-breakpoint-down(md) {
              position: static;
              top:auto;
              right:auto;
              @include transform(translateY(0%));
              padding-top:2rem;

            }
       
           
            h2 {
                font-size: 2rem;
                max-width: 100%;
                font-family: 'Gotham-Book';
                span {
                    display: block;
                }
            }
        }
    }
    .lgDisplay {
        .main {
            margin-bottom: 0.875rem;
            min-height: 2.625rem;
            @include media-breakpoint-up(md) {
                height: 3.0625rem;
                margin-bottom: 1.7rem;
            }
            @include media-breakpoint-up(md) {
                margin-bottom: 1rem;
            } // @media (min-width:1600px) {
            //     height: 4.35rem;
            //     margin-bottom: 1.6rem;
            // }
        }
        .stylish-select {
            height: 2.625rem;
            width: 25.4rem;
            min-width: 25.4rem;
            ul.listing {
                top: 2.5rem;
            }
            @include media-breakpoint-up(md) {
                height: 3.0625rem;
                ul.listing {
                    top: 3rem;
                }
            } // @media (min-width:1600px) {
            //     height: 4.35rem;
            //     margin-bottom: 1.4rem;
            //     ul.listing {
            //         top: 4.35rem;
            //     }
            // }
        }
        .btn {
            padding: 0.70rem 1.3rem;
            font-size: 0.85rem;
            &:hover,
            &.active,
            &:active {
                background-color: transparent;
                border-color: $white;
                color: $blue;
            }
        }
        .stylish-select-left {
            width: 100%;
            font-family: 'Gotham-Book';
            &:after {
                border-color: $green-100;
                top: 53%;
            }
            .stylish-select-right {
                padding: 0.5rem 1.875rem 0.5rem 0;
                border-color: $white;
                color: $white;
                line-height: 1;
                font-weight: 300;
                font-family: 'Gotham-Book';
                color: $green-100;
                font-size: 1.5rem;
                overflow: hidden;
                text-overflow: unset;
                white-space: nowrap;
                display: inline-block;
                min-width: 92%;
                max-width: 92%;
                border:none;
                @include media-breakpoint-up(sm) {
                    padding: 0.5rem 3.4375rem 0.5rem 0;
                }
                @include media-breakpoint-up(md) {
                    font-size: 2rem;
                }
            }
        }
    }
    h3.light,div.light,h1.light{
        @include media-breakpoint-up(md) {
            font-size: 3rem;
        }
    }
}

//Bottom Quick link boxes...
.quickLinkWrap {
    color: $white;
    h5 {
        text-transform: uppercase;
        margin-bottom: 5px;
        font-size: 0.75rem;
    }
    h4 {
        font-family: "ScalaSans-Bold";
        font-size: 0.875rem;
        text-transform: uppercase;
        margin-bottom: 0.3125rem;
        line-height: 1;
    }
    p {
        font-size: 0.875rem;
        margin-bottom: 0;
        line-height: 1.4;
    }
    .innerWrap {
        display: flex;
        flex-wrap: wrap;
        padding: 0;
        @include media-breakpoint-down(sm) {
            margin-left: -15px;
            margin-right: -15px;
        }
    }
    .icon {
        margin-right: 10px;
        vertical-align: middle;
    }
    .d-inline-block {
        vertical-align: middle;
        padding-right: 3rem;
        @include media-breakpoint-down(lg) {
            padding-right:0rem;
        }
    }
    .gBox {
        width: 50%;
        height: 10rem;
        background-color: $green-100;
        color: $white;
        text-decoration: none;
        &:first-child,
        &:last-child {
            background-color: $green;
        }
        span.wrap {
            min-height: 2.4rem;
            svg {
                width:2.3rem;
                height: 2.2rem;
                display: inline-block;
                vertical-align: middle;
                margin-right: 0.4rem;
                .cls-1 {
                    stroke: $white;
                    fill:none!important;
                }
            }
        }
         @include transition(all 0.70s ease);
        &:hover,
        &:active,
        &:focus {
            background: #70a000;
        }
        @include media-breakpoint-up(md) {
            height: 7.375rem;
            width: 25%;
            background-color: $green;
            &:nth-child(even) {
                background-color: $green-100;
                &:hover,
                &:active,
                &:focus {
                    background: #77a50e;
                }
            }
        }
        display: inline-block;
        padding: 0.7rem;
        text-align:center;
        overflow: hidden;
        .wrap {
            display: inline-block;
            text-align: left;
            position: relative;
            top: 50%;
            @include transform(translatey(-50%));
            @include media-breakpoint-down(lg) {
                text-align: center;
                .icon {
                    margin-bottom: 1rem;
                    margin-right: 0;
                }
                .d-inline-block {
                    display: block !important;
                }
            }
        }
    }
}

.overlay {
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
}
.homebannerWrap .fixed-50 .listing,.homebannerWrap .fixed-100 .listing {
    max-width: 100%;
    width: 100%;
}
.quickLinkWrap {
.bannerloginBox {
    position: relative;
    overflow: visible;
    // padding-left: 2rem;
    text-align: left;
    // cursor: pointer;
    // z-index: 10;
    &:after {
        border-bottom: 0.125rem solid $white;
        border-right: 0.125rem solid $white;
        content: "";
        display: block;
        height: 0.625rem;
        margin-top: -0.25rem;
        pointer-events: none;
        position: absolute;
        right: 2rem;
        top: 50%;
        @include transform(rotate(45deg));
        @include transform-origin(66% 66% 0);
        @include transition(all 0.15s ease-in-out 0s);
        width: 0.625rem;
    }
    &.openDropdown {
        &:after {
            @include transform(rotate(228deg));
        }
    }
    .login-dropdown {
        display: none;
        position: absolute;
        bottom: 7.4rem;
        margin-bottom: 0;
        left:0;
        width: 100%;
        background: $white;
        list-style: none;
        padding:0;
        @include box-shadow(0px 0px 4px rgba(0,0,0,0.2));
        z-index: 1000;
        li {
            outline: none;
            border-bottom: solid #f1f1f1 0.0625rem;
            list-style: none;
           a,span {
            color: #333333;
            line-height: 1.125rem;
            display: block;
            text-align: left;
            font-size: 1.125rem;
            @include transition(all 0.3s ease-in);
            padding: 1rem 1.25rem 1rem 1.25rem;
            &:hover {
                background: $green;
                color: $white;
                text-decoration: none;
            }
            }
        }
    }
}
}
@media all and (min-width:1500px) {
.homebannerWrap-2 {
    .card{
        max-width: 100%;
        .card-body {
            padding: 2rem;
        }
        .card-title {
            font-size: 1rem;
        }
        p {
            font-size: 1rem;
        }
        .form-group .stylish-select-left .stylish-select-right,.form-group .form-control,.stylish-select ,.form-group .main{
            height: auto!important;
            min-height: 2.375rem!important;
        }
        .form-group .stylish-select-left .stylish-select-right {
            padding: 0.47rem 0.45rem;
        }
     }
     .align-self-lg-center {padding-left: 3rem;}
    }
    .homebannerWrap .card .form-group {

        margin-bottom: 0.80rem;
    
    }
}
.alertMassage {
    color: red;
}
.dropdown-menu {
    .alertMassage {
        font-size: 0.875rem;
    }  
}
@include media-breakpoint-down(lg) {
.DetailBanner  {
    min-height: 650px;
}
.quickLinkWrap .gBox span.wrap svg {margin:0 auto 0.5rem;display:block;}
.quickLinkWrap {
    .bannerloginBox {
        text-align: center;
        padding-left: 0;
       &:after {
           right: auto;
           left: 50%;
           top:auto;
           bottom:0.5rem;
           margin-left: -0.6rem;
           
       }
     
    }
}
}
@include media-breakpoint-down(md) {
    .homeBanner{
        .type-2 {
            .btnWrap {
                .btn {display: block;margin: 0 0 0.7rem;}
            }
        }
    }
    .homeBanner  {
        h3.light, div.light,h1.light {
        font-size: 2rem;
    }
}
.quickLinkWrap .bannerloginBox .login-dropdown li a {
    font-size: 0.8rem;
}
}
@include media-breakpoint-down(sm) {
    .homeBanner {
        .type-2 {
            .quickLinkWrap {
                margin: 0 15px;
                width: auto;
            }
        }
        h3.light, div.light,h1.light {
            font-size: 1.7rem;
        }
        .lgDisplay {
            .stylish-select {
                width: 100%;
                min-width:100%;
            }
        }
      
    }
    .homebannerWrap .searchBox .input-group-append a.btn {padding: .7rem 1.3rem;}
    .homebannerWrap .fixed-50 .listing,.homebannerWrap .fixed-100 .listing {
        max-width: 100%;
        width: 100%;
    }
    .quickLinkWrap .bannerloginBox .login-dropdown {top:10rem;bottom:auto;max-height: 9rem;
        overflow: auto;}
}
 .rateTab {
     max-width:20rem;
     width: auto;
     margin-bottom: 2rem;
     @include media-breakpoint-down(xs) {
        max-width: 18rem;
     }
     .rateTab-heading {
         background: rgba(132,189,0,0.9);
         padding:   1rem;
         @include media-breakpoint-down(xs) {
          padding:0.7rem;
         }
         strong {
             font-size: 0.8rem;
             display: block;
             margin-bottom: 0.4rem;
         }
         ul {
             padding-left:0;
             margin-bottom: 0;
             li {
                 list-style: none;
                 display: inline-block;
                 vertical-align: top;
                 font-size: 0.8rem;
                background: rgba(255,255,255,0.9);
                //background: $blue;
                 color: $grey-300;
                //color: $white;
                 padding:0.3rem 0.4rem;
                 cursor: pointer;
                 border-radius: 3px;
                 &:hover {
                     @include box-shadow(0 3px 3px rgba(0,0,0,0.1) inset)
                 }
                 @include media-breakpoint-down(xs) {
                    padding:0.3rem 0.3rem;
                    font-size: 0.7rem;
                   }

            }
         }
     }

 }
 .rateTab-content {
    background: rgba(157,202,51,0.7);
    padding:1rem;
    @include media-breakpoint-down(xs) {
        padding:0.7rem;
       }
    strong{
        // font-size: 0.8rem;
        font-size: 1rem;
        margin-bottom: 0.4rem;
        display: block;
        color: $white;
        &.rate-value {
            color: $white;
            span {
                font-size: 1.1rem;
            }
        }
    }
    table th, table td {
        border-top:none;
        padding:0.2rem 0.4rem 0.4rem 0;
        font-size: 0.8rem;
    }
    table th {
        font-size:1rem;
        font-family: "ScalaSans-Bold";
        @include media-breakpoint-down(xs) {
            font-size:0.9rem;
        }
        &:first-child {
            // text-align:right;
            width:50%;
            padding-right: 1.5rem;
        }
    }
    table td:first-child {
        width:50%;
        padding-right: 1.5rem;
    }
}

.rate-tab {
    display: none;
    &.active-tab {
        display: block;
    }
}

.homebannerWrap{
    .container {
        position: relative;
    }
}

@include media-breakpoint-down(lg){
    header .d-flex.align-items-center {
           padding-right: 15px;
    }
}

.homeBanner {
    .innerWrap {
        .main {
        .listing {
            max-height: 10rem;
            overflow-y: auto;
            
        }
        .mCSB_inside > .mCSB_container {
            margin-right: 0px;
        }
        .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCSB_scrollTools .mCSB_dragger .mCSB_dragger, .mCSB_scrollTools .mCSB_dragger .mCSB_draggerRail {
            width: 7px;
        }
        .mCSB_scrollTools {
            width: 7px;
        }
    }
    }
}


.dropdown.userloginBox.active .dropdown-menu, .dropdown.userloginBox.active .listing {
    visibility: visible !important; 

}
header .userloginBox .dropdown-wrap .header{
    overflow-x: auto;
}

@include media-breakpoint-up(xl){
.quickLinkWrap .gBox span.wrap {
    display: table;
    width: 100%;
   
    //overflow: hidden;
&>.svg-img {
// float:left;
// display: block;
// max-width: 25%;
margin-right: 0;
display: table-cell;
vertical-align: middle;

}
&>.d-inline-block {
    // display: block!important;
    width: 80%;
    // float: left;
    display: table-cell!important;
    vertical-align: middle;
    padding-right: 1rem;
    padding-left: 0.6rem;
    h4 {
        // word-wrap: break-word;
        margin-bottom: 0;
        padding: 0rem 0;
    }
   
}
}

.quickLinkWrap .gBox.bannerloginBox span.wrap {
    padding-right: 2.2rem;
}
}

.homeBanner {
    .btn{
        @include media-breakpoint-down(lg){
          max-width: 20rem;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        @include media-breakpoint-down(sm){
            max-width: 17rem;
        }
    }

    .bannerContent-row {
        &,.col-lg-5,.container,.row,.col-12,.col-lg-4{
        position: static;
        }
    }
}

.quickConnect.blueBg:empty {
    display: none;
 }

 .homeBanner {
     .innerWrap.table {
         position: relative;
     }
 }

.homeBanner .lgDisplay .stylish-select-left {
    overflow-x: hidden;
    border-bottom: 1px solid $white;
}