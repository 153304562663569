.agent-detail {
    display: table;
    width: 100%;
    margin-bottom: 2.8rem;
    padding-right: 10%;
.agent-profile {
    width: 55%;
    display: table-cell;
    vertical-align: middle;
    padding-right: 18%;
    figure {
        width: 100%;
        margin-bottom: 1.4rem;
        img {
            width: 100%;
            height: auto!important;
        }
    }
    .socialWrap {
        text-align: center;
        .socialNav-svg{
            .nav-item{
                a {
                    background: $blue;
                    border-radius: 50%;
                    width: 3.125rem;
                    height: 3.125rem;
                   svg {
                       max-width: 1.3rem;
                       height: 1.3rem;
                       max-height:1.3rem;
                       margin-top: 0.9rem;
                      
                   }
                   &:hover {
                       background:$gray-60;
                   }
                }
            }
        }
    }
}
.agent-address-detail {
    display: table-cell;
    width:45%;
    vertical-align: middle;
    h3 {
        font-size: 1.875rem;
        color: $blue;
        font-family: 'Gotham-medium';
        margin-bottom: 1rem;
    }
  
    address  {
        margin-bottom: 2rem;
        a, p {
            font-family: 'Gotham-book';
            font-size: 1rem;
            color: $brown-200;
        }
        a {
            &:hover {text-decoration: none; color: $green-400;}
        }
        p {
            margin-bottom:0rem;
            line-height: 1.7;
            span {
                display: block;
            }
        }
       
    }
}
}
.agent-description {
    padding-right: 10%;
    h2 {
        color: $brown-200;
        font-family: 'Gotham-medium';
        font-size:1.625rem;
     

    }
    p li{
        font-family: 'Gotham-book';
            font-size: 1rem;
            color: $brown-200;
    }
    ul {
        padding:0;
        li {
            background: url(../images/agent/list-arrow.png) left 0.3rem no-repeat;
            list-style: none;
            padding-left: 1.875rem;
            font-size: 1rem;
            margin-bottom: 0.8rem;
        }
    }
}
.agent-block {
    .card {
label {
    display: block !important;
    &.require-label {
        &:after{
        position: absolute;
        color: red;
        content: "*";
        width: 10px;
        height: 10px;
        padding-left: 5px;
        }
    }
    &.hidden {
        display: none!important;
    }
}
.card-title {
    font-size: 2.1875rem;
    font-family: 'Gotham-light';
    color: $blue-300;
    text-transform: none;

}
p {
    line-height: 1.3;
    margin-bottom: 2rem;
}
.form-control,label,select,.stylish-select-right,.listing li,textarea {
    font-family: 'Gotham-light'!important;
    color: $textColor!important;
    font-size: 1rem!important;
    opacity: 1;
}
textarea {
    width: 100%;
    border:none;
}
.form-control {
    padding-bottom: 0.8rem
}
.stylish-select-right {
    padding-top: 0.7rem;
    padding-bottom: 0.5rem
}
.form-group {
    margin-bottom: 1.1rem;
}
.btn-green-full {
    width:100%;
    display: block;
    background: $green-400;
    border:1px solid $green-400;
    font-family: 'Gotham-medium';
    font-size: 1rem;
    padding:1.3rem 0.7rem;
    margin-top:1.8rem;
    &:hover {
         background: $white;
         color: $green-400;
    }

}
 }
    .quickConnect  {
        background: $white;
        @include box-shadow(0px 10px 30px rgba(0, 0, 0, 0.1));
    }
  .headerWrap {
      padding-top:3.2rem;
      padding-bottom: 1.4rem;
      h2.lg {
          span {display: block;}
      }
  }
}
.mapWrap {
    height:25.0625rem;
    margin-top:3.6rem;
    .map-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
}
.map-container:before, .map-container:after, .map:before, .map:after {
    position: absolute;
    display: block;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
    content: '';
    z-index: 5;
}
.map-container:before {
    top: -5px;
    left: 0;
    right: 0;
    height: 5px;
}
.map-container:after {
    right: -5px;
    top: 0;
    bottom: 0;
    width: 5px;
}
.map:before{
    bottom: -5px;
    left: 0;
    right: 0;
    height: 5px;
}
.map:after{
    left: -5px;
    top: 0;
    bottom: 0;
    width: 5px;
}
#map {
    width: inherit;
    height: inherit;
}
} 
.agents-list 
	{
     padding-bottom:2.8125rem;
     padding-top:2rem;
     div[class*="col-"]{
         margin-bottom: 4.125rem;
         a {
             display: block;
             &:hover {
                 text-decoration: none;
             }
         }
     }
     cite { 
        color:$blue;
		display: block;
		text-align:center;
		font-family: "Gotham-Medium";
        font-size: 1.5rem;
        font-style: normal;
        small {
            font-family: "Gotham-Book";
            display: block;
            text-align:center;
            font-size: 1rem;
            color: $brown-200;
            padding-top: 0.4rem;
          }
      }
      .imageWrap {
        position: relative;
        @include transition(0.25s ease-in-out);
        figure  {
            //height:16rem;
            overflow: hidden;
            position: relative;
            img {
             width:100%;
             height: auto;
            }
          }
          &:before {
            background-color: rgba(0,0,0,0.6);
            position:absolute;
            content: "";
            top:0;
            left: 0;
            width:100%;
            height:100%;
            padding:0;
            z-index:1;
            opacity: 0;
            @include transition(0.25s ease-in-out);
            } 
            &:after {
                content: "+";
                position:absolute;
                top:50%;
                left:0;
                width: 100%;
                text-align: center;
                color:$white;
                font-size: 6.25rem;
                font-family: "Gotham-Thin";
                z-index:11;
                @include transform(translateY(-50%));
                display: none;
                opacity: 0;

            }  
            &:hover:before {
                width:100%;
                opacity: 1;
            }  
            &:hover:after {
                display: block;
                opacity:1;
            }   
     }
 }
.agent-filter {
 padding-bottom: 3.2rem;
    .form-group,.btnWrap {
        float: none;
        vertical-align: middle;
        width: 21%;
        margin:0;
        display: inline-block;

    }
    .btnWrap {
        width:12%;
        padding-left:0.5%;
        .btn {
        margin:0;
        width:100%;
        padding: 1.44rem 0.5rem;
        font-size: 1rem;
        white-space: normal;
        &:disabled {
            background: #b7b8b7;
            border-color: #b7b8b7;
            cursor: not-allowed;
        }
        }
    }
    .form-group {
        padding:0 0.5%;
        &:first-child {
            padding-left: 0;
        }
        select, input ,.styledSelect{
            color: $darker-brown-1; 
            font-family: "Gotham-light";
            background: $white-100 ;
            border:none;
            padding: 1.4rem 1.5rem;
        }
        .stylish-select  {
            height: 4.1rem;
        }
        ::-webkit-input-placeholder {
           opacity: 1;
           color: $darker-brown-1;
          }
          ::-moz-placeholder {
            opacity: 1;
            color: $darker-brown-1;
          }
          :-ms-input-placeholder { 
            opacity: 1;
            color: $darker-brown-1;
          }
          :-moz-placeholder {
            opacity: 1;
            color: $darker-brown-1;
          }
         
              .stylish-select-left:after {
                  border-color: $darker-brown-1;
                  right: 1.5rem;
              }
       
    }
}
.agentListMap {

iframe {
    height: 34.375rem;
}
figure {
    img {
        width:100%;
        height: auto;
    }
}
}
@include media-breakpoint-down(lg) {
.agent-detail {
    .agent-profile {
        padding-right:7%;
        margin:0 0 2rem;
        max-width: 15rem;
        vertical-align: top;
        width: 46%;
        vertical-align: top;
    }
    .agent-address-detail {
        vertical-align: top;
        width: 54%;
    } 
}
}
@include media-breakpoint-down(lg) {
    .agent-description,.agent-detail {padding-right: 0;}
    .agent-block {
        .quickConnect {
            margin-top: 2rem;
        }
    }
    .mapWrap {
        margin-top: 2rem;
    }
    .internalPage .agent-block ,.internalPage .agent-block:last-child {
        padding-bottom: 4rem;
       
    }
}
@include media-breakpoint-down(md) {
    .agent-filter .form-group, .agent-filter .btnWrap {
        float: none;
        width: 100%;
        padding: 0;
        margin-bottom: 0.8rem;
    }
    .agent-filter .form-group select, .agent-filter .form-group input, .agent-filter .form-group .styledSelect {padding:0.8rem;}
    .agent-filter .form-group .stylish-select {height: 3rem;}
    .agent-filter .btnWrap {
     text-align: center;
     padding-left: 0;
    .btn {
        margin: 0 auto;
        width: auto;
        padding: 1rem 1.5rem;
        font-size: 1rem;
    
    }
}
.agent-block .headerWrap {padding-top:1rem;}
.agent-filter {padding-bottom: 2rem;}
}
@include media-breakpoint-down(sm) {
    .agent-detail {
        display: block;
        margin-bottom: 2rem;
        .agent-profile,.agent-address-detail {display: block;width: 100%; padding:0;}
        .agent-profile {margin:0 auto 2rem;}
        .agent-address-detail  {
            h3 {
                font-size: 1.4rem;
                margin-bottom: 0.6rem;
            }
            address {
                margin-bottom: 1.4rem;
                p {
                    font-size: 0.875rem;
                }
            }
        }
    }
    .agent-description{
        h2 {
            font-size: 1.4rem;
        }
    }
    .agent-block{
        .card{
            .card-title {
                font-size: 1.8rem;
            }
            .btn-green-full {
                margin-top:1rem;
            }
        }
    }
    .mapWrap {
        height:16rem;
    }
    .agent-block .headerWrap h2.lg span.heading5 {display: none;}
    .agents-list div[class*="col-"] {margin-bottom: 2rem;}
    .agentListMap iframe {
        height:18rem;
    }
    .agents-list  {padding-top:0;}
}


.customSelect-disabled {
    &+.stylish-select {
        position: relative;
        &:after {
        position: absolute;
        left: 0;
        top:0;
        width: 100%;
        height: 100%;
        background: rgba(255,255,255,0.3);
        z-index: 100;
        content: "";
        cursor: not-allowed;
        }
    }
}

.agent-page-Loader {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.2);
    left:0;
    top:0;
    z-index: 10000000;
    img {
        position: absolute;
        top:50%;
        left:50%;
        @include transform(translate(-50%,-50%));
    }
}
.agentLanding {
    .filtersGroup {
        
        .btn-primary {
            margin: 0;
            padding: 1.2rem 0.5rem;
            text-transform: uppercase;
            font-size: 1rem;
            width: 100%;
            &[disabled="disabled"] {
               cursor: not-allowed;
               background-color: #6c757d;
                border-color: #6c757d;
                &:hover {
                background-color: #6c757d;
                border-color: #6c757d;
               }

            }
        }
        .form-group div[class*="col-"] {
           
                ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                    opacity: 1;
                    color: #363735;
                  }
                  ::-moz-placeholder { /* Firefox 19+ */
                    opacity: 1;
                    color: #363735;
                  }
                  :-ms-input-placeholder { /* IE 10+ */
                    opacity: 1;
                    color: #363735;
                  }
                  :-moz-placeholder { /* Firefox 18- */
                    opacity: 1;
                    color: #363735;
                  }
                 
                  @include media-breakpoint-down(md) {
                    margin-bottom: 1rem;
                   }
                
        }
        .form-group.col-lg-2{
            @include media-breakpoint-down(md) {
                margin-bottom: 2rem;
               }
        }
    }
}

.agentLanding .filtersGroup .form-group .stylish-select-left .stylish-select-right {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding-right: 1.8rem;
}

.agentDetailMap .card.quickConnect select.customSelect {
    border:none;
    border-bottom: 1px solid $grey-50;
}