/**
* List of mixins we are using.
*
1: @include transform($transforms)
1.1: @include transform-origin($transforms)

*****Transition set******
2: @include transition($transition...)
3: @include transition-property($property...)
4: @include transition-duration($duration...)
5: @include transition-timing-function($timing...)
6: @include transition-delay($delay...) 
7: @include prespective($property...)

*****Animation set******
7: @include animation($animation)
8: @include flexwrap()
9: @include flex()
*
**/

//Function transform
@mixin transform($transforms) {
    -moz-transform: $transforms;
    -o-transform: $transforms;
    -ms-transform: $transforms;
    -webkit-transform: $transforms;
    transform: $transforms;
}

@mixin transform-origin($transforms) {
    -webkit-transform-origin: $transforms;
    -moz-transform-origin: $transforms;
    -ms-transform-origin: $transforms;
    -o-transform-origin: $transforms;
    transform-origin: $transforms;
}


/* transition Mixin */

@mixin transition($transition...) {
    -moz-transition: $transition;
    -o-transition: $transition;
    -webkit-transition: $transition;
    transition: $transition;
}

@mixin transition-property($property...) {
    -moz-transition-property: $property;
    -o-transition-property: $property;
    -webkit-transition-property: $property;
    transition-property: $property;
}

@mixin transition-duration($duration...) {
    -moz-transition-property: $duration;
    -o-transition-property: $duration;
    -webkit-transition-property: $duration;
    transition-property: $duration;
}

@mixin transition-timing-function($timing...) {
    -moz-transition-timing-function: $timing;
    -o-transition-timing-function: $timing;
    -webkit-transition-timing-function: $timing;
    transition-timing-function: $timing;
}

@mixin transition-delay($delay...) {
    -moz-transition-delay: $delay;
    -o-transition-delay: $delay;
    -webkit-transition-delay: $delay;
    transition-delay: $delay;
}

@mixin perspective($prespective...) {
    -webkit-perspective: $prespective;
    perspective: $prespective;
}


/* Animation Mixin */

@mixin animation($animation...) {
    webkit-animation: $animation;
    -o-animation: $animation;
    -moz-animation: $animation;
    animation: $animation;
}


/* Display flexWrap */

@mixin flexwrap() {
    display: -webkit-flex;
    -webkit-flex-wrap: wrap;
    display: flex;
    flex-wrap: wrap;
}


/* Display flex */

@mixin flex() {
    display: -webkit-flex;
    display: flex;
}

// Box Shadow
@mixin box-shadow($value) {
    -webkit-box-shadow: $value;
    -ms-box-shadow: $value;
    -moz-box-shadow: $value;
    -o-box-shadow: $value;
    box-shadow: $value;
}

// Border radius

@mixin border-radius($radius) {
	border-radius: $radius;
	-webkit-border-radius: $radius;
	-moz-border-radius: $radius;
}
@mixin appearance($none) { 
    -webkit-appearance: $none;
    -moz-appearance: $none;
    -ms-appearance: $none;
    -o-appearance: $none;
    appearance: $none;

  }
@mixin placeholder {
    ::-webkit-input-placeholder {@content}
    :-moz-placeholder           {@content}
    ::-moz-placeholder          {@content}
    :-ms-input-placeholder      {@content}  
  }
  
