@charset "UTF-8";

// deps
@import "deps/bootstrap";

//core
@import "core/mixins";
@import "core/config";
@import "core/custom-select";
@import "core/icons";
@import "core/keyframes.scss";
@import "core/form-control";
@import "core/typography";

//vendors 

/* components */
@import "components/common/header";
@import "components/common/primaryMenu";
@import "components/common/language-dropdown";
@import "components/common/user-login";
@import "components/accordian";
@import "components/home-banner";
@import "components/news-block";
@import "components/home-slides";
@import "components/testimonial-block";
@import "components/description-block";
@import "components/product-block";
@import "components/cards-section";
@import "components/blogs-block";
@import "components/hive-tiles";
@import "components/common/footer";
@import "components/utility";
@import "components/investor-profile.scss";
@import "components/contact.scss";
@import "components/getAQuote.scss";
@import "components/promotion.scss";
@import "components/search.scss";
@import "components/calculator.scss";
@import "components/agent.scss";
@import "components/product-selection.scss";
@import "components/error.scss";
/* Home page */
@import "components/home-page/home-page";

/* Blog page */
@import "components/blog-page/blog-page";
@import "components/card-table.scss";

@import "components/dev-update.scss";
@import "components/our-story.scss";
@import "components/reset-password.scss";
