.accordian{
    h4{
        cursor: pointer;
        position: relative;
        padding: 0 0 0 2.2rem;
        margin-bottom: 1.4rem;
        display: inline-block;
        font-size: 1rem;
        @media (min-width:1024px){
            &:hover{
                color: $blue;    
            }
        }
        &:before{
            content: "";
            background-image: url(../images/sprite.png);
            width: 20px;
            height: 20px;
            display: inline-block;
            background-position: -9px -110px;
            vertical-align: middle;
            position: absolute;
            left: 0;
            top: calc(50% - 10px);
        }
    }
    li.active{
        h4{
            color: $blue;
            &:before{
                background-position: -9px -132px;
            }
        }  
    }
    .content{
        display: none;
        background: $white;
        padding: 0rem 1.50rem;
        @include media-breakpoint-up(xl) {
            padding: 1.4rem 2.2rem;
        }
    }
    &.faqs{
        .content{
            position: relative;
            margin-bottom:1.4rem;
            box-shadow: 0px 16px 20px rgba(0, 0, 0, 0.08);
            b{
               // font-family: 'Gotham-bold';
                //font-size: 0.85rem;
            }
            &:before{
                content:"";
                width: 0;
                height: 0;
                border-bottom: 15px solid $white;
                border-left: 15px solid transparent;
                border-right: 15px solid transparent;
                position: absolute;
                top: -14px;
                left: 1.5rem;
            }
            p{
                a{
                    color:$brown-200;
                    text-decoration:none;
                    font-weight:700;
                    &:hover {
                        color: $blue;
                    }
                }
            }
        }
    }
}




