.stylish-select {
    height: 3.0625rem;
    position: relative;
    z-index: 1;
    cursor: pointer;
}

.stylish-select-left  {
    position: absolute;
    z-index: 1;
    width: 100%;
    outline: 0;
    box-sizing: border-box;
    cursor: pointer;
    border: none;
    font-size: 1.125rem;
    font-family: 'pf_encore_sans_proregular', sans-serif;
    color: #000;
    width: 100%;
    background: transparent;
    .stylish-select-right {
        text-align: left;
        text-overflow: ellipsis;
        overflow: hidden;
        border-bottom: 0.0625rem solid $dark-brown ;
        padding: 0.625rem 0;
    }
    &:after {
        border-bottom: 0.125rem solid $green;
        border-right: 0.125rem solid $green;
        content: "";
        display: block;
        height: 0.625rem;
        margin-top: -0.25rem;
        pointer-events: none;
        position: absolute;
        right: 0.625rem;
        top: 50%;
        @include transform(rotate(45deg));
        transform-origin: 66% 66% 0;
        -moz-transform-origin: 66% 66% 0;
        -webkit-transform-origin: 66% 66% 0;
        @include transition(all 0.15s ease-in-out 0s);
        width: 0.625rem;
    }
    &.open:after {
        @include transform(rotate(226deg));
    }
}

ul.listing {
    background:$white;
    box-shadow: 0 0 0.3125rem rgba(0, 0, 0, 0.2);
    display: none;
    left: 0;
    list-style-type: none;
    margin: 0;
    padding: 0 0 0 0;
    position: absolute;
    top: 2.75rem;
    width: 100%;
    z-index: 100;
    max-height:15.625rem; //  overflow-y:scroll;
    overflow-x: hidden;
    li {
        color: #333333;
        line-height: 1.125rem;
        text-align: left;
        outline: none;
        font-size: 1.125rem;
        border-bottom: solid #f1f1f1 0.0625rem;
        padding: 0.625rem 1.25rem 0.625rem 1.25rem;
        @include transition( all 0.3s ease-in);
        &:hover {
                color: $white;
                background: $green;
                cursor: pointer;
        }
        &.selected {
            color: $white;
            background: $green;
        }
    }
}

.main {
    margin: 0 0 0;
    position: relative;
    width: 100%;
    min-height: 3.375rem;
}

.stylish-select-left {
    .icon {
        border: solid;
        position: absolute;
        width: 3.375rem;
        left: 0.375rem;
        top: 0.5625rem;
        height: 1.5625rem;
        cursor: pointer;
    }
}

.searchname {
    padding: 1.25rem;
    input[type=text] {
        border: 0.0625rem solid $brown;
        padding-left: 0.9375rem;
        padding-right: 0.9375rem;
    }
}

.listingbox {
    background: #fff;
    display: none;
    margin: 0;
    padding: 0;
    position: relative;
    top: 3.125rem !important;
    z-index: 100;
    overflow: auto;
    width: 100%;
    cursor: pointer;
    box-shadow: 0 0 0.3125rem rgba(0, 0, 0, 0.2);
    overflow: auto;
    max-height: 15.625rem;
    .listing {
        display: block;
        height: auto;
        overflow-x: initial;
        max-height: 100%;
        position: static;
    }
    .errorMsg {
        text-align: left;
        padding: 0 1.25rem 0.625rem;
        color: $green;
    }
}

.card.quickConnect   {
    select.customSelect {
        border: .0625rem solid $blue;
        padding: .47rem .45rem;
        font-size: .875rem;
        line-height: 1.5;
        border-radius: .1875rem;
        color: $grey-300;
        background-color: $white;
        text-align: left;
        text-overflow: ellipsis;
        overflow: hidden;
        font-family: 'ScalaSans-Light';
        width: 100%;
        position: relative;
    }
    .field-validation-error {
        // font-size: .8rem;
        // position: absolute;
        // top: 0.1rem;
        // font-weight: bold;
        // left: 100%;
        // width:auto;
        // text-align: left;
        // background: #fff;
        // color: red;
        // padding: 0.2rem 0.7rem;
        // border-radius:0px 4px 4px 0;
        // height:1.6rem;
        // z-index: 100;
        // min-width: 7rem;
        font-size: 0.7rem;
        &:after {
            // width: 0; 
            // height: 0; 
            // border-top: 0.82rem solid transparent;
            // border-bottom: 0.82rem solid transparent; 
            // border-right:0.82rem solid #fff; 
            // content: "";
            // position: absolute;
            // left:-0.78rem;
            // top:0rem;
        }
    }
}


.promotionBanner  {
    // .card.quickConnect {
    //     .field-validation-error {
    //        position: absolute;
    //        right: auto;
    //        left: auto;
    //        top:auto;
    //        bottom:-1.8rem;
    //        padding:0;
    //        line-height: 1;
    //        font-size: 0.7rem;
    //        background:transparent;
    //     }

    // }
    .col-lg-9 {
     z-index: 1000;
    }
}


.homebannerWrap .card {
    .form-control{
        opacity: 1!important;
        height: 1.90rem;
    }
select.customSelect {
    height: 1.90rem;
    padding: 0.25em 0.45rem;
    border-color: $black;
}
}
.field-validation-error {color: red;}




.agent-block .card.quickConnect .field-validation-error:after ,.footerQuickLink .card.quickConnect .field-validation-error:after{display: none;}
.agent-block .card.quickConnect .field-validation-error,.footerQuickLink .card.quickConnect .field-validation-error  {position: static; right: auto;background: none;padding-left: 0;}
    select {
        width: 100%;
       background: url(../images/select-2.jpg) right center no-repeat;
         -webkit-appearance: none;
         -moz-appearance: none;
          -ms-appearance: none;
             -ms-appearance: none;
             border:none;
             border-bottom: 1px solid #acacac;
             padding: 0.57rem 0;
    }
