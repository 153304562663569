
.contactBanner {
    position: relative;
    z-index: 100;
    .banner {
        min-height: 400px;
        h2.lg,h1.lg {
            margin-bottom: 6rem;
        }
    }
  .quickLinkWrap {
       .innerWrap {
        position: absolute;
        width: 100%;
        bottom: -4rem;;
       
    }
    span.d-inline-block{
        width: 86%;
        padding-left: 0rem;
        
    }
    p,svg {
        display: inline-block;
        vertical-align: middle;
    }
    p {
        width: 40%;
        font-size: 1rem;
        line-height: 1.3;
        strong {font-family: "ScalaSans-Bold";line-height: 1;}
        span {
            font-family: 'Gotham-medium';
            font-size: 0.9rem;
            line-height: 1.2;
            // padding-left:0.5rem;
            display: inline-block;
            font-weight: bold;
            
        }
        &+p {
            width: 58%;
            font-size: 1rem;
             text-align: right;
             padding-left: 4%;
             span {
             font-size: 1.1rem;
            }
        }
    }
    .gBox {
        padding: 1.2rem;
        width: 33.33%;
        height: 6.8rem;
    }
  }

.quickLinkWrap  {
  &.contactBannerloginBox {
    position: absolute;
    bottom: -3.0625rem;
    width:100%;
    @include media-breakpoint-down(sm) {
        position: relative;
        width: 100%;
        top: -1.375rem;
        z-index: 1;
    }
    .innerWrap {
        position: static;
        bottom: auto;
    }
      .gBox {
          width:25%;

          @include media-breakpoint-down(sm) {
              width: 50%;
          }
          .d-inline-block {
            width:auto;
           
            vertical-align: middle;
            h4 {
                margin:0;
            }
          }
          p:empty {
              display: none;
          }
          p {
              width: 100%;
          }
      }
  }
}
}
.contactBanner .quickLinkWrap .gBox {padding: 1rem 1.5rem;}

.internalPage {
    .block-contact {
        &:last-child{
        padding-top: 10rem;
        }
        h3.light,h1.light {
            font-size: 1.3rem;
            margin:0;
            padding-bottom: 3rem;
            line-height: 1.5;
            font-family: 'Gotham-light';
        }
        .form-control,.form-group .stylish-select-left .stylish-select-right,select {
            border:none;
            border-bottom: 1px solid $light-brown ;
        }
        .form-group .stylish-select-left .stylish-select-right:focus,.form-group .stylish-select-left .stylish-select-right.hasfocus {
            border: 1px solid $light-brown ;
        }
        .form-control,.form-group .stylish-select-left .stylish-select-right,.require-label,select {
            font-size: 1.1rem;
            color: $input-color;
            border-radius: 0px;
            font-family: 'Gotham-light';
            padding:0.6rem 0.3rem;
        }
        select {
            width: 100%;
            background: url(../images/select-2.jpg) right center no-repeat;
            -webkit-appearance: none;
            -moz-appearance: none;
            -ms-appearance: none;
            -ms-appearance: none;
            
        }
        
        .form-group {
            margin-bottom: 1.6rem;
            ul.listing {
              top:125%;
            li {
                padding: 0.5rem 1.1rem;
            }
           }
        }
        .map_filter {
            ul.listing {
                top:117%;
            }
        }
        textarea.form-control{
            padding:0.6rem 0.3rem;
            height: 6rem;
        }
        .map_filter {
            position: relative;
            z-index: 2;
            .form-group .stylish-select-left .stylish-select-right{
          background: #fff;
          border:none;
          padding: 0.5rem 1rem;
            }
            .form-group {
                margin-bottom:0;
            }
        }
        @include placeholder {
           opacity: 1;
           color: $input-color;
           font-family: 'Gotham-light';
        }
        
        .btnWrap {
            padding-top: 1.2rem;
            .btn {
                text-transform: uppercase;
                font-size: 1rem;
                min-width: 13rem;
                line-height: 1.2;
            }
        }
        aside {
            padding-left: 3rem;
            svg {
                max-width: 1.6rem;
                margin-right:0.8rem;
                fill:$white;
                stroke: $white;
                max-height:2rem;
                &.svg_liveInsurance {
                    fill: none;
                }
            }
            svg,span {
                display: inline-block;
                vertical-align: middle;
            }
           
            .navbar-nav{
                li{
                    margin-bottom: 0.5rem;
                   
                }
                .nav-link {
                    font-family: 'Gotham-light';
                    font-size: 0.8rem;
                    color: $white;
                    text-transform: uppercase;
                    display: block;
                    width: 100%;
                    &:hover {
                        opacity: 0.6;
                    }
                    &> span {
                        max-width: 80%;
                        &>span {
                            width:100%;
                        }
                    }
                }
            }
            .blue_aside {
                background-color:$blue-200;
            }
            &>div {
                padding:2rem;
            }
            h3 {
                font-family: 'Gotham-light';
                font-size: 1.3rem;
                text-transform: uppercase;
                color: $white;
                margin-bottom: 1.5rem;
            }
            .green_aside {
                background-color: $green;
                .nav-link {
                    span {
                        font-size: 0.6rem;
                        line-height: 1.3;
                        strong {
                            font-size: 0.8rem;
                            font-family: 'Gotham-medium';
                            font-weight: bold;
                            display: block;
                        }
                    }
                }
            }
        }

    }
}
.contact_map {
    padding: 5rem 0 2rem;
    iframe,#mapdiv{
     height: 38rem!important;
    }
.map_filter {
    background:$white-light;
    padding: 1.7rem 0;
    
}
.address_list {
    position: absolute;
    right: 0;
    width:30%;
    top: -91px;
    background: $white;
    @include box-shadow(0px 0px 8px rgba(0,0,0,0.2));
    padding: 2.4rem  1.5rem 2.4rem 0;
    border:2px solid $blue;
    height: 45.7rem;
    overflow: hidden;
    .mCSB_container {
    .innerWrap > div {
        border-bottom: 1px solid $gray-light;
        padding: 1.5rem 0 2rem 1.5rem;
        &:last-child {
            border-bottom:none;
        }
        &:first-child {
            padding-top:0;
        }
    }
    }
    h3 {
        font-size: 1.2rem;
        font-family: 'Gotham-medium';
        span{
            display: block;
            font-family: 'Gotham-light';
            font-size: 1rem;
            margin-bottom: 0.5rem;
        }
    }
    p {
        line-height: 1.2;
        span {
            display: block;
        }
    }
    ul {
        padding: 0;
        li {list-style: none;
            padding-bottom: 0.2rem;
         a {color: $brown-200;}
        }
    }
    .time_detail {
        p {
            font-family: 'Gotham-medium';
            font-size: 0.8rem;
            color: $blue;
        }
    }
}
}

.mCSB_scrollTools {
    .mCSB_draggerRail{
        width: 15px;
        background-color:$white-light-10;
    }
  .mCSB_dragger{
    .mCSB_dragger_bar,.mCSB_dragger ,.mCSB_draggerRail{
        background: $green!important;
        width: 15px;
        height: 74px;
        &:hover {
            background: $green!important;
        }
    }
  }
}
.mCSB_scrollTools .mCSB_dragger {
    height: 5.2rem!important;
}
.contactBanner {
    svg {
        stroke-width: 5px;
    }
}
@media all and (max-width:1366px){
    // .contactBanner .quickLinkWrap p span {
    //     font-size: 1.75rem;
    // }
    
}
@media all and (min-width:1500px) {
    .contactBanner .quickLinkWrap span.d-inline-block {width:84%;}
    .contactBanner .quickLinkWrap p {width:42%;}
    .contactBanner .quickLinkWrap p + p {
        width:56%;
        font-size: 0.9rem;
    }
  
}
@include media-breakpoint-down(lg) {
    .contactBanner {
        .quickLinkWrap {
            p {display: block; width: 100%;}
            span.d-inline-block {width: 100%; padding: 0;}
        }
       
    
    }
.contactBanner .quickLinkWrap p + p {
    width: 100%;
    padding-left: 0;
    text-align: center;
}
.contactBanner .quickLinkWrap p span {
text-align: center;
padding:0;

}
.contactBanner .quickLinkWrap p + p span {
    font-size: 0.9rem;
}
    .internalPage .block-contact aside h3 {font-size: 1.1rem;margin-bottom: 1.3rem;}
    .internalPage .block-contact aside > div { padding: 1.3rem;}
    .internalPage .block-contact aside .green_aside .nav-link span strong {font-size: 0.7rem;}
    .internalPage .block-contact aside svg {max-width: 1.4rem; margin-right: 0.3rem;}
    .internalPage .block-contact .map_filter .form-group .stylish-select-left .stylish-select-right {font-size: 1rem;}
}

@include media-breakpoint-down(md) {
    .contactBanner .banner {
        min-height: 300px;
        height: 35vh;
        h2.lg,h1.lg {
         font-size: 4rem;
         margin-bottom: 5rem;
        }
    }
    .internalPage .block-contact:last-child {
        padding-top: 7rem;
    }
    .contactBanner .quickLinkWrap p,.contactBanner .quickLinkWrap p span {font-size: 0.8rem;}
    .contactBanner .svg_call {max-width: 2rem;}
    .internalPage .block-contact aside {padding:3rem 0 0 0;}
    .internalPage .block-contact .map_filter .form-group {margin-bottom: 0.7rem;}
    .internalPage .block-contact aside .green_aside .nav-link span strong {font-size: 0.8rem;}
    .contact_map  {
        padding: 4rem 0 0rem;
        .address_list {
        position: static;
        width:100%;
        right: auto;
        top:auto;
        height: 16rem;
        padding: 1.4rem 0.5rem 1.4rem 0;
    }
    form.row {
        margin-bottom: 1.5rem;
    }
    iframe,#mapdiv {
        height: 18rem!important;
    }
    }
}

@include media-breakpoint-down(sm) {
    .contactBanner {
        .quickLinkWrap {
            .gBox {width: 50%;padding: 1rem;height: 8rem;}
            .innerWrap {
                position: static;
                bottom: auto;
                margin: 0 auto;
                .wrap {
                    width:100%;
                }
            }
        }
        .banner {
            min-height: 200px;
            height: 26vh;
            h2.lg,h1.lg {
                font-size: 3rem;
                margin-bottom: 2rem;
            }
        }

    }
    .internalPage {
        .block-contact:last-child{
        padding-top: 3rem;
        }
        .block-contact {
            h3.light {padding-bottom: 2rem;}
            .btnWrap {
                padding-top:0;
                .btn {margin-top:0;}
            }
        }
        
    }
    .contact_map {
        padding: 2rem 0 0rem;
    }
    .internalPage .block-contact .form-control, .internalPage .block-contact .form-group .stylish-select-left .stylish-select-right, .internalPage .block-contact .require-label {
        font-size: 1rem;
    }
    .internalPage .block-contact .form-group {margin-bottom: 1rem;}
    .internalPage .block-contact h3.light {
        padding-bottom: 1rem;
        font-size: 1.1rem;
    }
    .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCSB_scrollTools .mCSB_dragger .mCSB_dragger, .mCSB_scrollTools .mCSB_dragger .mCSB_draggerRail {
        width: 10px;
    }
    .internalPage .block-contact:last-child {padding-bottom: 7rem;}
}
@include media-breakpoint-down(xs) {
    .contactBanner .banner h2.lg,.contactBanner .banner h1.lg {font-size: 2.4rem;}
    .contact_map {
        .address_list {
            h3 {
        font-size: 1rem;
        margin-bottom: 1rem;
        span {
            font-size: 0.9rem;
         margin-bottom: 0.3rem;
        }
            }
            p {
                margin-bottom: 1rem;
            }
        }
    }
    .contact_map .address_list .mCSB_container > div {
        padding: 1.5rem 0 0.5rem 1rem;
    }
    
}


.internalPage .contact_form .dropdownLabel, .internalPage .contact_form .dropdownLabel label {
    position: static;
    top: auto;
    input{
        margin-right: 0.5rem;
    }
}
.contactBannerloginBox svg {
    stroke: #fff;
    fill: #fff;
}