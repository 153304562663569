.icon {
    background: url(../images/sprite.png);
    background-repeat: no-repeat;
    background-position: -6px -12px;
    display: inline-block;
    width: 38px;
    height: 24px;
    vertical-align: middle;
    text-indent: -9999px;
    margin-right: 15px;
    cursor: pointer;
    @include transition(0s ease);
    &.ico-mail {
        background-position: -6px -12px;
    }
    &.ico-call {
        width: 30px;
        height: 29px;
        background-position: -36px -4px;
    }
    &.ico-agent {
        width: 30px;
        height: 29px;
        background-position: -155px -39px;
    }
    &.ico-ask {
        width: 30px;
        height: 29px;
        background-position: -117px -7px;
    }
    &.ico-lg-lock {
        width: 30px;
        height: 40px;
        background-position: -196px -6px;
    }
    &.ico-lg-insurance {
        width: 40px;
        height: 40px;
        background-position: -235px -6px;
    }
    &.ico-lg-call {
        width: 42px;
        height: 42px;
        background-position: -286px -7px;
    }
    &.ico-lg-notes {
        width: 46px;
        height: 46px;
        background-position: -339px -6px;
    }
    &.ico-lense {
        width: 22px;
        height: 22px;
        background-position: -391px -34px;
        &.active {
            background-position: -391px -4px;
        }
        @media only screen and (min-width: 1025px) {
            &:hover {
                background-position: -391px -4px;
            }
        }
    }
    &.ico-user {
        width: 26px;
        height: 26px;
        background-position: -419px -32px;
        @media only screen and (min-width: 1025px) {
            &:hover {
                background-position: -419px -3px;
            }
        }
    }
    &.lang-default-img {
        background: none;
        margin-right: 0;
        width:1.5rem;
        @include media-breakpoint-down(lg){
            margin-right: 25px;
        }
        img {
            max-width:1.5rem;
            max-height: 1.125rem;
            width:auto!important;
            height: auto!important;
            display: block;
            position: relative;
            top: 0.23rem;
        }
    }
}

svg {
    fill: $green;
    stroke: $green;
    stroke-miterlimit: 8;
    stroke-width: 1px;
    @include transition(all 0.5s ease);
}

.svg_insurance {
    
    .cls-1 {
        stroke-miterlimit: 10;
        stroke-width: 3px;
    }
    path {
        stroke-width: 1.5;
    }
}

.svg_banking {
    .cls-1 {
        fill: none;
        stroke-miterlimit: 10;
        stroke-width: 4px;
    }
}

.svg_mortgages {
    .cls-1,
    .cls-2 {
        stroke-miterlimit: 10;
        stroke-width: 0;
    
    }
    .cls-2 {
        fill: none;
    }
    .cls-3 {
        font-size: 51.04px;
        font-family: Gotham-Medium, Gotham;
        
    }
}
.svg_insvestment {
    path {
        stroke-width: 0;
    }
}
.svg_property {
    .cls-1 {
        fill: none;
        stroke-miterlimit: 10;
        stroke-width: 7px;
    }
}

.svg_ship {
    .cls-1 {
        stroke-miterlimit: 10;
        // stroke-width: 0.25px;
        stroke-width: 0;
    
    }
}

.svg_loans {
    .cls-1 {
        stroke-miterlimit: 10;
        stroke-width: 0;
       
    }
}

.svg_call {
    .cls-1 {
        fill: none;
        stroke-width: 6px;
    }
}

.svg_report {
    .cls-2 {
        fill: none;
        stroke-miterlimit: 10;
        stroke-width: 5px;
    }
}
.hiveblock {
    .gBox {
        .svg_board {
            max-width: 3rem;
        }
        .svg_notice {
            max-width: 2.5rem
        }
        .svg_invest_contact {
            max-width: 3rem;
        }
        .svg_executive {
            max-width: 3.5rem;
            path {
                stroke-width: 3;
            }
        }
        .svg_portal {
            max-width: 3.5rem;
        }
        }
    }


.quickLinks {
    .nav-item {
        a,.nav-link,&>span {
            display: block;
            overflow: hidden;
            span {
                display: inline-block;
                vertical-align: middle;
                margin-left: 4px;
            }
           
            svg {
                width: 3.2rem;
                margin-right: 6px;
                fill: $white;
                stroke: $white;
            }
            .svg_email {
                max-width: 1.7rem;
            }
            .svg_call {
                max-width: 1.7rem;
                .cls-1 {
                    fill: none;
                    stroke-width: 6px;
                }
                &.svg_chat {
                    .cls-1 {
                        stroke-width: 4px;
                    }
                }
            }
        }
        a, span{
            span.nav-link {
                  display: inline-block;
                  max-width: 90%;
                  @include media-breakpoint-down(sm) {
                    max-width: 80%;
                  }
            }
        }
        &:hover {
            svg {
                fill: $brown;
                stroke: $brown;
            }
        }

        .nav-link {
            a {
                display: inline-block;
                vertical-align: middle;
                color: $blue;
                font-size: 1rem;
            }
            &:hover {
                color: $blue;
            }
        }
    }
    &.card {
        .nav-item {
            a,.nav-link,&>span {
                svg {
                    fill: $green;
                    stroke: $green;
                }
                &:hover {
                    svg {
                        fill: $blue;
                        stroke: $blue;
                    }
                }
            }
        }
    }
}

.hiveblock  {
    .gBox  {
        .svg_banking {max-width: 3rem;stroke-width: 0;}
    }
    
}
.block-investor {
    .hiveblock {
        .svg_call{
            max-width: 3rem;
            }
    }
}

.contactBanner {
    .svg_call {
        max-width: 2.5rem;
        fill: $white;
        stroke:$white;
    }
}
.svg_liveInsurance {
    fill: none;
    stroke-width: 6px;
}
#Capa_1 {
    max-width: 1.7rem;
}

.internalPage .quickLinks .nav-item a {
    display: inline-block;
}


.log-header .icon.ico-user {
    background-position: -419px -3px;
}