.logo {
    max-width: 185px;
    position: relative;
    margin:0;
}

.navbar.fixed-top{
    @include transition(0.30s ease);
    padding-top: 1.05rem;
    background: -moz-linear-gradient(top, rgba(0,0,0,0.44) 1%, rgba(0,0,0,0.43) 3%, rgba(0,0,0,0.18) 81%, rgba(0,0,0,0.11) 88%, rgba(0,0,0,0) 100%);
    background: -webkit-linear-gradient(top, rgba(0,0,0,0.44) 1%,rgba(0,0,0,0.43) 3%,rgba(0,0,0,0.18) 81%,rgba(0,0,0,0.11) 88%,rgba(0,0,0,0) 100%);
    background: linear-gradient(to bottom, rgba(0,0,0,0.44) 1%,rgba(0,0,0,0.43) 3%,rgba(0,0,0,0.18) 81%,rgba(0,0,0,0.11) 88%,rgba(0,0,0,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#70000000', endColorstr='#00000000',GradientType=0 );
    &.shade-0{
        background: none;
    }
    &.stuck{
       background: $brown;
        padding-top: 0.5rem;
    }
}

.dark-text {
    .selLanguage .stylish-select-left:after {
        border-color:$gray-light-0;
    }
    .icon.ico-lense.icon.ico-lense {
        background-position: -41px -241px;
    }
    .icon.ico-user {
        background-position: -69px -239px;
    }
  .megaMenu.navbar-nav {
    li  {
        .nav-link {
            color: $darker-brown-1;
        }
    }
    }
    &.dark-text.stuck {
       
        .megaMenu.navbar-nav {
            li  {
                .nav-link {
                    color: $white;
                }
            }
            }

            .icon.ico-lense {
                background-position: -391px -34px;
            }
            .icon.ico-user {
                background-position: -419px -32px;
            }
            .selLanguage .stylish-select-left:after {
                border-color:$white;
            }
    }
}
body.modal-open {overflow: auto;}


.megaMenu .accordian .contentWrap .content.submenu-active {
        padding: 1rem;
        background:transparent;
        &:before {
            position: absolute;
            left:0;
            top:0;
            width:100%;
            height: 100%;
            background: $white-light;
            content: "";
            z-index: -1;
             @include transition(0.25s ease-in-out);
             opacity:0;
        }
        &.bg-gray {
        position: static;
        background:transparent;
        &:before {
            -webkit-animation: myback 1s;
           -moz-animation: myback 1s;
           -ms-animation: myback 1s;
           -o-animation: myback 1s;
            animation: myback 1s;
            opacity: 1;
            @include media-breakpoint-down(lg) {
             display: none;
            }
        }
       
    }
        @-webkit-keyframes myback {
            0%   { 
               opacity: 0;
            }
          
            100% {
                opacity: 1;
          }
        }
          @-moz-keyframes myback {
            0%   { 
                opacity: 0;
             }
           
             100% {
                 opacity: 1;
           }
          }
         
          @-moz-keyframes myback {
            0%   { 
                opacity: 0;
             }
           
             100% {
                 opacity: 1;
           }
          }
          
        
          @keyframes myback {
            0%   { 
                opacity: 0;
             }
           
             100% {
                 opacity: 1;
           }
          }

       
           
       
       
        @include media-breakpoint-down(sm) {
            padding:0.6rem;
        }

    
}
.megaMenu .accordian ul {
    position: relative;
    z-index: 1;
    background:$white;
    .nav-item {
        background: $white;
    }
    .bg-active {
        .nav-item.dropdown  {
            background: $white-light;
        }
    }
}
.megaMenu .accordian ul li.nav-item.dropdown .nav-item{
    background: $white;
    &:hover{
        background: $white-light;
    }
}
.megaMenu .accordian ul li.nav-item.active .nav-item,.megaMenu .accordian ul li.nav-item.active.dropdown .nav-item{
    background: $white-light;
    &:hover{
        background: $white-light;
    }
}
.megaMenu > .dropdown > .dropdown-menu[data-rel="0"] {
.accordian {
    &:before {
        display: none;
    }
}
@include media-breakpoint-up(md) {
.contentWrap {
    display: none;
}
}
}

#forgotpassLink ,#signupOption {
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
}

.logged-form-wrap {
    padding:1.5rem 0 1rem;
}
.log-header {
    
    .userloginBox .display-1 {
        font-size: 1.5rem;
    }
    h4 {
        font-size: 1rem;
    }
}