.homebannerWrap.promotionBanner{
    min-height: 42.25rem;
    height: 80vh;
    .card.type-2 {
        max-width: 28rem;
    }
    .form-group ul.listing {
        top:140%;
    }
   .card.type-2 .form-group {
        margin-bottom: 1.1rem;
    }
    .homeBanner {
        min-height: 1px;
    }
}

.offerBlock-gray {
    background: $white-100 
}
.btn-blue {
    background: $blue;
    color: $white;
    border-color: $blue;
}
%list-bg {
    background: url(../images/pointer.png) left 0px no-repeat;
    padding-left: 50px;
    min-height: 32px;
}
.promotionBanner {
    .type-2.quickConnect {
        @include box-shadow(0 3px 10px rgba(0, 0, 0, 0.3));
    }
    &.promotionBanner-main {
    .innerWrap  {
        padding-bottom: 0;
       .table-row-2 .table-cell {
            vertical-align: bottom;
        }
    }
    .table {margin-bottom: 0px;}
  }
    .quickConnect {
        float: right;
        margin-bottom: 0px;
    }
    h3.light {
        font-size: 3.12rem;
        line-height: 1.2;
        margin-bottom: 2rem;
        span {
            display: block;
             font-size: 1.875rem;
            margin-bottom: 1.1rem;
        }
    }
    

    .type-2.card {
        .card-title {
            text-transform: none;
            font-size: 2.1875rem;
        }
        .btnWrap{
            margin: 1.5rem 0 0.5rem;
            .btn {
                font-size: 1rem;
                padding: 0.7rem 1rem;
            }
       
        .btn-green-full {
            width: 100%;
            display: block;
            background:$green;
            border-color:$green;
            line-height: 3rem;
            font-size: 1rem;
            &:hover {
                background: transparent;
                color: $green;
            }
            }
            .green-banner-btn{
                background: $green;
                color: $white;
                border-color: $green;
                margin-bottom: 2rem;
                &:hover {
                    background: transparent;
                    color: $green;
                }
            }
    }
}
    .card {
        p {
            font-size: 1rem;
            margin-bottom: 1.3rem;
        }
        .form-control ,select,.styledSelect{
           
            font-size: 1rem;
        }
        .form-control ,select {
            height: 2.68rem;
        }
        .styledSelect {
            min-height: 2.25rem;
            margin-top:0.437rem;
        }
        @include placeholder {
            opacity: 1;
        }
    }
    h3.light-2 {
        margin-bottom: 4rem!important;
    } 
    .card {
        label {
            display: block!important;
            font-size: 1rem;
            opacity: 1;
            font-family: 'Gotham-light';
            span {
                color: $red;
            }
            &.require-label:after {
            position: absolute;
            color: $red;
            content: "*";
            width: 10px;
            height: 10px;
            padding-left:5px;

            }
        }
       
        .customSelect-require .styledSelect {
            position: relative;
            &:after {
                position: absolute;
                color: $red;
                content: "*";
                width: 10px;
                height: 10px;
                padding-left:5px;
    
                }
        }
    }
    .btn-dark:hover, .btn-dark:focus {
        border-color: $blue;
    }
    &.promotionBanner-main  {
        h3.light {min-height: 18rem;}
    }
}

.internalPage  {
    .block.promotionBlock:first-child {
    padding: 5rem 0 2rem;
    }
}
.quickConnect.userloginBox {
    display: block!important;
}
.benefits-list {
    li {
        list-style: none;
        margin-bottom: 2.5rem;
        overflow: hidden;
        div.svg-icon {
            float: left;
            @include media-breakpoint-down(sm) {
              float: none;
            }
            &:first-child {
                width: 25%;
                height: 121px;
                max-width: 121px;
                background: $white-100;
                float: left;
                @include media-breakpoint-down(sm) {
                    float: none;
                    padding-left: 0;
                    width: 100%;
                  }
            }
            &:last-child {
                width: 75%;
                padding-left: 1.5rem;
            }
            &+ div {
                width: 75%;
              padding-left: 1.5rem;
              float: left;
              @include media-breakpoint-down(sm) {
                float: none;
                padding-left: 0;
                width: 100%;
              }
            }
        }
        p{
            font-family: 'Gotham-Book';
           
        strong {
            display: block;
            font-family: 'Gotham-bold';
            font-weight: normal;
        }
    }
    &>a {
        color: inherit;
        display: block;
        overflow: hidden;
        &:hover {
  text-decoration: none;
        }
    }
    &:hover {
        background: $white-light;
    }
    }

    &.benefits-list-2 {
        ul {padding: 0 5%; text-align: left}
        li {
            display: inline-block;
            width: 32%;
            text-align: center;
            padding:1.5rem 1rem;
            vertical-align: top;

            div.svg-icon {
                float: none;

                &:first-child {
                    margin:0 auto 1rem;
                    background: $green;
                    width:122px;
                    height: 122px;
                    border-radius: 5px;
                }
                &:last-child {
                    padding-left:0;
                    width: 100%;
                }
                &+ div {
                    width: 100%;
                  padding-left: 0;
                  float: none;
                }
            }

            svg {
                fill:$white;
                stroke:$white;
            }
            .svg-icon svg.svg_nascetur .cls-1 { stroke:$white;}
            &>a {
                display: block;
                color: inherit;
                text-decoration: none;
                &:hover {
                    text-decoration: none;
                   
                }
            }
            &:hover {
                background: $white-light;
            }
        }
    }
   
   
}

.top_content {
    padding-bottom: 2.5rem;
    h2 ,h3,p{
        text-align: center;
        margin-bottom: 0;
    }
    h2 {padding-bottom: 2rem;}
    h3 {padding-bottom: 2rem;}
}
.svg-icon {
    position: relative;
    svg {
        max-width: 3.3rem;
        position: absolute;
        left: 50%;
        top:50%;
       @include transform(translate(-50%,-50%));
       &.svg_nascetur {
           .cls-1 {
            fill:none;
            stroke:$green;
            stroke-miterlimit:10;
            stroke-width:6px;
           }
       }
    }
}
.offerBlock {
    overflow: hidden;
    .col-lg-6.content-wrap {padding: 0 7% 0 30px;}
    .row {
    background: $white-100;
    }
    figure {
        margin: 0;
        img {
            width: 100%;
        }
    }
        padding: 2rem 5rem 2rem 3rem;
    .content-wrap {
        ul {
            padding: 0;
        li {
                list-style: none;
                margin-bottom: 1rem;
                @extend %list-bg;
                padding-top: 5px;
         }
        }
      &.contact-wrap-2 {
          h2,p { text-align: center;margin-bottom: 0; padding-bottom: 2rem;}
          ul {padding:1rem 10% 0;margin-bottom: 0;}
        
      }
    }
}
.promotionListBlock {
    h2 {
        text-align: center;
        margin-bottom: 3rem;
    }
    h3 {
        font-size: 1.2rem;
        margin-bottom: 0.4rem;
        font-family: 'Gotham-medium';
    }
    div[class*="col-"] {
        @extend %list-bg;
       padding-left:2.2rem;
       margin-bottom: 2rem;
    }

    &.promotionListBlock-2 {
        div[class*="col-"] {
         background: none;
         padding-left:15px;
         padding-right:2rem;
         &:nth-child(2n){
            padding-right:15px;
            padding-left: 2rem;
         }
        }
        h3 {
            margin-bottom: 0.9rem;
        }
    }
}
.promotion-header {
    float: none;
    display: block;
    width: 100%;
    #mMenu,.align-items-center {
        display: none!important;
    }
    .logo {
        margin:0 auto;
    }
    &.promotion-header-2 {
        .logo {margin:0;}
    }
}

.promotion-footer{
background: $dark-brown; 
line-height: 0;
.col-lg-4 {
    background: #363735;
}

position: relative;
    &:before {
        background: $grey-300;
        position: absolute;
        content: "";
        right:0;
        top:0;
        height:100%;
        width: 40%;
    }
    .footer-logo {padding-bottom: 1.2rem;padding-top:1.5rem;}
    .socialWrap {
        display: block;width: 100%;
    }
    .socialNav-1 {
        display: inline-block;
        vertical-align: middle;
        li {

           display: inline-block;
        vertical-align: middle;
        margin-right: 0.5rem;
        &:last-child {
            margin-right: 0px;
        }
        .nav-link{
            padding:0;
            svg {
                max-width: 1.2rem;
                fill: $white;
                stroke: $white;
                height: 1.5rem;
                max-height: 1.5rem;
                &#Capa_1 {
                    max-width: 1.2rem;
                }
            }
            &:hover {
                svg {
                    
                    fill:$blue;
                    stroke:$blue;
                }
            }
            &.linkdin-link {
                &:hover {
                    svg{
                    fill: $li-color;
                    stroke: $li-color;
                    }
                }
            }
            &.twiter-link {
                &:hover {
                    svg{
                    fill: $twit-color;
                    stroke:$twit-color;
                    }
                }
            }
            &.facebook-link {
                &:hover {
                    svg{
                    fill: $fb-color;
                    stroke:$fb-color;
                    }
                }
            }
            &.youtube-link {
                &:hover {
                    svg{
                    fill: $yout-color;
                    stroke:$yout-color;
                    }
                }
            }
            &.blog-link {
                &:hover {
                    svg{
                    fill: $blog-color;
                    stroke:$blog-color;
                    }
                }
            }
        }
    }
    }
    p {
        color: $gray-320;
        font-size: 1rem;
        padding-right: 2rem;
        line-height: 1.3;
        padding-bottom:1.5rem;
    }
    .footer-social-nav{
        padding: 3rem 0 2rem 3rem;
        background: $grey-300;
     p {
         padding-right: 0;
         padding-top:0.3rem;
         padding-bottom: 0;
         clear: both;
     }
    }
}
.promotionBlock .rightSpace {
    padding-right: 4%;
} 
.promotionBanner  .homeBanner .type-2.quickConnect .card-body {
    padding: 2rem 1.93rem;
}
.promotionBanner  .homeBanner .type-2.quickConnect form,.promotionBanner .type-2.card .btnWrap .green-banner-btn {margin-bottom: 0;}
.card .form-group.designation.p-r-10 {
padding-right: 22px;
}
.internalPage .block.promotionListBlock:last-child {
    padding-bottom: 4rem;
}
.block.offerBlock-gray {
    padding:5rem 0;
}
.promotion-header .logo {max-width: 225px;}
.promotion-header.promotion-header-2 {max-width: 100%;}
.block.promotionListBlock.promotionListBlock-2 {padding-top:5rem;}
.homebannerWrap.promotionBanner .card select.customSelect{
    
        border:none;
        border-bottom: 1px solid #acacac;
        border-radius: 0px;
        height: 2.7rem;
padding: 0em 0.45rem 0 0rem;
appearance: none;
-moz-appearance: none;
-ms-appearance: none;
-o-appearance: none;
-webkit-appearance: none;
background: url(../images/select-icon.jpg) no-repeat right center;
font-family: 'Gotham-light';
option{
    padding:0;
}

}
@media all and (max-width:1560px) {
    .homebannerWrap.promotionBanner{
        height:97vh;
        min-height:37.25rem;
    }
    .promotionBanner {
        .type-2.card {
            .card-title {
                font-size: 1.4rem;
            }

        }
        .card {
            p {font-size: 0.875rem;margin-bottom: 0.6rem;}
            .form-control,select,.styledSelect,label{
                font-size: 0.875rem;
            }
        }
    }
    .homeBanner .type-2.quickConnect .card-body {
        padding: 1.3rem 1.7rem;
    }
    .homebannerWrap.promotionBanner .card.type-2 .form-group {
        margin-bottom: 1.3rem;
    }
    .promotionBanner .type-2.card .btnWrap .btn-green-full {font-size: 0.875rem;line-height: 2rem}
}
@include media-breakpoint-down(lg) {
    .promotionBanner h3.light {font-size: 2rem;}
    .promotionBanner .type-2.card .btnWrap .btn-green-full {line-height: 33px;}
    .internalPage .block.promotionBlock:first-child {padding: 2rem 0 2rem; }
    .benefits-list {padding-top: 0rem;}
    .internalPage .block.offerBlock {
        padding:1rem 0 1rem;
        h2 {
            padding-top: 1.5rem;
        }
        &.offerBlock-gray {
            padding:1.5rem 0;
        }
    
    }
    .benefits-list {
        ul {padding: 0;}
    }
    .promotionListBlock {
        div[class*="col-"] {
           margin-bottom: 1.3rem;
        }
    }
    .promotion-header .logo .img-fluid {max-height: 50px;}
    .promotionBanner-main .homeBanner .innerWrap {padding-top: 6rem;}
    .promotionBanner.promotionBanner-main h3.light {min-height: 1px;}
    .promotionBanner .type-2.card .card-title {font-size: 1.8rem;}
    .promotionBanner .card p,.promotionBanner .card .form-control, .promotionBanner .card select, .promotionBanner .card .styledSelect,
    .promotionBanner .card label,.promotionBanner .card .form-control, .promotionBanner .card select, .promotionBanner .card .styledSelect,.promotionBanner .type-2.card .btnWrap .btn {font-size: 0.875rem;}
    .promotionBanner .type-2.card .btnWrap {
        margin: 0.8rem 0 0.5rem;
    }
}
@media all and (max-width:1024px) {
    .promotionBanner .homeBanner {height: auto; }
    .homebannerWrap.promotionBanner {
        height: auto;
        max-height: 100%;
    }
    .promotionBanner .homeBanner .type-2.quickConnect .card-body {
        padding: 1rem 1.8rem;
    }
}
@include media-breakpoint-down(md) {
    .benefits-list.benefits-list-2 li {
        display: block;
        width: 100%;
    }
    .promotion-footer {
        &:before {
           width: 100%;
           height: 8rem;
           top:auto;
           bottom: 0;
           left:0;
           right: auto;
        }
        .footer-social-nav {
            height: 8rem;
            padding: 0 0;
        }
    }
    .col-lg-6.content-wrap {
        padding:1.5rem 30px;
    }
    .top_content {
        padding-bottom: 1rem;

    h2 {
        padding: 1rem 0;
    }
}
.promotionBlock .rightSpace {
    padding-right: 0%;
} 
.card .form-group.designation.p-r-10 {
    padding-right: 10px;
    }
    .block.promotionListBlock.promotionListBlock-2 {padding-top:3rem;}
    .benefits-list {

        padding-top: 2rem;
    
    }
}
@include media-breakpoint-down(sm) {
    .promotionBanner  {
        .homeBanner {
       .type-2.quickConnect {
        margin-top: 1.5rem;
    }
}
}
.homebannerWrap.promotionBanner .card.type-2 {max-width: 100%;}
.offerBlock .content-wrap.contact-wrap-2 ul {padding: 0.8rem 0;}
.promotionListBlock.promotionListBlock-2 div[class*="col-"] {padding-right:15px;}
.promotionListBlock.promotionListBlock-2 div[class*="col-"]:nth-child(2n) {padding-left:15px;}
.promotion-footer {
    &:before {
       height: 10rem;

    }
    .footer-social-nav {
        height: 10rem;
    }
}
.internalPage {
    .offerBlock  {
        .content-wrap {
            padding: 1rem;
            ul li {padding-top:0;}
        }
    }
}
.benefits-list.benefits-list-2 {
    li {
        padding:0;
    }
}
.benefits-list li > div:last-child {padding-left:0;}
.benefits-list.benefits-list-2 ul {padding:0;}
.internalPage .benefits-list li > div.svg-icon {
    margin:0 0 1rem;
}
.internalPage .block.promotionBlock h2, .internalPage .block.promotionBlock p, .internalPage .block.promotionBlock h3 {text-align: left;padding-left:2rem;}

.internalPage .benefits-list-2 li > div.svg-icon {
    margin:0 auto 1rem;
}
.internalPage .block.promotionBlock .benefits-list-2 h2, .internalPage .block.promotionBlock .benefits-list-2 p, .internalPage .block.promotionBlock .benefits-list-2 h3 {text-align: center;padding-left:0rem;}

}
@include media-breakpoint-down(xs) {
    .promotionBanner h3.light span {font-size: 1rem;margin-bottom: 0.3rem;}
    .promotionBanner h3.light {font-size: 1.5rem;margin-bottom: 1.5rem;}
    .promotionBanner {
        .type-2.card {
            .card-title {
                font-size: 1.5rem;
                margin-bottom: 0.3rem;
            }
            .btnWrap {
                margin: 1rem 0 0rem;
            }
        }
        .card {
            .form-control ,select,.styledSelect{
                font-size: 0.9rem;
                line-height: 1.8;
                 margin-bottom: 0.3rem;
            }
            
        }
    }
    .internalPage  {
        .block.promotionBlock {
        &:first-child{
        padding: 2rem 0 1rem;
        }
        h2 {
            font-size: 1.6rem;
        }
        h3.light {
            font-size: 1.3rem;
        }
        
        }
        .benefits-list li > div {
            width: auto;
            float: none;
            &.svg-icon {
             margin:0 auto 0.8rem;
            }
        }
        .offerBlock {
            h2 {
                font-size: 1.6rem;
            }
            .content-wrap {padding: 2rem;}
        }
    }
    %list-bg {background-size: 20px auto;padding-left: 31px;}
    .promotionListBlock {
        h2 {
            font-size: 1.6rem;
            margin-bottom: 2rem;
        }
        div[class*="col-"] {
            background-position: 13px 0px;
            padding-left: 42px;
        }
    }
    .internalPage {
        .offerBlock.offerBlock-gray  {
            .content-wrap {
                padding: 0rem 1rem;
            }
            h2 {
                padding-top: 0.5rem;
            }
        }
    }
    .internalPage .benefits-list li > div.svg-icon {
        margin:0 0 1rem;
    }
    .internalPage .block.promotionBlock h2, .internalPage .block.promotionBlock p, .internalPage .block.promotionBlock h3 {padding-left:0rem;}
    .internalPage .benefits-list-2 li > div.svg-icon {
        margin:0 auto 1rem;
    }
}

.homebannerWrap.promotionBanner .form-group .main {
    z-index: 10000!important;
}

.customer-options {
    position: relative;
    z-index: 100000;
    margin-bottom: 2.5rem!important;
}