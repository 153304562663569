.descBlock{
    h3{
        margin-bottom:2.7rem;
    }
    h4{
        margin-bottom: 0.65rem;
        @include media-breakpoint-up(md) {
            margin-bottom: 1.5rem;
        }
    }
    .regular{font-family:'ScalaSans-Light';}

}
.innerBlock{
    margin-bottom: 0.5rem;
    @include media-breakpoint-up(md) {
        margin-bottom: 1rem;
    }

    :last-child{
        margin-bottom: 0;
    }
}

.planList{
    background-image: url('../images/wide-banner.jpg');
    background-position: 0 0;
    background-size: cover;
    background-repeat: no-repeat;
    position:relative;
    color:$white;
    padding:4.5rem 0;
    a{
        color:$white;
    }
    .display-4{
        font-family:'Gotham-light';
        font-size:2rem;
        margin-bottom:1rem;
    }
    .heading{
        -webkit-flex-wrap: nowrap;
        flex-wrap: nowrap;
        .col{ 
            width: 23.8%;
            margin: 1% 0 1% 1.6%;
            padding:0;
        }
    }
    .column{
        -webkit-flex-wrap: nowrap;
        flex-wrap: nowrap;
        .col{ 
            display: block;
            margin: 1% 0 1% 1.6%;
            margin-bottom: 0.5rem;
            border:1px solid $green-100; min-width: 140px;padding: 0.15rem 0.6rem;
            width: 23.8%;
        
        }
    }
}
.large-none {
    display: none;
}
.block-investor {
    .heading5 {
        padding: 5rem 0 4rem 0;
        display: inline-block;
    }
}
@include media-breakpoint-down(sm) {
    .planList {
        .heading {display: none;}
        .column {
            display: block;
            border:1px solid $green-100;
            margin:0 2% 1rem;
            .col {
                width: 100%;
                border: none;
                display: table;
                span {
                    display:table-cell;
                    vertical-align: top;
                    width: 47%;
                }
            }
         }
    }
    .large-none {
        display: block;
        font-weight: normal;
        padding-right: 2%;
    }
 }
