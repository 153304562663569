.grayBg-block {
    background: $white-150;
    .pageOptions {
      margin:0;
      background: transparent;
      padding:1.75rem 0 2.5rem;
    }
    &.grayTopContent {
      padding: 3rem 0 3.5rem;
      @include media-breakpoint-down(md) {
        padding: 2rem 0 1.5rem;
      }
    }
    .card-body {
      background: $white;
    }
}
.product-search-wrap {
    padding:5rem 0 0;
    background: $white;
.product-search-sidebar {
   
    margin-right: 10%;
    min-height: 10rem;
    padding-top:0.65rem;
    position: relative;
    margin-bottom: 10rem;
    @include media-breakpoint-down(md) {
    margin:0 0 3rem;
    }
    &:before {
        border-top:0.25rem solid $blue;
        width: 90%;
        height: 0.25rem;
        position: absolute;
        content: "";
        left:0;
        top:0;
        @include media-breakpoint-down(md) {
            width: 100%;
        }
    }
    div {
        padding: 0;
    }
    label {
        font-size: 1.375rem;
        color: $brown-200;
        font-family: 'Gotham-medium';
        padding: 1.3rem 0.875rem;
        @include media-breakpoint-down(md) {
            font-size: 1rem;
            padding: 0.7rem 0.5rem;
        }
    }
    .stylish-select {
        height: 4.125rem;
        @include media-breakpoint-down(md) {
            height: 3.125rem;
        }
    }
    select,.stylish-select-right{
       
        border:none;
        background: $white-100;
        padding: 1.4rem 1.75rem;
        color: $darker-brown-1;
        font-family: 'Gotham-light';
        font-size: 1.125rem;
        border-radius: 0.4rem;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        padding-right: 2.5rem;
        @include media-breakpoint-down(md) {
            padding: 0.6rem 1rem;
            font-size: 0.875rem;
        }
    }
    .form-group{
        margin-bottom: 0.7rem;
        .stylish-select-left:after {
            width: 0.6rem;
            height: 0.6rem;
            border-color: $textColor;
            right: 1.75rem;
            @include media-breakpoint-down(md) {
                right: 1rem;
                width: 0.4rem;
                height: 0.4rem;
            }
        }
        ul.listing {
          top:112%;
          @include media-breakpoint-down(md) {
             top:84%;
          }
        li {
            padding: 0.5rem 1.75rem;
            font-family: 'Gotham-light';
            @include media-breakpoint-down(md) {
                padding: 0.6rem 1rem;
            }
        }
    } 
    }

    .btnWrap {
        padding:1rem 0;
    }
    a {
        font-family: 'Gotham-medium';
    }
    
}
.product-search-form {
    padding-bottom: 1.5rem;
    .btn {
        width: 22%;
        text-transform: uppercase;
        font-family: 'Gotham-medium';
        font-size: 0.875rem;
        height: 3.2rem;
    }
   .searchTag {
       width: 78%;
       color: #5b5d60;
       font-size: 0.875rem;
       text-transform: uppercase;
       font-family: 'Gotham-medium';
       height: 3.2rem;
       padding:0.8rem 1.75rem;
       
       &::-webkit-input-placeholder{
        color: #5b5d60;
        font-size: 0.875rem;
        text-transform: uppercase;
        font-family: 'Gotham-medium';
        opacity: 1;
       }
       &::-moz-placeholder {
        color: #5b5d60;
        font-size: 0.875rem;
        text-transform: uppercase;
        font-family: 'Gotham-medium';
        opacity: 1;
       }
       &:-ms-input-placeholder {
        color: #5b5d60;
        font-size: 0.875rem;
        text-transform: uppercase;
        font-family: 'Gotham-medium';
        opacity: 1;
       }
       &:-moz-placeholder {
        color: #5b5d60;
        font-size: 0.875rem;
        text-transform: uppercase;
        font-family: 'Gotham-medium';
        opacity: 1;
       }
   }
   
   }
}
.product-search-list {
    margin-bottom: 11rem;
    @include media-breakpoint-down(md) {
        margin-bottom: 7rem;
    }
}
.product-card {
    display: table;
    width: 100%;
    @include box-shadow(0px 3px 10px rgba(0,0,0,0.1));
    padding:2.2rem 2.5rem 3rem;
    margin-bottom: 1.25rem;
    @include media-breakpoint-down(sm) {
        padding:1.2rem 1.5rem 2rem;
        display: block;
    }
    
    ul {
        padding:0;
        list-style: none;
        li {
            font-size: 1rem;
            font-family: 'Gotham-book';
            line-height: 1.3;
            @include media-breakpoint-down(md) {
                font-size: 0.875rem;
            }
        }
    }
    .product-card-thumb {
       display: table-cell;
       vertical-align: top;
        width:36%;
        padding-right: 0%;
        @include media-breakpoint-down(sm) {
         float: none;
         width: 100%;
         overflow: hidden;
         display: block;
        }
       figure {
           background: #5b5d60;
           width:100%;
           height: 10rem;
           border-radius: 0.6rem;
           margin-bottom: 1.4rem;
           overflow: hidden;
           @include media-breakpoint-down(sm) {
            min-height: 10rem;
            height: auto;
            
           }
           img {
               width: 100%;
               height: 100%;
               min-height:  10rem;
               border-radius: 0.6rem;
               @include transition(0.5s ease-in-out);
               &:hover {
                   @include transform(scale(1.1));
               }
           }
       }
       li {
           font-size:1.25rem;
           color: #6b5e51;
           font-family: 'ScalaSans-Light';
           margin-bottom: 0.4rem;
           @include media-breakpoint-down(md) {
            font-size: 0.875rem;
        }
       }
      
    }
    .product-list-content {
        padding-left: 4%;
        display: table-cell;
       vertical-align: top;
        @include media-breakpoint-down(sm) {
            float: none;
            width: 100%;
            padding-left: 0;
            display: block;
           }
        h2 {
            color:$brown-200;
            font-size: 1.875rem;
            font-family: 'Gotham-medium';
            line-height: 1;
            margin-bottom: 2rem;
            @include media-breakpoint-down(md) {
                font-size: 1.475rem;
                margin-bottom: 1rem;
            }
        }
        p {
            font-size:1rem;
            font-family: 'Gotham-light';
            line-height: 1.4;
            @include media-breakpoint-down(md) {
                font-size: 0.875rem;
            }
        }
        ul {
            margin-bottom: 1.7rem;
            li {
                color: $blue;
                margin-bottom: 0.8rem;
            }
        }
        .btn {
            font-family: 'Gotham-medium';
            font-size: 0.875rem;
            padding: 0.8rem 1.7rem 0.7rem;
        }
    }
    
}