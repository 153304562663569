/* adds some margin below the link sets  */

.modal-open .megaMenu {
    z-index: 0;
}

/* Hamburger menu */

.navbar-dark .navbar-toggler {

    width: 25px;
    height: 16px;
    position: relative;
    outline: 0;
    box-shadow: none;
    border: 0;
    @include transform(rotate(0deg));
    @include transition(0.5s ease-in-out);
    cursor: pointer;
    span.navbar-toggler {
        display: block;
        position: absolute;
        height: 2px;
        width: 100%;
        padding: 0;
        background: $white;
        border-radius: 0;
        opacity: 1;
        left: 0;
        @include transform(rotate(0deg));
        @include transition(0.25s ease-in-out);
        &:nth-child(1) {
            top: 0px;
        }
        &:nth-child(2) {
            top: 6px;
            left: -6px;
        }
        &:nth-child(3) {
            top: 12px;
        }
    }
    &[aria-expanded="true"] {
        span.navbar-toggler {
            background: $brown;
            &:nth-child(1) {
                top: 9px;
                @include transform(rotate(135deg));
            }
            &:nth-child(2) {
                opacity: 0;
                left: -60px;
            }
            &:nth-child(3) {
                top: 9px;
                @include transform(rotate(-135deg));
            }
        }
    }
    &[type="button"]{
        @include media-breakpoint-down(lg) {
            z-index:999;
        }
        &:before{
            content:"";
            position: absolute;
            left: -13px;
            right: 0;
            width: 50px;
            height: 65px;
            top: -16px;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
            @include transition(0.30s ease-in-out);
        }
        &[aria-expanded="true"]{
            &:before{
                background: $white;
            }
        }
    }
}

/* Hamburger menu */

.icon {
    @include media-breakpoint-down(md) {
        margin-right: 25px;
    }
    @include media-breakpoint-down(lg) {
        &.ico-lense,
        &.ico-user {
            margin-right: 2rem;
        }
    }
}

.navbar {
    >.container {
        @include media-breakpoint-up(xl) {
            position: relative;
        }
    }
}

.navbar-dark {
    .megaMenu {
        &.navbar-nav {
            .nav-link {
                text-transform: uppercase;
                color: $brown-200;
                padding: 0.50rem 1rem;
                @include media-breakpoint-up(xl) {
                    padding: 1rem;
                }
                @include media-breakpoint-down(sm) {
                    padding: 0.50rem 0.7rem 1rem 0.7rem;
                }
            }
        }
    }
    &.dark-text.dark-text.stuck {
        @include media-breakpoint-down(lg) {
            .megaMenu.navbar-nav{
                li{
                    .nav-link {color:$darker-brown-1;}
                    &.dropdown.active {
                        a.nav-link {
                            color: $blue;
                        }
                    }
                }
            }
        }
    }
    .flex-row-reverse {
        &> .nav-item.dropdown.active {
            &>a:hover ,&>a{
                color: $darker-brown!important;
            }
        }
    }
}

.megaMenu {
    @include media-breakpoint-up(xl) {
        margin-right: 15px;
        padding-top: 0px;
        padding-bottom: 0px;
        font-size: 0.80rem;
        z-index: 5000;
        .nav-item {
            padding: .5rem;
            margin: 0;
        }
        >.nav-item {
            align-self: center;
            position: static;
            @include transition(0.25s ease-in-out);
            &.active {
                &.btnType {
                    a.btn {
                        background: transparent;
                        border-color: $white;
                        outline: 0;
                        box-shadow: none !important;
                    }
                }
            }
        }
    }
    >.dropdown {
        >.dropdown-menu {
            @include media-breakpoint-up(xl) {

                width: 100%;
                left: 15px;
                right: auto;
                top: auto;
                margin-top: 0;
                border-radius: 0;
                background-color: $white;
                border-radius: 4px;
                padding: 0;
                box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.15);
                display: none;
                bottom:auto;
                .nav-item {
                    margin: 0;
                }
                div[class*="col"] {
                    margin-bottom: 1rem;
                }
            }
            border: none;
            background: $white !important;
            border-radius: 4px;
            border: 1px solid $white;
            color: $darker-brown;
            font-size: 0.9rem;
            line-height: 1.3;
            h1,
            h2,
            h3,
            p,
            a {
                color: $darker-brown;
            }
            .title {
                margin-bottom: 0.7rem;
                display: inline-block;
            }
            h3 {
                .title {
                    margin: 0;
                }
            }
            p {
                font-size: 0.875rem;
                line-height: 1.5;
            }
        }
    }
    /*Dropdown menu*/
    .dropdown-toggle {
        &:after {
            display: none;
        }
    }
    &.navbar-nav {
        >.nav-link {
            &.active {
                color: $white !important;
            }
        }
        .nav-item {
            padding: 0;
        }
        >.nav-item {
            padding: 0;
            background: transparent;
            @include media-breakpoint-up(xl) {
                .nav-link {
                    padding: 1.20rem 0.80rem;
                    color: $white;
                    opacity: 1;
                    cursor: pointer;
                    position: relative;
                    @include transition(0.400s ease-in-out);
                    border-top-left-radius: 4px;
                    border-top-right-radius: 4px;
                    &.green-btn {
                        padding-top: 0.50rem;
                        padding-bottom: 0.45rem;
                        border-radius: 0.25rem;
                        color: $white;
                        opacity: 1;
                        margin-left: 0.90rem;
                        background: $green;
                       
                    }
                }
                &.dropdown {
                    &.active {
                        .nav-link {
                            background: #f4f4f4;
                            opacity: 1;
                            outline: 0;
                            color: $brown-200;
                        }
                    }
                }
            }
        }
    }
    /* Accordian */
    .accordian {
        @include media-breakpoint-up(xl) {
            display: -webkit-flex;
            -webkit-flex-wrap: wrap;
            display: flex;
            flex-wrap: wrap;
        }
        &:after,
        &:before {
            content: "";
            display: table;
            width: 100%;
        }
        .title {
            text-transform: uppercase;
        }
        ul {
            padding: 0;
            margin: 0;
            display: block;
            list-style: none;
            @include media-breakpoint-up(xl) {
                border-right: 1px solid $grey-200;
                width: 18%;
                float: left;
            }
            li.nav-item {
                border-bottom: 1px solid $grey-200;
                @include media-breakpoint-up(xl) {
                    width: calc(100% + 1px);
                    border: 1px solid $grey-200;
                    border-left: 0;
                    border-top: 0;
                }
                list-style: none;
                display: block;
                float: left;
                width: 100%;
                &.active {
                    // border-right-color: $white;
                    border-right-color: $white-light!important; 
                    .nav-item{
                        color: $blue!important;
                    }
                }
                .nav-item {
                    padding: 1rem;
                    color: #16110a!important;
                    width: 100%;
                    text-align: left;
                    &:active {
                        color: #16110a!important;
                    }
                    @media (min-width: 1600px) {
                        padding: 1.5rem 1.2rem;
                    }
                    display: block;
                    margin-bottom: 0;
                    &:hover,
                    &.active,
                    &:focus {
                        text-decoration: none;
                        color: $blue;
                    }
                }
            }
        }
        .contentWrap {
            width: 100%;
            float: left;
            @include media-breakpoint-up(xl) {
                width: 82%;
                float: left;
                padding: 1.5rem 1rem;
            }
            .content {
                display: block;
                float: left;
                width: 100%;
                padding: 0rem 0.70rem;
                @include media-breakpoint-up(xl) {
                    display: none;
                }
                @include media-breakpoint-down(lg) {
                   padding: 0.5rem;
                   border-bottom:1px solid rgba(0,0,0,0.05);
                   &:last-child {
                   border-bottom: none;
                   }
                }
                &:first-child {
                    display: block;
                }
            }
        }
    }

    /* Accordian */
} 

.mob-nav-toggle {
    position: absolute;
    right: 0;
    // top: 1.2rem;
    top:0.5rem;
    z-index: 100;
    display: block;
    width: 1.25rem;
    height: 1.25rem;
    cursor: pointer;
    display: none;
    @include media-breakpoint-down(lg) {
   display: block;
    }
    &+a {
        position: relative;
        z-index: 1;
        padding-right: 1.4rem;
    }
}

//Mega  menu end.


/* Mobile menu */
@include media-breakpoint-down(lg) {
    header {
        .navbar-collapse {
            position: fixed;
            left: 0;
            width: 100%;
            background: $white;
            top: 4.0625rem;
            z-index: 999;
            overflow: auto;
            height: calc(100% - 4.0625rem);
            padding-top: 1rem;
            padding-bottom: 1rem;
            .megaMenu{
                padding:0 1rem;
            }
            .dropdown-menu {
                margin: 0;
                padding: 0;
            }
            .dropdown{
                &:after{
                    content: "";
                    display: block;
                    width: 20px;
                    height: 20px;
                    background-image: url(../images/sprite.png);
                    background-position: -9px -156px;
                    position: absolute;
                    right: 0;
                    top: 9px;
                    z-index: -1;
                }
                &.active{
                    .nav-link{
                        color:$blue;
                    }
                    &:after{
                        background-position: -9px -222px;
                    }
                }
            }
            .accordian {
                >ul,
                p {
                    display: none;
                }
                .innerWrap {
                    padding-top:0.50rem;
                    a,button {
                        font-family: 'Gotham-book';
                        text-transform: capitalize;
                    }
                }
                h3 {
                    overflow: hidden;
                    padding: 0;
                    margin-bottom: 0;
                    a.title, button.title {
                        margin-bottom: 0;
                        padding: 0 0 0.6rem;
                    }
                }
            }
        }
    }
}


@include media-breakpoint-down(lg) {
    .megaMenu  .dropdown-menu .contentWrap .content.submenu-active {
     border-top:none;
     border-bottom: none;
    }
    .megaMenu {
        li.nav-item {
        border-bottom: 1px solid rgba(0,0,0,0.05);
        &:last-child {
            border-bottom: none;
        }
        }
    }
    .navbar-dark .megaMenu.navbar-nav {
     li {
         padding: 0.7rem 0;
    .nav-link {
        padding: 0.50rem 0;
    }
    &.dropdown{
        &:after {
            top: 1.2rem;
        }
    }
}

}


}
/* Mobile menu */

@-webkit-keyframes autofill {
    to {
      color: #363735;
      background: transparent;
    }
  }
  @keyframes autofill {
    to {
      color: #363735;
      background: transparent;
    }
  }
  input:-webkit-autofill {
    -webkit-animation-name: autofill;
    animation-name: autofill;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }

  .footerQuickLink,.homebannerWrap .quickConnect.card {
    @-webkit-keyframes autofill {
        to {
          color: #363735;
          background: #fff;
        }
      } 
      input:-webkit-autofill {
        -webkit-animation-name: autofill;
        animation-name: autofill;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        @include box-shadow(0 0 0px 1000px #fff inset!important);
        font-size: 0.875rem;
        font-family: 'ScalaSans-Light';
      }
  }
 
  .LanguageSelect{
     
    @include media-breakpoint-down(sm){display: none;}
      ul.listing {
          left: auto;
          right: 0;
          width: 100%;
          z-index: 1000;
          li {
            padding: 0.625rem 1.25rem 0.625rem 0.7rem;
          }
      }
      .stylish-select-left {
          color:$grey-300;
          font-size: 0.80rem;
          &:after {
              margin-top: -0.5rem;
          }

      }
      .stylish-select-right.styledSelect {
          padding:1.2rem 0;
      }
  }

  .LanguageSelect,.selLanguage{
    .mCSB_inside > .mCSB_container {

        margin-right: 0px;
    
    }
    .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCSB_scrollTools .mCSB_dragger .mCSB_dragger, .mCSB_scrollTools .mCSB_dragger .mCSB_draggerRail {
        width: 14px;
    }
    .mCSB_scrollTools {
        width: 15px;
        right: 0;
    }
    .stylish-select-left {
        &:after {
            border-color: $blue!important;
        }
    .stylish-select-right {
        border-bottom: none;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding-right: 1.5rem;
        
    }
    span.langData {
    padding:1.2rem 1.5rem 1.2rem 0rem;
    display: block;
    text-align: left;
    font-size: 1rem;
    }
   
  }
  .mCSB_container {
    display: block!important;
}
}
.dropdown  {
    .selLanguage ,.LanguageSelect{
     width: 100%;
     border-bottom: 1px solid $blue;
     .stylish-select-right{
         font-size: 1rem;
         color: $grey-300;
     }
    }
    .selLanguage {
        z-index: 100;
        position: relative;
        height: 2.8rem;
       
        .listing {
            top:100%;
        } 
    }
    .LanguageSelect {
        position: relative;
        z-index: 10;
        .main,.stylish-select {
            height: auto;
        }
        .stylish-select-left {
            padding:0;
            height: auto;
            &:after {
                width: 8px;
                height: 8px;
                right: 3px;
            }
        }
    }
  }

  .lang_search {
      padding-top:1rem;
  }
  @include media-breakpoint-down(sm) {
  .icon.ico-lense, .icon.ico-user {
    margin-right: 0.8rem;
}

header {
 .selLanguage ,.LanguageSelect{
    display: block;
}
}
.userloginBox.mob-none {
    display: none;
}
header .userloginBox .dropdown-menu {
    width: 16rem;
    padding: 1.15rem 1.15rem 1.6rem;
}
  }


// Menu Start
@include media-breakpoint-up(lg) {
.megaMenu.navbar-nav > .nav-item{
    &[data-rel="0"] {
        position: relative;
    }
}

.megaMenu > .dropdown > .dropdown-menu {
    left: 0;
    right: 0;
    margin:0 auto;
    float: none;
    &[data-rel="0"]{
        width: 100%;

max-width: 100%;

min-width: 100%;
border-radius: 0px;
        .accordian > ul {
            width: 100%;
        }
    }
    &[data-rel="1"]{
        width:50%;
        @include media-breakpoint-down(lg) {
            width:100%;
        }
        .accordian > ul {
            width: 31%;
            @include media-breakpoint-down(lg) {
                width:100%;
            }
        }
        .contentWrap {
            width: 67%;
            @include media-breakpoint-down(lg) {
                width:100%;
            }
            
        }
        div[class*="col"] {
            flex: 0 0 100%;
           max-width: 100%;
        }
    }
    &[data-rel="2"]{
        width:60%;
        @include media-breakpoint-down(lg) {
            width:100%;
        }
        .accordian > ul {
            width: 31%;
            @include media-breakpoint-down(lg) {
                width:100%;
            }
        }
        .contentWrap {
            width: 67%;
            @include media-breakpoint-down(lg) {
                width:100%;
            }
            
        }
        div[class*="col"] {
            flex: 0 0 50%;

           max-width: 50%;
           @include media-breakpoint-down(lg) {
            flex: 0 0 100%;

            max-width: 100%;
        }
        }
    }
   
    
}

}

.dropdown-link {
    position: relative;
}
.megaMenu.navbar-nav > .nav-item {
    .nav-link {
        &:hover {
            color: $blue;
        }
        &.green-btn {
            color: $white;
        }
    }
    &.dropdown {
        .nav-link {
            color: $white;
            @include media-breakpoint-down(lg) {
                color: #16110a;
            }
            &:hover {
                color: #16110a;
            }
        }
       
    }
    button.nav-link {
        background: transparent;
       }
}
.dark-text {
    .megaMenu.navbar-nav > .nav-item.dropdown {
    .nav-link {
        color: #16110a; 
        // &:hover {
        //     color: #16110a;
        // }
    }
    &.active {
        .nav-link {
            color: #16110a; 

        }
    }
    }
} 

@include media-breakpoint-down(lg) {
body .userloginBox {
    .dropdown-menu {
        padding-right: 1rem;
    }
    .dropdown-wrap {
        // height: 13rem;
        .mCSB_scrollTools .mCSB_draggerRail,.mCSB_scrollTools {
            width:7px;
        }
        .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCSB_scrollTools .mCSB_dragger .mCSB_dragger, .mCSB_scrollTools .mCSB_dragger .mCSB_draggerRail {
            width:7px;
        }
        .mCSB_container {
            margin-right: 0;
            margin-right: 1.15rem;
        }
        .login-form-wrap {
            .mCSB_scrollTools .mCSB_draggerRail,.mCSB_scrollTools {
                width:15px;
            }
            .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCSB_scrollTools .mCSB_dragger .mCSB_dragger, .mCSB_scrollTools .mCSB_dragger .mCSB_draggerRail {
                width:15px;
            }
        }
    }
}
// .selLanguage,.LanguageSelect {
//     padding-right: 1rem;
// }
.selLanguage,.LanguageSelect {
    .main ul.listing {
        max-height: 9rem;
        .mCSB_container {
            margin-right: 15px;
        }
        .mCSB_scrollTools .mCSB_draggerRail,.mCSB_scrollTools,
        .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCSB_scrollTools .mCSB_dragger .mCSB_dragger, .mCSB_scrollTools .mCSB_dragger .mCSB_draggerRail 
            { 
            width: 15px;
        }
    }
}
}


body {
    .contact_form  {
    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
      -webkit-text-fill-color: #000;
      -webkit-box-shadow: 0 0 0px 1000px transparent inset;
      box-shadow: 0 0 0px 1000px transparent inset;
      transition: transparent 5000s ease-in-out 0s;
      background-color: transparent;
      z-index: 0;
    }
}
}

body {
    .agent-filter,.subscribeBtn {
    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
    //   border: 1px solid transparent;
      -webkit-text-fill-color: #000!important;
      -webkit-box-shadow: 0 0 0px 1000px #f7f7f7 inset!important;
      box-shadow: 0 0 0px 1000px #f7f7f7 inset!important;
      background-color: #f7f7f7!important;
      transition: background-color 5000s ease-in-out 0s!important;
      z-index: 1;
     
    }
}
}

.block-contact {
.userloginBox .form-control {
    z-index: 0;
}
}

@include media-breakpoint-down(lg) {
    .megaMenu.navbar-nav > .nav-item .nav-link.green-btn {
    color: #383b40;

}
  }

  .social_login .nav-link,#anchrRegistration,#anchrRegistration span,#anchSignupLogin,#BacktoLoginLink {
	cursor:pointer;
} 
@include media-breakpoint-up(lg) {
.megaMenu > .dropdown > .dropdown-menu{

border-top:none;
}

}

@include media-breakpoint-down(lg) {
    .megaMenu>.dropdown>.dropdown-menu {
        .innerWrap {
            padding-left:0.5rem;
        }
    }
    .megaMenu .accordian .nav-block .title{
        font-family: 'Gotham-book';
        text-transform: capitalize;
        color: #16110a;
        display: inline-block;
    }
}

.language-bubble {
    position: fixed;
    right: 2rem;
  
     bottom:6rem;
     margin:0!important;
     width: auto!important;
     height: auto!important;
  
    @include media-breakpoint-down(sm) {
     right: 1rem;
     bottom:2rem;
      
    }
    &> div{
        background: #00a9e0;
        height: 3rem;
        width: 3rem;
        padding: 0.475rem;
        display: block;
        border-radius: 50%;
        box-shadow: 1px 3px 6px rgba(0, 0, 0, 0.4);
       cursor: pointer;
       position: relative;
       text-indent: 0;
       @include transition(all 0.5s);
       .fa {
        font-size: 1.6rem;
        color: $white;
        position: absolute;
        left: 50%;
        top:50%;
        @include transform(translate(-50%,-50%));
        }
        .tooltip-txt {
            display: none;
            position: absolute;
            background: $black;
            width: 10rem;
            color: $white;
            font-size: 0.875rem;
            line-height: 1;
            padding:0.5rem;
            border-radius: 0.3rem;
            right: 0;
            bottom: 4rem;
            &:after {
                width: 0; 
  height: 0; 
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  
  border-bottom: 5px solid black;
  content: "";
  position: absolute;
  left:50%;
  bottom:0;
            }
        }
       
      
    }
    &:hover {
        .tooltip-txt {
            display: block;
           
        }
    }
}

