.history-section {
    padding:2rem 0 1rem;
    @include media-breakpoint-down(sm){
  padding-top:0;
    }
    .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCSB_scrollTools .mCSB_dragger .mCSB_dragger, .mCSB_scrollTools .mCSB_dragger .mCSB_draggerRail {
        width: 15px;
    }
}
.year-start {
   padding: 2rem 0;
   @include media-breakpoint-down(sm){
    padding: 1rem 0 0;
   }
    strong {
        text-align: center;
        display: block;
        font-family: 'Gotham-medium';
        font-size: 4.5rem;
        color:$blue;
        @include media-breakpoint-down(sm){
            font-size: 3rem;
        }
    }
}
.history-box {
    position: relative;
    padding:2rem 0;
    @include media-breakpoint-down(sm){
  padding-bottom: 0;
    }
    &:before {
        position: absolute;
        width: 0.12rem;
        height: 100%;
        background: #d2d2d2;
        content:"";
        top:0;
        left: 50%;
        margin-left: -0.06rem;
        @include media-breakpoint-down(sm){
            display: none;
           }
    }
  .history-wrapper {
        position: relative;
        &:before {
            width: 2rem;
            height: 2rem;
            position: absolute;
            left: 50%;
            border:0.3rem solid #d2d2d2;
            content: "";
            top:-2rem;
            border-radius: 50%;
            background: #fff;
            margin-left: -1rem;
            @include media-breakpoint-down(sm){
             display: none;
            }
        }
        &:after {
            width: 2rem;
            height: 2rem;
            position: absolute;
            left: 50%;
            border:0.3rem solid #d2d2d2;
            content: "";
            bottom:-2rem;
            border-radius: 50%;
            background: #fff;
            margin-left: -1rem;
            @include media-breakpoint-down(sm){
                display: none;
               }

        }

        &> div {
            @include media-breakpoint-down(sm){
                margin-bottom:0rem;
            }
            &>div{
            margin-bottom: 4rem;
            @include media-breakpoint-down(sm){
                margin-bottom: 1rem;
            }
            &:first-child {
                padding-right: 3rem;
                @include media-breakpoint-down(sm){
                    padding: 15px 0;
                   }
            }
            &:last-child {
                padding-left: 3rem;
                @include media-breakpoint-down(sm){
                    padding: 15px 0;
                   }
            }
        }

        &:nth-child(even){
            &>div{
                &:first-child {
                    padding-right: 0rem;
                    padding-left: 3rem;
                    -webkit-order: 2;
                    -moz-order: 2;
                    -o-order: 2;
                    -ms-order: 2;
                    order: 2;
                   
                    @include media-breakpoint-down(sm){
                        padding: 15px 0;
                        -webkit-order: 1;
                        -moz-order: 1;
                        -o-order: 1;
                        -ms-order: 1;
                        order: 1;
                       }
                }
                &:last-child {
                    padding-left: 0rem;
                    padding-right: 3rem;
                    -webkit-order: 1;
                    -moz-order: 1;
                    -o-order: 1;
                    -ms-order: 1;
                    order: 1;
                 
                    &> * {
                        text-align: right;
                        @include media-breakpoint-down(sm){
                            text-align: left;
                        }
                    }
                    @include media-breakpoint-down(sm){
                        padding: 15px 0;
                        -webkit-order: 2;
                        -moz-order: 2;
                        -o-order: 2;
                        -ms-order: 2;
                        order: 2;
                       }
                }
            }
        }

        &:nth-child(odd){
            &>div{
                &:first-child {
                  figure {
                      float:right;
                      text-align: right;
                      @include media-breakpoint-down(sm){
                        float:none;
                        text-align: center;
                      }
                      img {
                          text-align: right;
                          @include media-breakpoint-down(sm){
                            text-align: center;
                          }
                      }
                  }
                }
            }
        }
        }
    }

    figure {
        width: 70%;
        height: auto;
        margin:0;
        position: relative;
        top: 50%;
        @include transform(translateY(-50%));
        @include media-breakpoint-down(sm){
          margin:0 auto;
          width: 100%;
          position: static;
          top: auto;
          @include transform(translateY(0%));
        }
        img {
            max-width: 100%;
            height: auto;
            @include media-breakpoint-down(sm){
             width: 100%;
             max-width: 480px;
             margin:0 auto;
            }
        }
    }
    h4 {
        font-size: 3rem;
        @include media-breakpoint-down(sm){
            font-size: 2.4rem;
        }
     
    }

}
.filter-block {
    position: relative;
    text-align: center;
    z-index: 10;
}
.year-filter {
    width: auto;
    margin:0rem auto;
    position: relative;
    z-index: 10;
    display: inline-block;
    min-width: 15rem;
    @include media-breakpoint-down(sm){
  margin-bottom: 2rem;
    }
    a {
        &:hover {
            text-decoration: none;
        }
    }
   
    .filter-label {
        
      padding:1px;
      background:#f7f7f7;
      text-align: center;
        a {
            display: block;
            padding:1rem 2.6rem 1rem 1.2rem;
            font-size:1rem;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            position: relative;
            color: #1f1f1f;
            font-family: "Gotham-light";
            text-align: left;
            min-width: 10rem;
            &:after {
                width: 0.7rem; 
                 height: 0.7rem; 
                  border-right: 0.15rem solid  #d2d2d2;
                position: absolute;
                border-bottom: 0.15rem solid #d2d2d2;
                content: "";
                right: 1rem;
                top:46%;
                @include transform(translateY(-50%) rotate(45deg));
                @include transition(all 0.15s ease-in-out 0s);
            }
            
        }
        &.active{
            a:after {
                @include transform(translateY(0%) rotate(225deg));
            }
        }
    }
    .filter-year-list {
     max-height: 12rem;
     position: absolute;
     top:3.45rem;
     left:0;
     width: 100%;
     background: $white;
     border:0.2rem solid transparent;
     &.filter-year-active {
        border:0.2rem solid #f7f7f7;
     }
  
     ul {
         padding:0 15px 0 0;
        display: none;
        text-align: left;
         li {
             list-style: none;
           a {
               display: block;
               border-bottom: 0.1rem solid #f7f7f7;
               font-family: "Gotham-light";
               padding:0.8rem 1.2rem 0.8rem 1.2rem;
               color: #1f1f1f; 
               font-size: 1rem;
               &:hover,&.active-list {
                   background: $green;
                   color: #fff;
               }
           }
         }
     }
     .mCSB_container {
         margin-right: 0;
     }
    }
}

