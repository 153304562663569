.card{
    font-size:1rem;
    border-radius: 0;
    margin-bottom: 1.5rem;
    line-height: 1.5;
    position: relative;
    z-index: 1;
    box-shadow:none;
    border:0;
    color:$brown;
    .card-body{padding: 2rem;}
    label{
        display: none!important;
    }
    &.blueBg{
        .form-control,.form-elements input{background: $white;opacity:1;height:2.375rem;}
        @include media-breakpoint-up(lg) {
        }
        p{
           line-height:1.4;margin-bottom: 1.75rem;color:$white;    margin-bottom: 1rem;
        }
    }
    &.tintBg{
        background:rgba(0,0,0,0.5);color:$white;
        .form-control,.stylish-select-left .stylish-select-right{border-color:#000;}
    }

    @include transition(0.25s ease);
    .card-title{     margin-bottom: 0.55rem;}
    .btn,.card-title{text-transform: uppercase;}
    .btn{margin-top: 1rem;}
    select.form-control:not([size]):not([multiple]) {
        height: calc(2.35rem);
    }
    .form-control,.form-elements input{font-family:'ScalaSans-Light';border-radius:3px; border:1px solid $blue-100;    padding: 0.47rem 0.45rem;font-size: 0.875rem;line-height: 1.5;outline:0!important;box-shadow:none!important; }
    .btn{letter-spacing: 1px;padding: 0.75rem 1.38rem;}
    .form-group{
        margin-bottom: 0.5rem;
        &.designation{
            padding-right: 0.5rem;
        }
    }
    &.type-2{
        .form-group .stylish-select-left .stylish-select-right,.form-control{
            border-radius:0;
            border:0;
            border-bottom:1px solid $grey-50;
            padding-left:0;
            font-family:'Gotham-light';
            color:$brown;
        }
        .btnWrap{
            font-size: 0.75rem;
            margin-top: 0.4rem;
        }

        .btn{
            letter-spacing: normal;
            padding: 0.60rem 0.70rem;
            font-size: 0.75rem;
            margin:0;
        }

    }
}
footer {
    .card {
        .form-group.designation {float: left;
        & + div {float: right;}
        }
    }
}

.mCustomScrollbar.listing .mCSB_container {
    display: block !important;
}
