.hiveblock{
    display: inline-block;

    .colmn{
        vertical-align: middle;
        &:first-child{
            margin-left: 0;
        }
        max-width: 100%;
        @include media-breakpoint-up(sm) {
            margin: 0 0 0 10px;
            max-width: calc(33.334% - 6.667px);
            float:left;
        }
        @include media-breakpoint-up(sm) {
            display: table-cell;
            float:none;
            max-width:10.94rem;
        }
    }
    .gBox{
        display: block;
        background-color: $white-150;
        margin-bottom:10px;
        padding: 1rem;
        color:$dark-brown-100;
        text-decoration:none!important;
        border-radius:0.1875rem;
        height:12.81rem;
        overflow: hidden;
        @include transition(all 0.5s ease);
        svg{
            max-width:4.5rem;
            margin-bottom:0.70rem;
            @include transition(all 0.5s ease);
            max-height:4.5rem;
        } 
        .svg_chat{
            max-width:3rem;
        }    
        .svg_form {
            max-width:3rem;
        } 
        .svg_portal_log{
         max-width: 1.8rem;

        } 
        .svg_testimonial {
            max-width:2.3rem
        }
        .svg_knowledge {
            max-width: 3.1rem;
        }
        .svg_contact {
            max-width: 2.7rem;
        }
        .svg_financial {
            max-width: 3.2rem;
        }
        .svg_blog {
            max-width: 3.5rem;
        }
        &:hover{
            background-color: $green;
            color:$white;
            svg{
               fill:$white; 
               stroke: $white;
            }   
        }
        h4{
            font-family: 'Gotham-Book';
            font-size: 1rem;
        }
        h4,p{
            line-height: 1.2;
        }
        p{
            font-size: 0.90rem;
        }
        > span{
            display: block;
        }
        @include media-breakpoint-up(sm) {
            padding: 0.80rem;
            margin-right:10px;
        }
        
    }
}
.hiveblock.hiveblock-3 .gBox {
    svg {
        max-width: 3.1rem!important;
        max-height: 3.1rem!important;
    }
}
.hiveblock-2 {
    .gBox{
    .svg_insurance {
        max-width: 3.2rem;
        path {
            stroke-width: 3;
        }
    }
    .svg_banking {
        max-width: 2.8rem;
        path {
            stroke-width: 1px;
        }
    }
    .svg_ship,.svg_insvestment {
        max-width: 3.8rem;
        path {
            stroke-width: 1px;
        }
    }
    .svg_insvestment {
        path {
            stroke-width: 2;
        }
    }
    .svg_mortgages,.svg_loans,.svg_property {
        max-width: 3.4rem;
    }
    .svg_loans{
        path {
            stroke-width: 2;
        }
    }
    .svg_mortgages {
        path {
            stroke-width: 2px;
        }
    }
    .svg_property {
        stroke-width: 1px;
    }
}
}
.hiveblock-3 {
    .gBox{
.svg_testimonial {
    path{
        stroke-width: 1px;
    }
}
.svg_chat {
    path {
        stroke-width: 2px;
    }
}
.svg-portal{
    max-width: 1.9rem;
  .cls-1 {
    fill: none;
    stroke-linejoin: round;
    stroke-width: 5px;
}
}
.svg_form {
    max-width: 2.6rem;
    path {
        stroke-width: 0;
    }
      }
      .svg_chat {
          max-width: 2.8rem;
      }
    }
    .svg_contact,.svg_knowledge {
        path {
            stroke-width: 2;
        }
    }
 
}
#Layer_22 {
  
    path.cls-1 {
        fill:none;stroke-miterlimit:10;stroke-width:3px;
    }
}
.hiveblock-center {
    a {
        position: relative;
       display: block;
       min-width: 10rem;
      
        &>div {
          position: absolute;
          left: 0;
          width: 100%;
          top:50%;
          @include transform(translateY(-50%));
          padding:0.5rem;
        }
    }
}
.hiveblock-center {
    svg.svg_liveInsurance {
        width: 3rem!important;
        height: 3rem;
        stroke-width: 5px!important;
    }
}
.hiveblock.hiveblock-center .gBox:hover svg.svg_liveInsurance  {
fill: none!important;
}
@include media-breakpoint-down(sm) { 
.block-investor {
    .hiveblock {
     display: block;
    }
   }
   .hiveblock {display: block;}
}