.oddShade{
    .block{
        &:nth-child(odd){
            background-color:$white-100;
        }    
    }
    &.homePage {
        .block:first-child{
            @include media-breakpoint-up(md) {
                padding-top: 5rem!important;
            }
            @include media-breakpoint-up(lg) {
                padding-top: 6.5rem!important;
            }
        }
    }
}

.evenShade{
    .block{
        &:nth-child(even){
            background-color:$white-100;
        }    
    }
}

.contact-info-box {
    position: fixed;
    right: 2rem;
    z-index: 100;
     bottom:2rem;
     display: none;
    @include media-breakpoint-down(sm) {
     right: 1rem;
     bottom:1rem;
      
    }
    .info-toggle-btn {
        background: $blue;
        height: 3rem;
        width: 3rem;
        padding:0.475rem;
        display: block;
        border-radius: 50%;
        box-shadow:1px 3px 6px rgba(0, 0, 0, 0.4);
        cursor: pointer;
        @include transition(all 0.5s);
        @include media-breakpoint-down(sm) {
         padding: 0.3rem;
         height: 2rem;
        width: 2rem;
        }
        &:hover {
            background: $blue-100;
        }
        img {
            width: 1.6rem;
            position: absolute;
            top:50%;
            left:50%;
            @include transform(translate(-50%,-50%));
            @include media-breakpoint-down(sm) {
            width: 1.3rem;
            }
        }
    }
    .contact-dropdown {
        position: absolute;
        top: auto;
        width: 14rem;
        right: 0;
        padding: 0;
        display: none;
        bottom: 4rem;
     
        @include media-breakpoint-down(sm) {
            bottom: 3rem;

        }
        a {
            display: block;
            margin-bottom: 0.7rem;
            
            &:last-child {
                margin-bottom: 0;
            }
            &.btn-primary {
                background: $green;
                border-color: $green;
                box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.3) !important;
                &:hover {
                    background: $green-100 ;
                border-color: $green-100 ;
                }
            }
        }
        
       
    }
}

.show-close {
    display: none;
}

.contact_form,.map_filter,.homebannerWrap .quickConnect.type-2,.designation ,.form-calculator ,.agent-block,.product-search-sidebar{
  input:focus,.form-control:focus,.stylish-select-left:focus  {
    -webkit-outline:0!important;
    -ms-outline: 0!important;
    -moz-outline:0!important;
    -o-outline:0!important;
    outline: 0!important;
   border-bottom: 1px solid #00a9e0 !important;
  }
  textarea.form-control:focus{
   border: 1px solid #00a9e0 !important;
  }
  textarea.form-control {
      border:1px solid transparent!important;
      border-bottom: 1px solid #d8d8d8!important;
  }
  input[type="checkbox"]:focus,input[type="radio"]:focus{
    box-shadow:0px 0px 3px #00a9e0 !important;
  }

}

.filtersGroup {
    .stylish-select-left:focus,.form-control:focus,.inputSearch:focus .form-control  {
        box-shadow:0px 0px 3px #00a9e0 !important; 
    }
}
.dropdown-menu {
    .stylish-select-left:focus {
    strong ,.stylish-select-right{
        color: $blue;
    }
    }
}
