$brand-success: #5cb85c;
$loader-size: 7em;
$check-height: 18px;
$check-width: 10px;
$check-left: 0;
$check-thickness: 2px;
$check-color: $brand-success;
.checkmark {
    position: relative;
    
    &.draw:after {
      animation-duration: 800ms;
      animation-timing-function: ease;
      animation-name: checkmark;
      transform: scaleX(-1) rotate(135deg);
    }
    
    &:after {
      opacity: 1;
      height: $check-height;
      width: $check-width;
      transform-origin: left top;
      border-right: $check-thickness solid $check-color;
      border-top: $check-thickness solid $check-color;
      content: '';
      left:$check-left;
      top: 0;
      position: absolute;
    }
  }
  
  @keyframes loader-spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  @keyframes checkmark {
    0% {
      height: 0;
      width: 0;
      opacity: 1;
    }
    20% {
      height: 0;
      width: $check-width;
      opacity: 1;
    }
    40% {
      height: $check-height;
      width: $check-width;
      opacity: 1;
    }
    100% {
      height: $check-height;
      width: $check-width;
      opacity: 1;
    }
  }