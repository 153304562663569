.blogsWrap{
    .item{
        figure{
            .img{
                height: 100%;width:100%;background-position: top right;
                min-height: 290px;
                background-size: cover;
                display: block;
                @include media-breakpoint-up(lg) {
                    position: absolute; width: calc(100% - 30px);  
        
                }
            }
        }
    }
    .content{
        padding-top: 1rem;
        @include media-breakpoint-up(xl) {
            padding-left:2.6875rem;
        }
        @include media-breakpoint-up(lg) {
            padding-bottom:2rem;
        }
    }
}
@include media-breakpoint-down(lg) {
    .homecarousel .blogsWrap .custom-nav{
        top: calc(65% - 24px);
    }
}

//Blogs details...
.block{
    .tags{
        .btn{
            margin:0;
            background: $silver-50;
            border-color:transparent;
            margin-bottom:1rem;
            cursor: initial;
            font-family: 'Gotham-Book';
            margin-right: 0.85rem;
            padding-left: 1.50rem;
            padding-right: 1.50rem;
            font-size: 1rem;
            cursor: default;
            &:hover,&:active{
                background: $silver-50;
                border-color: #000;
            }
            
        }
        .navbar-brand{
            padding:0;
            margin:0 4rem 1rem 0;
            font-size: 1.875rem;vertical-align: middle;
        }
        span.btn {
            cursor: default;
        }
    }   
}
.blogsContent{
    .subscribeBtn{
        float:right;
        .field-validation-error {
            position: absolute;
             display: block;
              bottom: -1.5rem;
              left: 1.5rem;
        }
    }
    .socialWrap {
        padding: 0 15px;
    }
}

.oddShade.homePage {
    .internalPage {
        .block {
            &:nth-child(odd) {
                background: transparent;
            }
            &:first-child {
                padding-top: 2rem;
            }
        }
    }
}

.newsBlock .author .ico-calandar {cursor: context-menu;}
.newsBlock {
    .content {
        a {
            h4 {
            color: $grey-300;
            &:hover {
                color: $blue;
                text-decoration: none;
            }
        }
        &:hover {
            text-decoration: none;
        }
        }
    }
}
.subscribeBtn input[type="submit"]{
    background-color: #00a9e0;
border-color: #00a9e0;
font-family: 'Gotham-Book';
font-size: 0.80rem;
line-height: 1;
padding: 0.750rem 1.5rem 0.60rem;
outline: 0;
box-shadow: none !important;
letter-spacing: 0.85px;
&:hover,&:active,&:focus {
    background-color: #0085b1;
border-color: #0085b1;
box-shadow: none;
}
}
@include media-breakpoint-down(lg) {
body.modal-open {
    position: fixed;
    width: 100%;
    height: 100%;
}
// header .userloginBox .dropdown-menu {
//     .dropdown-wrap  {
//      max-height: 18rem;
//          overflow-y: auto;
//      }
//  }
}
@media only screen and (min-device-width : 320px) and (max-device-width : 767px) and (orientation : landscape) { 
    .dropdown .subChild {
    .selLanguage,.LanguageSelect {
    padding-right: 0rem;
    display: inline-block;
    width: 42%;
    margin-right: 5%;
}
.LanguageSelect {
    margin-right:0%;
    margin-left:5%;
    display: inline-block!important;
}
    }
    header .userloginBox .dropdown-menu.subChild {
        width: 24rem;
    }
    body .userloginBox .subChild .dropdown-wrap {
        height: auto;
    }
    .selLanguage .main ul.listing, .LanguageSelect .main ul.listing {
        max-height: 7rem;
    }
}
@include media-breakpoint-down(md) {
    .homecarousel .blogsWrap .custom-nav {
        top: 16%;
    }
}

@include media-breakpoint-down(sm) {
     .logo {
         max-width: 118px;
     }
     .icon.ico-lense, .icon.ico-user {
        margin-right: 0.4rem;
    }
    .userloginBox.userlogin-show .dropdown-menu {
        right: -54px;
    }
    .userloginBox.userlogin-show .dropdown-menu::before {
        right: 3.7rem;
    }
}
.validation-summary-errors {
    display: none!important;
}