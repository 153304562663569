.productBlock{
    h5{
        margin-bottom:1.35rem;
        text-transform: uppercase;
    }
    .img{
        width: 100%;height:100%;background-size: cover;background-position:center;
        min-height:190px;
        display:block;
        @include media-breakpoint-up(lg) {
            position: absolute; width: calc(100% - 30px);  

        }
        @include media-breakpoint-between(sm,lg) {
            min-height: 290px;    
        }
    }

    .content{
        @include media-breakpoint-up(lg) {
            margin-bottom:6rem;
            padding-right: 4rem;
        }
        @media (min-width:1600px){
            padding-right: 6rem;
        }
    }
}

.quickConnect,.form-elements input {

        @include placeholder{
            opacity: 1!important;
            color: $brown!important;
        }
  
}

.customCol {
    &> div {
    display:inline-block;
    vertical-align: top;
    width: 50%;
    margin:0 -4px 1.5rem 0;
    &:nth-child(odd){
        padding-right: 9.8%;
        @include media-breakpoint-down(md) {
          padding-right: 1rem;
        }
        @include media-breakpoint-down(sm) {
            padding-right: 0rem;
          }
    }
    &:nth-child(even){
        padding-left: 9.8%;
        @include media-breakpoint-down(md) {
            padding-left: 1rem;
          }
          @include media-breakpoint-down(sm) {
            padding-left: 0rem;
          }
         
    }
    @include media-breakpoint-down(sm) {
        width: 100%;
        padding:0;
        margin:0 0 1.5rem 0;
      }
    &.fullWidth {
        &:nth-child(odd), &:nth-child(even) {
            width: 100%;
            padding:0;
            margin:0 0 1.5rem 0;
        }
        &:nth-child(odd) ~ div {
            &:nth-child(even){
                padding-right: 9.8%;
                padding-left:0;
                @include media-breakpoint-down(md) {
                  padding-right: 1rem;
                  padding-left:0;
                }
                @include media-breakpoint-down(sm) {
                    padding-right: 0rem;
                    padding-left:0;
                  }
            }
            &:nth-child(odd){
                padding-left: 9.8%;
                padding-right: 0;
                @include media-breakpoint-down(md) {
                    padding-left: 1rem;
                    padding-right: 0;
                  }
                  @include media-breakpoint-down(sm) {
                    padding-left: 0rem;
                    padding-right: 0;
                  }
                 
            }
            &.fullWidth{
                padding-left:0;
                padding-right: 0;
            }
        }
    }
    h4 {
        margin-bottom: 1.5rem;
    }
    }
    ul, ol {
        margin-bottom: 2rem;
        list-style: none;
        padding:0;
        li {
            position: relative;
        }
    }
    h3 {
        font-family: 'Gotham-light';
line-height: 1.34;
font-size: 1.7rem;
@include media-breakpoint-down(sm) { 
    font-size: 1.5rem;
}
    }
}


.alignBox {
    padding:3rem 0 0;
    @include media-breakpoint-down(sm) { 
        padding:2rem 0 0;
    }
    h2 {
        color: #d9d9d9;
        font-size: 6.375rem;
margin-bottom: 3.2rem;

@include media-breakpoint-down(lg) { 
    font-size: 4rem;
}
@include media-breakpoint-down(sm) { 
font-size: 2.375rem;
}
    }
}