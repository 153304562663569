$transactionTopPos: 1.3rem;
.testimonialBlock{
    font-size: 1rem;
    @include media-breakpoint-down(md) {
        .item{
            margin-bottom:1rem;
            &:last-child{
                margin-bottom:0;
            }
        }
        .custom-nav{
            display: none;
        }
    }    
    .thumbnail{
           margin-bottom:0;
           @include media-breakpoint-up(md) {
                padding-right:0;
           }
            .img{
                background-size:cover;
                background-repeat:no-repeat;
                display: block;
                width: 100%;
                height: 100%;  
                background-position: center center;
                min-height: 234px;
                display:block;
                @include media-breakpoint-up(md) {
                    position: absolute;
                }
            }
        }
        .content {
            position: relative;
            min-height: 234px;
            @include media-breakpoint-down(sm) {
              min-height: 1px;
            }
            @include media-breakpoint-up(xl) {
                min-height: 309px;
            }
            .innerWrap{
                background: $white;padding: 1.2rem;
            }
            &:before{
                content: '';
                width: 0;
                height: 0;
                border-left: 20px solid transparent;
                border-bottom: 20px solid $white;
                border-right: 20px solid transparent;
                position: absolute;
                top: -20px;
                left: 0;right:0;margin:0 auto;
            }  
           
            @include media-breakpoint-up(md) {
                padding-bottom: 1.3rem;          
                max-width: calc(50% - 15px);
                flex-basis: calc(50% - 15px);
                background: $white;
                margin:0;padding-left: 25px; margin-right:15px;
                padding-top: $transactionTopPos;
                
                &:before{
                    left: -20px;
                    right:auto;
                    border-top:  20px solid transparent;
                    border-bottom-color: transparent;
                    border-right: 20px solid $white;
                    border-left:0;
                    top: 23px;
                }
                .innerWrap{
                    background: none;
                    padding: 0;
                }
            }
            
        }
        .owl-carousel{
            display: block;
        }
    .crauselWrap{
        margin-bottom: 1rem;
        @include media-breakpoint-up(md) {
            margin-bottom: 5rem;
        }
    }
    // .lead{
    //     margin-bottom: 1.98rem;
    // }
    h5{
        margin-bottom: 0.75rem;    text-transform: uppercase;
    }

    .crauselWrap .thumbnail img {
        width:100%!important;
        height: auto!important;
    }
}
.testimonialDetails{
    figure{
        max-width:160px;
    }
    img{
        max-width:100%;
    }
}